
body {
  font-size: 14px;
  background: #090909;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  color: #bcbcbc;
  padding-right: 0 !important;
}
.card {
  font-size: 0.875rem;
}
.image{
  margin-block: 10px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}


abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #ffd804;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #f06873;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.error-message {
  color: red;
}
.stakingCurrencyList table th:first-child{
  width: 180px;
}
.stakingCurrencyList table td:first-child{
  width: 180px;
}
.stakingCurrencyList table th:nth-child(2){
  width: 130px;
}
.stakingCurrencyList table td:nth-child(2){
  width: 130px;
}
.stakingCurrencyList table td:last-child{
  text-align: right;
}
.stakingCurrencyList table th:last-child{
  text-align: right;
}
.stakingCurrencyList table th{
  white-space: nowrap;
}
.stakingCurrencyList table td{
  white-space: nowrap;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #ffd804;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #f06873;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 3.3125rem;
}
h2,
.h2 {
  font-size: 2.25rem;
}
h3,
.h3 {
  font-size: 1.5625rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1.0625rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 7rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
code {
  font-size: 87.5%;
  color: #e91e63;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.border-bottom-0{
  border-bottom: 0 !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgb(47, 47, 47);
}
.light_theme .footer_bottom p{
  color: #a3a3a3 !important;
}
.light_theme .table th,
.light_theme .table td {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}
.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.table .table {
  background-color: #fafafa;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e3c3e9;
}
.table-hover .table-primary:hover {
  background-color: #dab0e2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #dab0e2;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce;
}
.table-hover .table-success:hover {
  background-color: #bbe1bd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bbe1bd;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3;
}
.table-hover .table-info:hover {
  background-color: #a2e6ef;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2e6ef;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe2b8;
}
.table-hover .table-warning:hover {
  background-color: #ffd89f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffd89f;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7;
}
.table-hover .table-danger:hover {
  background-color: #fbb3af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fafafa;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.06);
}
.table-dark {
  color: #fafafa;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  border-color: #d176e1;
  outline: 0;
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.login_container .form-control::placeholder {
  line-height: 60px !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.4375rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-sm
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-lg
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.125rem + 2px);
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5625rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(4.125rem + 2px);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.login_form .form-check {
  display: flex;
  align-items: flex-start;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: 0.2rem;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #4caf50;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #4caf50;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6ec071;
}
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4caf50;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: 0.2rem;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f44336;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #f44336;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #f44336;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f77066;
}
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f44336;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.46875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25), none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
  box-shadow: none;
}
.btn-primary:hover {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #771e86;
  border-color: #6e1b7c;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: none;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: none;
}
.btn-info:hover {
  color: #fff;
  background-color: #009aae;
  border-color: #008fa1;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #008fa1;
  border-color: #008394;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: none;
}
.btn-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: none;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
  color: #ffd804;
  background-color: transparent;
  background-image: none;
  border-color: #ffd804;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ffd804;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00bcd4;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9800;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #ffd804;
  background-color: transparent;
}
.btn-link:hover {
  color: #f06873;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #999;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 1.125rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.show {
  display: block;
}
tr.collapse.show {
  display: table-row;
}
tbody.collapse.show {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ffd804;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffd804;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #dfa0ea;
  box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffd804;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #ffd804;
  box-shadow: none;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffd804;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d2d2d2;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #d176e1;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(209, 118, 225, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(2.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(4.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
  border-color: #d176e1;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #d176e1;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.4375rem + 2px);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  background-color: transparent;
  border: 0 solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.4375rem + 2px) - 0 * 2);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  content: "Browse";
  background-color: transparent;
  border-left: 0 solid #d2d2d2;
  border-radius: 0 0 0 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fafafa;
  border-color: #dee2e6 #dee2e6 #fafafa;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffd804;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.social_list {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
}
.social_list li {
  width: auto;
}
.social_links_header {
  background-image: linear-gradient(to right, #fe1301 0%, #ff7713 100%);
  border-radius: 40px !important;
  box-sizing: border-box;
  color: #7d8082 !important;
  display: block;
  height: 45px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 3px !important;
  text-decoration: none;
  width: 45px;
  z-index: 2;
  box-shadow: 0px 0px 1px 5px rgb(47 51 54 / 88%);
  font-size: 16px !important;
  /* margin-right: 25px; */
}
.social_links_header:hover {
  color: #fff;
}
.social_links_header i {
  align-items: center;
  background-image: linear-gradient(#2b2a2c, #4a5157);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s ease;
  width: 100%;
  text-shadow: 4px 6px 5px rgb(0 0 0 / 71%);
}
.social_links_header:hover i {
  background: transparent;
  color: #fff;
}
a.mobile_menu {
  color: #fff;
}
a.mobile_menu svg {
  vertical-align: middle;
  margin-right: 8px;
}
a.mobile_menu:hover {
  color: #ff7800;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.homeAccordian .card .card-header {
  margin-top: 0;
  padding: 0;
}
.homeAccordian .card .card-header .btn {
	padding: 15px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	font-size: 16px;
	color: #ffff;
	transition: 0.5s;
}
.homeAccordian .card .card-header .btn:hover {
  color: #ffd808;
}
.homeAccordian .card .card-body > p{
  color: #fff;
}
.homeAccordian .card .card-body,
.homeAccordian .card .card-footer {
  padding-top: 0;
}
.homeAccordian .card .card-header h5 {
  margin-top: 0;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #ffd804;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  color: #f06873;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #51145c;
  background-color: #ebd4ef;
  border-color: #e3c3e9;
}
.alert-primary hr {
  border-top-color: #dab0e2;
}
.alert-primary .alert-link {
  color: #2c0b32;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}
.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3;
}
.alert-info hr {
  border-top-color: #a2e6ef;
}
.alert-info .alert-link {
  color: #00353b;
}
.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.alert-warning hr {
  border-top-color: #ffd89f;
}
.alert-warning .alert-link {
  color: #523100;
}
.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #ffd804;
  transition: width 0.6s ease;
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: inherit;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: inherit;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ffd804;
  border-color: #ffd804;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #51145c;
  background-color: #e3c3e9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #51145c;
  background-color: #dab0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #51145c;
  border-color: #51145c;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}
.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #00626e;
  background-color: #a2e6ef;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00626e;
  border-color: #00626e;
}
.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #854f00;
  background-color: #ffd89f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #854f00;
  border-color: #854f00;
}
.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7f231c;
  background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #ffd804;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #771e86;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #4caf50;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}
.badge-info {
  color: #fff;
  background-color: #00bcd4;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #008fa1;
}
.badge-warning {
  color: #212529;
  background-color: #ff9800;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cc7a00;
}
.badge-danger {
  color: #fff;
  background-color: #f44336;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.26;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: rgba(97, 97, 97, 0.9);
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}
.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}
.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
/* .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
} */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #ffd804 !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #771e86 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #4caf50 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}
.bg-info {
  background-color: #00bcd4 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important;
}
.bg-warning {
  background-color: #ff9800 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #ffd804 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #4caf50 !important;
}
.border-info {
  border-color: #00bcd4 !important;
}
.border-warning {
  border-color: #ff9800 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #ffd804 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #771e86 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}
.text-success {
  color: #4caf50 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #3d8b40 !important;
}
.text-info {
  color: #00bcd4 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #008fa1 !important;
}
.text-warning {
  color: #ff9800 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #cc7a00 !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ea1c0d !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}
.text-muted,
.bmd-help {
  color: #6c757d !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
a:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.bmd-layout-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bmd-layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-content {
  position: relative;
  z-index: 1;
  display: inline-block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-spacer {
  flex-grow: 1;
}
.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@supports (pointer-events: auto) {
  .bmd-layout-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
}
.btn {
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0.2rem;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn:active:focus:focus,
.btn:active:focus.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.btn-primary {
  color: #090909;
  background-color: #ffd804;
  border-color: #ffd804;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:hover {
  color: #090909;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:hover {
  color: #090909;
  background-color: #eac606 !important;
  border-color: #eac606 !important;
}
.btn.btn-primary:active,
.btn.btn-primary.active,
.open > .btn.btn-primary.dropdown-toggle,
.show > .btn.btn-primary.dropdown-toggle {
  color: #090909;
  background-color: #eac606;
  border-color: #eac606;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:active:hover,
.btn.btn-primary:active:focus,
.btn.btn-primary:active.focus,
.btn.btn-primary.active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active.focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle.focus,
.show > .btn.btn-primary.dropdown-toggle:hover,
.show > .btn.btn-primary.dropdown-toggle:focus,
.show > .btn.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #9124a3;
  border-color: #3f1048;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #ffd804;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #9124a3;
}
.btn.btn-primary.disabled:focus,
.btn.btn-primary.disabled.focus,
.btn.btn-primary:disabled:focus,
.btn.btn-primary:disabled.focus {
  background-color: #ffd804;
  border-color: #ffd804;
}
.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover {
  background-color: #ffd804;
  border-color: #ffd804;
}
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.btn.btn-primary.btn-link {
  background-color: transparent;
  color: #ffd804;
  box-shadow: none;
}
.btn.btn-primary.btn-link:hover,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:active {
  background-color: transparent;
  color: #ffd804;
}
.btn.btn-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
  border-color: #ccc;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14),
    0 3px 1px -2px rgba(250, 250, 250, 0.2),
    0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:focus,
.btn.btn-secondary.focus,
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:active,
.btn.btn-secondary.active,
.open > .btn.btn-secondary.dropdown-toggle,
.show > .btn.btn-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14),
    0 3px 1px -2px rgba(250, 250, 250, 0.2),
    0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:active:hover,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active.focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active.focus,
.open > .btn.btn-secondary.dropdown-toggle:hover,
.open > .btn.btn-secondary.dropdown-toggle:focus,
.open > .btn.btn-secondary.dropdown-toggle.focus,
.show > .btn.btn-secondary.dropdown-toggle:hover,
.show > .btn.btn-secondary.dropdown-toggle:focus,
.show > .btn.btn-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #8c8c8c;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #fafafa;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f2f2f2;
}
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary.disabled.focus,
.btn.btn-secondary:disabled:focus,
.btn.btn-secondary:disabled.focus {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary:focus,
.btn.btn-secondary:active,
.btn.btn-secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}
.btn.btn-secondary.btn-link {
  background-color: transparent;
  color: #fafafa;
  box-shadow: none;
}
.btn.btn-secondary.btn-link:hover,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:active {
  background-color: transparent;
  color: #fafafa;
}
.btn.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:focus,
.btn.btn-info.focus,
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:active,
.btn.btn-info.active,
.open > .btn.btn-info.dropdown-toggle,
.show > .btn.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:active:hover,
.btn.btn-info:active:focus,
.btn.btn-info:active.focus,
.btn.btn-info.active:hover,
.btn.btn-info.active:focus,
.btn.btn-info.active.focus,
.open > .btn.btn-info.dropdown-toggle:hover,
.open > .btn.btn-info.dropdown-toggle:focus,
.open > .btn.btn-info.dropdown-toggle.focus,
.show > .btn.btn-info.dropdown-toggle:hover,
.show > .btn.btn-info.dropdown-toggle:focus,
.show > .btn.btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #00aec5;
  border-color: #004b55;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #00bcd4;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #00aec5;
}
.btn.btn-info.disabled:focus,
.btn.btn-info.disabled.focus,
.btn.btn-info:disabled:focus,
.btn.btn-info:disabled.focus {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-info.btn-link {
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none;
}
.btn.btn-info.btn-link:hover,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:active {
  background-color: transparent;
  color: #00bcd4;
}
.btn.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:active,
.btn.btn-success.active,
.open > .btn.btn-success.dropdown-toggle,
.show > .btn.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:active:hover,
.btn.btn-success:active:focus,
.btn.btn-success:active.focus,
.btn.btn-success.active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active.focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle.focus,
.show > .btn.btn-success.dropdown-toggle:hover,
.show > .btn.btn-success.dropdown-toggle:focus,
.show > .btn.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #47a44b;
  border-color: #255627;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #4caf50;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #47a44b;
}
.btn.btn-success.disabled:focus,
.btn.btn-success.disabled.focus,
.btn.btn-success:disabled:focus,
.btn.btn-success:disabled.focus {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.btn-link {
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
}
.btn.btn-success.btn-link:hover,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:active {
  background-color: transparent;
  color: #4caf50;
}
.btn.btn-warning {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14),
    0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:active,
.btn.btn-warning.active,
.open > .btn.btn-warning.dropdown-toggle,
.show > .btn.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14),
    0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:active:hover,
.btn.btn-warning:active:focus,
.btn.btn-warning:active.focus,
.btn.btn-warning.active:hover,
.btn.btn-warning.active:focus,
.btn.btn-warning.active.focus,
.open > .btn.btn-warning.dropdown-toggle:hover,
.open > .btn.btn-warning.dropdown-toggle:focus,
.open > .btn.btn-warning.dropdown-toggle.focus,
.show > .btn.btn-warning.dropdown-toggle:hover,
.show > .btn.btn-warning.dropdown-toggle:focus,
.show > .btn.btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #f08f00;
  border-color: #804c00;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #ff9800;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f08f00;
}
.btn.btn-warning.disabled:focus,
.btn.btn-warning.disabled.focus,
.btn.btn-warning:disabled:focus,
.btn.btn-warning:disabled.focus {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}
.btn.btn-warning.btn-link {
  background-color: transparent;
  color: #ff9800;
  box-shadow: none;
}
.btn.btn-warning.btn-link:hover,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:active {
  background-color: transparent;
  color: #ff9800;
}
.btn.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
    0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:active,
.btn.btn-danger.active,
.open > .btn.btn-danger.dropdown-toggle,
.show > .btn.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
    0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:active:hover,
.btn.btn-danger:active:focus,
.btn.btn-danger:active.focus,
.btn.btn-danger.active:hover,
.btn.btn-danger.active:focus,
.btn.btn-danger.active.focus,
.open > .btn.btn-danger.dropdown-toggle:hover,
.open > .btn.btn-danger.dropdown-toggle:focus,
.open > .btn.btn-danger.dropdown-toggle.focus,
.show > .btn.btn-danger.dropdown-toggle:hover,
.show > .btn.btn-danger.dropdown-toggle:focus,
.show > .btn.btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #f33527;
  border-color: #a21309;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #f44336;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f33527;
}
.btn.btn-danger.disabled:focus,
.btn.btn-danger.disabled.focus,
.btn.btn-danger:disabled:focus,
.btn.btn-danger:disabled.focus {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:hover {
  box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.btn.btn-danger.btn-link {
  background-color: transparent;
  color: #f44336;
  box-shadow: none;
}
.btn.btn-danger.btn-link:hover,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:active {
  background-color: transparent;
  color: #f44336;
}
.btn.btn-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:focus,
.btn.btn-rose.focus,
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:active,
.btn.btn-rose.active,
.open > .btn.btn-rose.dropdown-toggle,
.show > .btn.btn-rose.dropdown-toggle {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:active:hover,
.btn.btn-rose:active:focus,
.btn.btn-rose:active.focus,
.btn.btn-rose.active:hover,
.btn.btn-rose.active:focus,
.btn.btn-rose.active.focus,
.open > .btn.btn-rose.dropdown-toggle:hover,
.open > .btn.btn-rose.dropdown-toggle:focus,
.open > .btn.btn-rose.dropdown-toggle.focus,
.show > .btn.btn-rose.dropdown-toggle:hover,
.show > .btn.btn-rose.dropdown-toggle:focus,
.show > .btn.btn-rose.dropdown-toggle.focus {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #7b0c32;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e91e63;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #ea2c6d;
}
.btn.btn-rose.disabled:focus,
.btn.btn-rose.disabled.focus,
.btn.btn-rose:disabled:focus,
.btn.btn-rose:disabled.focus {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose:focus,
.btn.btn-rose:active,
.btn.btn-rose:hover {
  box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}
.btn.btn-rose.btn-link {
  background-color: transparent;
  color: #e91e63;
  box-shadow: none;
}
.btn.btn-rose.btn-link:hover,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:active {
  background-color: transparent;
  color: #e91e63;
}
.btn,
.btn.btn-default {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:hover,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:focus,
.btn.focus,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default.focus,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:active,
.btn.active,
.open > .btn.dropdown-toggle,
.show > .btn.dropdown-toggle,
.btn.btn-default:active,
.btn.btn-default.active,
.open > .btn.btn-default.dropdown-toggle,
.show > .btn.btn-default.dropdown-toggle {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:active:hover,
.btn:active:focus,
.btn:active.focus,
.btn.active:hover,
.btn.active:focus,
.btn.active.focus,
.open > .btn.dropdown-toggle:hover,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle.focus,
.show > .btn.dropdown-toggle:hover,
.show > .btn.dropdown-toggle:focus,
.show > .btn.dropdown-toggle.focus,
.btn.btn-default:active:hover,
.btn.btn-default:active:focus,
.btn.btn-default:active.focus,
.btn.btn-default.active:hover,
.btn.btn-default.active:focus,
.btn.btn-default.active.focus,
.open > .btn.btn-default.dropdown-toggle:hover,
.open > .btn.btn-default.dropdown-toggle:focus,
.open > .btn.btn-default.dropdown-toggle.focus,
.show > .btn.btn-default.dropdown-toggle:hover,
.show > .btn.btn-default.dropdown-toggle:focus,
.show > .btn.btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #919191;
  border-color: #595959;
}
.open > .btn.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #999999;
}
.open > .btn.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #919191;
}
.btn.disabled:focus,
.btn.disabled.focus,
.btn:disabled:focus,
.btn:disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default.disabled.focus,
.btn.btn-default:disabled:focus,
.btn.btn-default:disabled.focus {
  background-color: #999999;
  border-color: #999999;
}
.btn.disabled:hover,
.btn:disabled:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover {
  background-color: #999999;
  border-color: #999999;
}
.btn:focus,
.btn:active,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-link,
.btn.btn-default.btn-link {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active,
.btn.btn-default.btn-link:hover,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:active {
  background-color: transparent;
  color: #999999;
}
.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
  background-color: #ffffff;
  color: #999999;
}
.btn.btn-white.btn-link {
  color: #ffffff;
  background: transparent;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active {
  text-decoration: none !important;
}
.btn.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.btn.btn-disabled:hover {
  box-shadow: none;
}
.btn.btn-raised.btn-link,
.btn-group-raised .btn.btn-link {
  box-shadow: none;
}
.btn.btn-raised.btn-link.active,
.btn-group-raised .btn.btn-link.active {
  box-shadow: none;
}
.btn.btn-raised.btn-link:hover,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:active,
.btn-group-raised .btn.btn-link:hover,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:active {
  box-shadow: none;
}
fieldset[disabled][disabled] .btn.btn-raised,
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled] {
  box-shadow: none;
}
.btn.btn-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-info,
.btn.btn-outline-success,
.btn.btn-outline-warning,
.btn.btn-outline-danger {
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
.btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:focus,
.btn.btn-outline.focus,
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:active,
.btn.btn-outline.active,
.open > .btn.btn-outline.dropdown-toggle,
.show > .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline:active:hover,
.btn.btn-outline:active:focus,
.btn.btn-outline:active.focus,
.btn.btn-outline.active:hover,
.btn.btn-outline.active:focus,
.btn.btn-outline.active.focus,
.open > .btn.btn-outline.dropdown-toggle:hover,
.open > .btn.btn-outline.dropdown-toggle:focus,
.open > .btn.btn-outline.dropdown-toggle.focus,
.show > .btn.btn-outline.dropdown-toggle:hover,
.show > .btn.btn-outline.dropdown-toggle:focus,
.show > .btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline.disabled:focus,
.btn.btn-outline.disabled.focus,
.btn.btn-outline:disabled:focus,
.btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:active,
.bg-inverse .btn.btn-outline.active,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline:active:hover,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active.focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.btn-link {
  background-color: transparent;
}
.btn.btn-outline-primary {
  color: #ffd804;
  background-color: transparent;
  border-color: #ffd804;
}
.btn.btn-outline-primary:hover {
  color: #ffd804;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ffd804;
}
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:hover {
  color: #ffd804;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ffd804;
}
.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active,
.open > .btn.btn-outline-primary.dropdown-toggle,
.show > .btn.btn-outline-primary.dropdown-toggle {
  color: #ffd804;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ffd804;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-primary:active:hover,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active.focus,
.open > .btn.btn-outline-primary.dropdown-toggle:hover,
.open > .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .btn.btn-outline-primary.dropdown-toggle.focus {
  color: #ffd804;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #ffd804;
}
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary:disabled:focus,
.btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #ffd804;
}
.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #ffd804;
}
.bg-inverse .btn.btn-outline-primary {
  color: #ffd804;
  background-color: transparent;
  border-color: #ffd804;
}
.bg-inverse .btn.btn-outline-primary:hover {
  color: #ffd804;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:hover {
  color: #ffd804;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:active,
.bg-inverse .btn.btn-outline-primary.active,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle {
  color: #ffd804;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-primary:active:hover,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active.focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus {
  color: #ffd804;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open
  > .bg-inverse
  .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #ffd804;
}
.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #ffd804;
}
.btn.btn-outline-primary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary.active,
.open > .btn.btn-outline-secondary.dropdown-toggle,
.show > .btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-secondary:active:hover,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active.focus,
.open > .btn.btn-outline-secondary.dropdown-toggle:hover,
.open > .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary:disabled:focus,
.btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:active,
.bg-inverse .btn.btn-outline-secondary.active,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-secondary:active:hover,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active.focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open
  > .bg-inverse
  .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:focus,
.btn.btn-outline-info.focus,
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:active,
.btn.btn-outline-info.active,
.open > .btn.btn-outline-info.dropdown-toggle,
.show > .btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-info:active:hover,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active.focus,
.open > .btn.btn-outline-info.dropdown-toggle:hover,
.open > .btn.btn-outline-info.dropdown-toggle:focus,
.open > .btn.btn-outline-info.dropdown-toggle.focus,
.show > .btn.btn-outline-info.dropdown-toggle:hover,
.show > .btn.btn-outline-info.dropdown-toggle:focus,
.show > .btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #00bcd4;
}
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info:disabled:focus,
.btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:active,
.bg-inverse .btn.btn-outline-info.active,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-info:active:hover,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active.focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.btn-link {
  background-color: transparent;
}
.btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:focus,
.btn.btn-outline-success.focus,
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:active,
.btn.btn-outline-success.active,
.open > .btn.btn-outline-success.dropdown-toggle,
.show > .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-success:active:hover,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active.focus,
.open > .btn.btn-outline-success.dropdown-toggle:hover,
.open > .btn.btn-outline-success.dropdown-toggle:focus,
.open > .btn.btn-outline-success.dropdown-toggle.focus,
.show > .btn.btn-outline-success.dropdown-toggle:hover,
.show > .btn.btn-outline-success.dropdown-toggle:focus,
.show > .btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #4caf50;
}
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success:disabled:focus,
.btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:active,
.bg-inverse .btn.btn-outline-success.active,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-success:active:hover,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active.focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open
  > .bg-inverse
  .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.btn-link {
  background-color: transparent;
}
.btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:active,
.btn.btn-outline-warning.active,
.open > .btn.btn-outline-warning.dropdown-toggle,
.show > .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-warning:active:hover,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active.focus,
.open > .btn.btn-outline-warning.dropdown-toggle:hover,
.open > .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #ff9800;
}
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning:disabled:focus,
.btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:active,
.bg-inverse .btn.btn-outline-warning.active,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-warning:active:hover,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active.focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open
  > .bg-inverse
  .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.btn-link {
  background-color: transparent;
}
.btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:active,
.btn.btn-outline-danger.active,
.open > .btn.btn-outline-danger.dropdown-toggle,
.show > .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-danger:active:hover,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active.focus,
.open > .btn.btn-outline-danger.dropdown-toggle:hover,
.open > .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #f44336;
}
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger:disabled:focus,
.btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:active,
.bg-inverse .btn.btn-outline-danger.active,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-danger:active:hover,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active.focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.btn-link {
  background-color: transparent;
}
.btn.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg .btn {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}
.btn.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm .btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-round {
  border-radius: 30px;
}
.btn.btn-fab,
.btn.btn-just-icon {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}
.btn.btn-fab.btn-round,
.btn.btn-just-icon.btn-round {
  border-radius: 50%;
}
.btn-group-sm .btn.btn-fab,
.btn.btn-fab.btn-sm,
.btn-group-sm > .btn.btn-fab,
.btn.btn-fab.btn-fab-mini,
.btn-group-sm .btn.btn-just-icon,
.btn.btn-just-icon.btn-sm,
.btn-group-sm > .btn.btn-just-icon,
.btn.btn-just-icon.btn-fab-mini {
  height: 30px;
  min-width: 30px;
  width: 30px;
}
.btn-group-sm .btn.btn-fab .material-icons,
.btn-group-sm .btn.btn-fab .fa,
.btn.btn-fab.btn-sm .material-icons,
.btn-group-sm > .btn.btn-fab .material-icons,
.btn.btn-fab.btn-sm .fa,
.btn-group-sm > .btn.btn-fab .fa,
.btn.btn-fab.btn-fab-mini .material-icons,
.btn.btn-fab.btn-fab-mini .fa,
.btn-group-sm .btn.btn-just-icon .material-icons,
.btn-group-sm .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-sm .material-icons,
.btn-group-sm > .btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-sm .fa,
.btn-group-sm > .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-fab-mini .material-icons,
.btn.btn-just-icon.btn-fab-mini .fa {
  font-size: 17px;
  line-height: 29px;
}
.btn-group-lg .btn.btn-fab,
.btn.btn-fab.btn-lg,
.btn-group-lg > .btn.btn-fab,
.btn-group-lg .btn.btn-just-icon,
.btn.btn-just-icon.btn-lg,
.btn-group-lg > .btn.btn-just-icon {
  height: 57px;
  min-width: 57px;
  width: 57px;
  line-height: 56px;
}
.btn-group-lg .btn.btn-fab .material-icons,
.btn-group-lg .btn.btn-fab .fa,
.btn.btn-fab.btn-lg .material-icons,
.btn-group-lg > .btn.btn-fab .material-icons,
.btn.btn-fab.btn-lg .fa,
.btn-group-lg > .btn.btn-fab .fa,
.btn-group-lg .btn.btn-just-icon .material-icons,
.btn-group-lg .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-lg .material-icons,
.btn-group-lg > .btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-lg .fa,
.btn-group-lg > .btn.btn-just-icon .fa {
  font-size: 32px;
  line-height: 56px;
}
.btn.btn-fab .material-icons,
.btn.btn-fab .fa,
.btn.btn-just-icon .material-icons,
.btn.btn-just-icon .fa {
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 41px;
  font-size: 20px;
}
.btn-just-icon.btn-lg,
.btn-group-lg > .btn-just-icon.btn {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
}
.input-group-btn > .btn {
  border: 0;
}
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  position: relative;
  display: inline-block;
  top: 0;
  margin-top: -1em;
  margin-bottom: -1em;
  font-size: 1.1rem;
  vertical-align: middle;
}
.bg-inverse fieldset[disabled][disabled] .btn,
.bg-inverse .btn.disabled,
.bg-inverse .btn:disabled,
.bg-inverse .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .input-group-btn .btn,
.bg-inverse .input-group-btn .btn.disabled,
.bg-inverse .input-group-btn .btn:disabled,
.bg-inverse .input-group-btn .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group,
.bg-inverse .btn-group.disabled,
.bg-inverse .btn-group:disabled,
.bg-inverse .btn-group[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group-vertical,
.bg-inverse .btn-group-vertical.disabled,
.bg-inverse .btn-group-vertical:disabled,
.bg-inverse .btn-group-vertical[disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 0.25rem 0.25rem;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn + .btn,
.btn-group .btn,
.btn-group .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn,
.btn-group-vertical .btn:active,
.btn-group-vertical .btn-group {
  margin: 0;
}
.btn-group > .btn-group,
.btn-group-vertical > .btn-group {
  margin: 0;
}
legend {
  border-bottom: 0;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
a {
  color: #ffd804;
}
a:hover,
a:focus {
  color: #f06873;
  text-decoration: none;
}
a.text-info:hover,
a.text-info:focus {
  color: #00a5bb;
}
a .material-icons {
  vertical-align: middle;
}
.main {
  position: relative;
  z-index: 3;
}
.form-check,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}
.main-raised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
/*           Animations              */
.animation-transition-general,
.carousel .carousel-indicators li {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.animation-transition-slow {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span,
.navbar,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.signup-page .card-signup form .form-check {
  padding-left: 20px;
}
.signup-page .card-signup form .form-check .form-check-label {
  padding-left: 35px;
}
.section-signup .card-signup .card-header {
  width: auto;
}
.section-signup .card-signup .card-body .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.offline-doc .page-header,
.offline-free-doc .page-header {
  height: 100vh !important;
}
.offline-doc .page-header:after,
.offline-free-doc .page-header:after {
  background: rgba(0, 0, 0, 0.5) !important;
}
.offline-doc .footer,
.offline-free-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 1;
}
.offline-doc .footer .copyright a,
.offline-free-doc .footer .copyright a {
  color: #ffffff;
}
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
  color: #ffffff;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tim-row {
  margin-bottom: 20px;
}
.tim-white-buttons {
  background-color: #777777;
}
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}
#map {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 70px);
  margin-top: 70px;
}
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row {
  padding-top: 50px;
}
.tim-row h3 {
  margin-top: 0;
}
#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
  margin-bottom: 0;
}
.switch {
  margin-right: 20px;
}
#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.navigation-example .img-src {
  background-attachment: scroll;
}
.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
}
#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}
#notifications .alert-danger {
  margin-bottom: 0px;
}
.tim-note {
  text-transform: capitalize;
}
#buttons .btn {
  margin: 0 0px 15px;
}
.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}
.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.parallax .parallax-image img {
  width: 100%;
}
@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}
.separator {
  content: "Separator";
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 20px;
}
.separator-line {
  background-color: #eee;
  height: 1px;
  width: 100%;
  display: block;
}
.separator.separator-gray {
  background-color: #eeeeee;
}
.social-buttons-demo .btn {
  margin: 10px 5px 7px 1px;
}
.img-container {
  width: 100%;
  overflow: hidden;
}
.img-container img {
  width: 100%;
}
.section-black {
  background-color: #333;
}
.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}
.sharing-area {
  margin-top: 80px;
}
.sharing-area .btn {
  margin: 15px 4px 0;
  color: #ffffff;
}
.sharing-area .btn i {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
#navbar {
  margin-bottom: -20px;
}
.sharing-area .btn-twitter {
  background-color: #55acee;
}
.sharing-area .btn-facebook {
  background-color: #3b5998;
}
.sharing-area .btn-instagram {
  background-color: #e4405f;
}
.sharing-area .btn-github {
  background-color: #333333;
}
#navbar .navbar {
  border-radius: 0;
}
@media (max-width: 830px) {
  .main-raised {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .tab-pane#schedule-2 {
    padding-top: 20px;
  }
}
.bd-docs .bd-example.bd-example-popover-static .popover,
.bd-docs .bd-example.bd-example-tooltip-static .tooltip {
  z-index: 3 !important;
}
@media all and (max-width: 375px) {
  .page-header {
    /* height: calc(100vh + 270px); */
  }
  .index-page {
    overflow-x: hidden;
  }
  .pagination.pagination-primary .page-item:nth-of-type(3),
  .pagination.pagination-primary .page-item:nth-of-type(7) {
    display: none;
  }
  .pagination.pagination-info .page-item:nth-of-type(2) {
    display: none;
  }
  .main.main-raised {
    margin-top: -30px;
  }
  .form-group.is-focused .bootstrap-datetimepicker-widget {
    left: -32px !important;
  }
}
.show.modal .dropdown-toggle:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.bootstrap-datetimepicker-widget
  .timepicker
  .table-condesed
  .btn
  .ripple-container {
  width: 40px;
  height: 40px;
  margin: -11px 3px;
}
.bd-docs .bd-toc-item .bd-sidenav a span {
  float: right;
  margin-top: 5px;
  padding: 3px 7px;
  font-size: 8px;
  line-height: 9px;
  background-color: #ffd804;
}
.bd-docs .bd-content .bd-title .btn {
  padding: 7px 20px;
  margin-bottom: 10px;
}
.form-check {
  margin-bottom: 0.5rem;
  padding-left: 0;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 0;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-input {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  float: left;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-sign:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-sign .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}
.form-check .form-check-sign .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  color: #ffffff;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
    0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.form-check .form-check-input:focus + .form-check-sign .check:after {
  opacity: 0.2;
}
.form-check .form-check-input:checked ~ .form-check-sign .check {
  background: #ffd804;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:before {
  color: #fff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.form-check .form-check-input:checked ~ .form-check-sign:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.form-check .form-check-input:not(:checked) + .form-check-sign:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.form-check .form-check-input:not(:checked) + .form-check-sign .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input,
.form-check .form-check-input[disabled] ~ .form-check-sign .check,
.form-check .form-check-input[disabled] + .circle {
  opacity: 0.5;
}
.form-check .form-check-input[disabled] ~ .form-check-sign .check {
  border-color: #000000;
  opacity: 0.26;
}
.form-check .form-check-input[disabled] + .form-check-sign .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  transform: rotate(-45deg);
}
.form-check .form-check-input[disabled][checked] + .form-check-sign .check {
  background-color: #000;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.switch label {
  position: relative;
  padding-left: 2.4375rem;
}
.switch label .bmd-switch-track {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  display: inline-block;
  width: 2.125rem;
  height: 0.875rem;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.12) 0%,
    rgba(0, 0, 0, 0.12) 50%,
    #c49bcb 50%,
    #c49bcb 100%
  );
  background-position: 0%;
  background-size: 4.25rem 0.875rem;
  border-radius: 2.125rem;
  transition: background-position 0.2s ease-in;
}
.switch label .bmd-switch-track::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background: #f1f1f1;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: left 0.2s ease-in, background-color 0.2s ease-in,
    transform 0.3s ease;
  transform: translateY(-50%);
}
.switch label .bmd-switch-track:active::after {
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}
.switch label input {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.switch label input:checked + .bmd-switch-track {
  background-position: -100%;
}
.switch label input:checked + .bmd-switch-track::after {
  left: calc(100% - 1.25rem);
  background-color: #ffd804;
}
.switch label input:disabled + .bmd-switch-track:active::after {
  transform: translateY(-50%);
}
.switch label input:disabled + .bmd-switch-track {
  cursor: default;
  background: rgba(0, 0, 0, 0.12);
}
.switch label input:disabled + .bmd-switch-track::after {
  background: #bdbdbd;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 8px;
  position: relative;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-label span {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  transition-duration: 0.2s;
}
.form-check .form-check-label .circle {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  top: 1px;
}
.form-check .form-check-label .circle .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #ffd804;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.form-check .form-check-input {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.form-check .form-check-input:checked ~ .check,
.form-check .form-check-input:checked ~ .circle {
  opacity: 1;
}
.form-check .form-check-input:checked ~ .check {
  background-color: #ffd804;
}
.form-check .form-check-input:checked ~ .circle {
  border-color: #ffd804;
}
.form-check .form-check-input:checked .check:before {
  animation: checkboxOn 0.5s forwards;
}
.form-check .form-check-input:checked ~ .circle .check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}
.form-check .form-check-input[disabled] ~ .check,
.form-check .form-check-input[disabled] ~ .circle {
  opacity: 0.26;
}
.form-check .form-check-input[disabled] ~ .check {
  background-color: #000;
}
.form-check .form-check-input[disabled] ~ .circle {
  border-color: #000;
}
.form-check .form-check-input[disabled] + .circle .check {
  background-color: #000;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  float: left;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-label .circle:before {
  display: block;
  position: absolute;
  left: -1px;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: -1px;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-label .form-check-input:checked + .circle:before {
  animation: rippleOn 0.5s;
}
.form-check
  .form-check-label
  .form-check-input:checked
  + .circle
  .check:before {
  color: #ffffff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkboxOn 0.3s forwards;
}
.form-check + .form-check {
  margin-top: 0;
}
@keyframes checkboxOn {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}
.carousel .carousel-control.right i {
  right: 0;
}
.carousel .card {
  margin-top: 0;
}
.carousel .item {
  text-align: center;
}
.carousel.carousel-full-navigation .carousel-control {
  width: 50%;
}
.carousel.carousel-full-navigation .carousel-control.left,
.carousel.carousel-full-navigation .carousel-control.right {
  background-image: none;
}
.carousel.carousel-full-navigation .carousel-control .material-icons,
.carousel.carousel-full-navigation .carousel-control .fa {
  display: none;
}
.carousel .carousel-control {
  height: 15%;
  margin: auto;
}
.carousel .carousel-control-prev .material-icons,
.carousel .carousel-control-next .material-icons {
  z-index: 5;
  display: inline-block;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  margin: auto;
}
.carousel .carousel-indicators {
  bottom: 5px;
}
/* .carousel .carousel-indicators li,
.carousel .carousel-indicators .active {
  margin: 11px 10px;
}
.carousel .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.carousel .carousel-indicators .active {
  margin-top: 10px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
} */
.carousel .carousel-caption {
  padding-bottom: 45px;
}
.carousel .carousel-caption .material-icons {
  position: relative;
  top: 5px;
}
form {
  margin-bottom: 1.125rem;
}
.card form {
  margin: 0;
}
.navbar form {
  margin-bottom: 0;
}
.navbar form .bmd-form-group {
  display: inline-block;
  padding-top: 0;
}
.navbar form .btn {
  margin-bottom: 0;
}
.form-control {
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border: 0;
  height: 36px;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  font-size: 14px;
}
.form-control:focus,
.bmd-form-group.is-focused .form-control {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
}
.form-control::-moz-placeholder {
  color: #aaaaaa;
  font-weight: 400;
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
  font-size: 14px;
}
.form-control::-webkit-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
  font-size: 14px;
}
.has-white .form-control::-moz-placeholder {
  color: #ffffff;
}
.has-white .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.has-white .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.bmd-help {
  position: absolute;
  display: none;
  font-size: 0.8rem;
  font-weight: normal;
}
.bmd-form-group.is-focused .bmd-help {
  display: block;
}
.bmd-help:nth-of-type(2) {
  padding-top: 1rem;
}
.bmd-help + .bmd-help {
  position: relative;
  margin-bottom: 0;
}
.radio label,
.is-focused .radio label,
.radio-inline,
.is-focused .radio-inline,
.checkbox label,
.is-focused .checkbox label,
.checkbox-inline,
.is-focused .checkbox-inline,
.switch label,
.is-focused .switch label {
  color: #999999;
}
.radio label label:has(input[type="radio"][disabled]),
.radio label label:has(input[type="radio"][disabled]):hover,
.radio label label:has(input[type="radio"][disabled]):focus,
.radio label label:has(input[type="checkbox"][disabled]),
.radio label label:has(input[type="checkbox"][disabled]):hover,
.radio label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .radio label,
fieldset[disabled] .radio label:hover,
fieldset[disabled] .radio label:focus,
.is-focused .radio label label:has(input[type="radio"][disabled]),
.is-focused .radio label label:has(input[type="radio"][disabled]):hover,
.is-focused .radio label label:has(input[type="radio"][disabled]):focus,
.is-focused .radio label label:has(input[type="checkbox"][disabled]),
.is-focused .radio label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .radio label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .is-focused .radio label,
fieldset[disabled] .is-focused .radio label:hover,
fieldset[disabled] .is-focused .radio label:focus,
.radio-inline label:has(input[type="radio"][disabled]),
.radio-inline label:has(input[type="radio"][disabled]):hover,
.radio-inline label:has(input[type="radio"][disabled]):focus,
.radio-inline label:has(input[type="checkbox"][disabled]),
.radio-inline label:has(input[type="checkbox"][disabled]):hover,
.radio-inline label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio-inline:hover,
fieldset[disabled] .radio-inline:focus,
.is-focused .radio-inline label:has(input[type="radio"][disabled]),
.is-focused .radio-inline label:has(input[type="radio"][disabled]):hover,
.is-focused .radio-inline label:has(input[type="radio"][disabled]):focus,
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]),
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]):hover,
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .is-focused .radio-inline,
fieldset[disabled] .is-focused .radio-inline:hover,
fieldset[disabled] .is-focused .radio-inline:focus,
.checkbox label label:has(input[type="radio"][disabled]),
.checkbox label label:has(input[type="radio"][disabled]):hover,
.checkbox label label:has(input[type="radio"][disabled]):focus,
.checkbox label label:has(input[type="checkbox"][disabled]),
.checkbox label label:has(input[type="checkbox"][disabled]):hover,
.checkbox label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox label:hover,
fieldset[disabled] .checkbox label:focus,
.is-focused .checkbox label label:has(input[type="radio"][disabled]),
.is-focused .checkbox label label:has(input[type="radio"][disabled]):hover,
.is-focused .checkbox label label:has(input[type="radio"][disabled]):focus,
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]),
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .is-focused .checkbox label,
fieldset[disabled] .is-focused .checkbox label:hover,
fieldset[disabled] .is-focused .checkbox label:focus,
.checkbox-inline label:has(input[type="radio"][disabled]),
.checkbox-inline label:has(input[type="radio"][disabled]):hover,
.checkbox-inline label:has(input[type="radio"][disabled]):focus,
.checkbox-inline label:has(input[type="checkbox"][disabled]),
.checkbox-inline label:has(input[type="checkbox"][disabled]):hover,
.checkbox-inline label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox-inline:hover,
fieldset[disabled] .checkbox-inline:focus,
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]),
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]),
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .is-focused .checkbox-inline,
fieldset[disabled] .is-focused .checkbox-inline:hover,
fieldset[disabled] .is-focused .checkbox-inline:focus,
.switch label label:has(input[type="radio"][disabled]),
.switch label label:has(input[type="radio"][disabled]):hover,
.switch label label:has(input[type="radio"][disabled]):focus,
.switch label label:has(input[type="checkbox"][disabled]),
.switch label label:has(input[type="checkbox"][disabled]):hover,
.switch label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .switch label,
fieldset[disabled] .switch label:hover,
fieldset[disabled] .switch label:focus,
.is-focused .switch label label:has(input[type="radio"][disabled]),
.is-focused .switch label label:has(input[type="radio"][disabled]):hover,
.is-focused .switch label label:has(input[type="radio"][disabled]):focus,
.is-focused .switch label label:has(input[type="checkbox"][disabled]),
.is-focused .switch label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .switch label label:has(input[type="checkbox"][disabled]):focus,
fieldset[disabled] .is-focused .switch label,
fieldset[disabled] .is-focused .switch label:hover,
fieldset[disabled] .is-focused .switch label:focus {
  color: #999999;
}
[class^="bmd-label"],
[class*=" bmd-label"] {
  color: #999999;
}
.form-control,
.is-focused .form-control {
  background-image: linear-gradient(
      to top,
      #ffd804 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.form-control.form-control-success,
.is-focused .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #ffd804 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.form-control.form-control-warning,
.is-focused .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #ffd804 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.form-control.form-control-danger,
.is-focused .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #ffd804 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #999999;
}
.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(153, 153, 153, 0.8);
  border-radius: 0.2rem;
}
.was-validated .is-focused .form-control:valid,
.is-focused .form-control.is-valid,
.was-validated .is-focused .custom-select:valid,
.is-focused .custom-select.is-valid {
  border-color: #999999;
}
.was-validated .is-focused .form-control:valid:focus,
.is-focused .form-control.is-valid:focus,
.was-validated .is-focused .custom-select:valid:focus,
.is-focused .custom-select.is-valid:focus {
  border-color: #999999;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .is-focused .form-control:valid ~ .valid-tooltip,
.is-focused .form-control.is-valid ~ .valid-feedback,
.is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .is-focused .custom-select:valid ~ .valid-tooltip,
.is-focused .custom-select.is-valid ~ .valid-feedback,
.is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .is-focused .form-check-input:valid ~ .form-check-label,
.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #999999;
}
.was-validated .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .is-focused .form-check-input:valid ~ .valid-tooltip,
.is-focused .form-check-input.is-valid ~ .valid-feedback,
.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-control-input:valid ~ .custom-control-label,
.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #999999;
}
.was-validated
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #d9d9d9;
}
.was-validated .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-control-input:valid ~ .valid-tooltip,
.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #b3b3b3;
}
.was-validated
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .custom-file-input:valid ~ .custom-file-label,
.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #999999;
}
.was-validated
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.is-focused .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-file-input:valid ~ .valid-tooltip,
.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-file-input:valid:focus ~ .custom-file-label,
.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused [class^="bmd-label"],
.is-focused [class*=" bmd-label"] {
  color: #ffd804;
}
.is-focused .bmd-label-placeholder {
  color: #999999;
}
.is-focused .form-control {
  border-color: #d2d2d2;
}
.is-focused .bmd-help {
  color: #555;
}
.has-success [class^="bmd-label"],
.has-success [class*=" bmd-label"] {
  color: #4caf50;
}
.has-success .form-control,
.is-focused .has-success .form-control {
  background-image: linear-gradient(
      to top,
      #4caf50 2px,
      rgba(76, 175, 80, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-success .form-control,
.has-success .form-control.disabled,
.has-success .form-control:disabled,
.has-success .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-success .form-control.form-control-success,
.is-focused .has-success .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #4caf50 2px,
      rgba(76, 175, 80, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-success .form-control.form-control-warning,
.is-focused .has-success .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #4caf50 2px,
      rgba(76, 175, 80, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-success .form-control.form-control-danger,
.is-focused .has-success .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #4caf50 2px,
      rgba(76, 175, 80, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-success .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.has-success .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-success .is-focused .form-control:valid,
.has-success .is-focused .form-control.is-valid,
.was-validated .has-success .is-focused .custom-select:valid,
.has-success .is-focused .custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .has-success .is-focused .form-control:valid:focus,
.has-success .is-focused .form-control.is-valid:focus,
.was-validated .has-success .is-focused .custom-select:valid:focus,
.has-success .is-focused .custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .has-success .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .form-control:valid ~ .valid-tooltip,
.has-success .is-focused .form-control.is-valid ~ .valid-feedback,
.has-success .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-select:valid ~ .valid-tooltip,
.has-success .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-success
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-success .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #4caf50;
}
.was-validated
  .has-success
  .is-focused
  .form-check-input:valid
  ~ .valid-feedback,
.was-validated
  .has-success
  .is-focused
  .form-check-input:valid
  ~ .valid-tooltip,
.has-success .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-success .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-success
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label {
  color: #4caf50;
}
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-success
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-success .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-success
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #6ec071;
}
.was-validated
  .has-success
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-success
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated
  .has-success
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-success .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4caf50;
}
.was-validated
  .has-success
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-success
  .is-focused
  .custom-file-input.is-valid
  ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated
  .has-success
  .is-focused
  .custom-file-input:valid
  ~ .valid-feedback,
.was-validated
  .has-success
  .is-focused
  .custom-file-input:valid
  ~ .valid-tooltip,
.has-success .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-success
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-success
  .is-focused
  .custom-file-input.is-valid:focus
  ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused [class^="bmd-label"],
.has-success .is-focused [class*=" bmd-label"] {
  color: #4caf50;
}
.has-success .is-focused .bmd-label-placeholder {
  color: #4caf50;
}
.has-success .is-focused .form-control {
  border-color: #4caf50;
}
.has-success .is-focused .bmd-help {
  color: #555;
}
.has-info [class^="bmd-label"],
.has-info [class*=" bmd-label"] {
  color: #00bcd4;
}
.has-info .form-control,
.is-focused .has-info .form-control {
  background-image: linear-gradient(
      to top,
      #00bcd4 2px,
      rgba(0, 188, 212, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-info .form-control,
.has-info .form-control.disabled,
.has-info .form-control:disabled,
.has-info .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-info .form-control.form-control-success,
.is-focused .has-info .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #00bcd4 2px,
      rgba(0, 188, 212, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-info .form-control.form-control-warning,
.is-focused .has-info .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #00bcd4 2px,
      rgba(0, 188, 212, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-info .form-control.form-control-danger,
.is-focused .has-info .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #00bcd4 2px,
      rgba(0, 188, 212, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-info .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00bcd4;
}
.has-info .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 188, 212, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-info .is-focused .form-control:valid,
.has-info .is-focused .form-control.is-valid,
.was-validated .has-info .is-focused .custom-select:valid,
.has-info .is-focused .custom-select.is-valid {
  border-color: #00bcd4;
}
.was-validated .has-info .is-focused .form-control:valid:focus,
.has-info .is-focused .form-control.is-valid:focus,
.was-validated .has-info .is-focused .custom-select:valid:focus,
.has-info .is-focused .custom-select.is-valid:focus {
  border-color: #00bcd4;
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated .has-info .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-control:valid ~ .valid-tooltip,
.has-info .is-focused .form-control.is-valid ~ .valid-feedback,
.has-info .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-select:valid ~ .valid-tooltip,
.has-info .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-info
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-info .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #00bcd4;
}
.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-tooltip,
.has-info .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-info .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-info .is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #00bcd4;
}
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-info
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: #55ecff;
}
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-info .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-info
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #08e3ff;
}
.was-validated
  .has-info
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-info
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated
  .has-info
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-info .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00bcd4;
}
.was-validated
  .has-info
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-info .is-focused .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-tooltip,
.has-info .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-info
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-info .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused [class^="bmd-label"],
.has-info .is-focused [class*=" bmd-label"] {
  color: #00bcd4;
}
.has-info .is-focused .bmd-label-placeholder {
  color: #00bcd4;
}
.has-info .is-focused .form-control {
  border-color: #00bcd4;
}
.has-info .is-focused .bmd-help {
  color: #555;
}
.has-white [class^="bmd-label"],
.has-white [class*=" bmd-label"] {
  color: #ffffff;
}
.has-white .form-control,
.is-focused .has-white .form-control {
  background-image: linear-gradient(
      to top,
      #ffffff 2px,
      rgba(255, 255, 255, 0) 2px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px);
}
fieldset[disabled][disabled] .has-white .form-control,
.has-white .form-control.disabled,
.has-white .form-control:disabled,
.has-white .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-white .form-control.form-control-success,
.is-focused .has-white .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #ffffff 2px,
      rgba(255, 255, 255, 0) 2px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-white .form-control.form-control-warning,
.is-focused .has-white .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #ffffff 2px,
      rgba(255, 255, 255, 0) 2px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-white .form-control.form-control-danger,
.is-focused .has-white .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #ffffff 2px,
      rgba(255, 255, 255, 0) 2px
    ),
    linear-gradient(to top, #ffffff 1px, rgba(255, 255, 255, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-white .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ffffff;
}
.has-white .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-white .is-focused .form-control:valid,
.has-white .is-focused .form-control.is-valid,
.was-validated .has-white .is-focused .custom-select:valid,
.has-white .is-focused .custom-select.is-valid {
  border-color: #ffffff;
}
.was-validated .has-white .is-focused .form-control:valid:focus,
.has-white .is-focused .form-control.is-valid:focus,
.was-validated .has-white .is-focused .custom-select:valid:focus,
.has-white .is-focused .custom-select.is-valid:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated .has-white .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .form-control:valid ~ .valid-tooltip,
.has-white .is-focused .form-control.is-valid ~ .valid-feedback,
.has-white .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .custom-select:valid ~ .valid-tooltip,
.has-white .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-white
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-white .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #ffffff;
}
.was-validated .has-white .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .form-check-input:valid ~ .valid-tooltip,
.has-white .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-white .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-white .is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #ffffff;
}
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-white
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: white;
}
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-white .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-white
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: white;
}
.was-validated
  .has-white
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-white
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated
  .has-white
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-white .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #ffffff;
}
.was-validated
  .has-white
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-white
  .is-focused
  .custom-file-input.is-valid
  ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated
  .has-white
  .is-focused
  .custom-file-input:valid
  ~ .valid-feedback,
.was-validated .has-white .is-focused .custom-file-input:valid ~ .valid-tooltip,
.has-white .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-white
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-white .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused [class^="bmd-label"],
.has-white .is-focused [class*=" bmd-label"] {
  color: #ffffff;
}
.has-white .is-focused .bmd-label-placeholder {
  color: #ffffff;
}
.has-white .is-focused .form-control {
  border-color: #ffffff;
}
.has-white .is-focused .bmd-help {
  color: #555;
}
.has-white .form-control:focus {
  color: #ffffff;
}
.has-warning [class^="bmd-label"],
.has-warning [class*=" bmd-label"] {
  color: #ff9800;
}
.has-warning .form-control,
.is-focused .has-warning .form-control {
  background-image: linear-gradient(
      to top,
      #ff9800 2px,
      rgba(255, 152, 0, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-warning .form-control,
.has-warning .form-control.disabled,
.has-warning .form-control:disabled,
.has-warning .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #ff9800 2px,
      rgba(255, 152, 0, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #ff9800 2px,
      rgba(255, 152, 0, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #ff9800 2px,
      rgba(255, 152, 0, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-warning .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff9800;
}
.has-warning .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 152, 0, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-warning .is-focused .form-control:valid,
.has-warning .is-focused .form-control.is-valid,
.was-validated .has-warning .is-focused .custom-select:valid,
.has-warning .is-focused .custom-select.is-valid {
  border-color: #ff9800;
}
.was-validated .has-warning .is-focused .form-control:valid:focus,
.has-warning .is-focused .form-control.is-valid:focus,
.was-validated .has-warning .is-focused .custom-select:valid:focus,
.has-warning .is-focused .custom-select.is-valid:focus {
  border-color: #ff9800;
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated .has-warning .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .form-control:valid ~ .valid-tooltip,
.has-warning .is-focused .form-control.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-select:valid ~ .valid-tooltip,
.has-warning .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-warning
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-warning .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #ff9800;
}
.was-validated
  .has-warning
  .is-focused
  .form-check-input:valid
  ~ .valid-feedback,
.was-validated
  .has-warning
  .is-focused
  .form-check-input:valid
  ~ .valid-tooltip,
.has-warning .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-warning
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label {
  color: #ff9800;
}
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-warning
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: #ffcc80;
}
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-warning .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-warning
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #ffad33;
}
.was-validated
  .has-warning
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-warning
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated
  .has-warning
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-warning .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #ff9800;
}
.was-validated
  .has-warning
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-warning
  .is-focused
  .custom-file-input.is-valid
  ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated
  .has-warning
  .is-focused
  .custom-file-input:valid
  ~ .valid-feedback,
.was-validated
  .has-warning
  .is-focused
  .custom-file-input:valid
  ~ .valid-tooltip,
.has-warning .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-warning
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-warning
  .is-focused
  .custom-file-input.is-valid:focus
  ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused [class^="bmd-label"],
.has-warning .is-focused [class*=" bmd-label"] {
  color: #ff9800;
}
.has-warning .is-focused .bmd-label-placeholder {
  color: #ff9800;
}
.has-warning .is-focused .form-control {
  border-color: #ff9800;
}
.has-warning .is-focused .bmd-help {
  color: #555;
}
.has-danger [class^="bmd-label"],
.has-danger [class*=" bmd-label"] {
  color: #f44336;
}
.has-danger .form-control,
.is-focused .has-danger .form-control {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-danger .form-control,
.has-danger .form-control.disabled,
.has-danger .form-control:disabled,
.has-danger .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-danger .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.has-danger .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-danger .is-focused .form-control:valid,
.has-danger .is-focused .form-control.is-valid,
.was-validated .has-danger .is-focused .custom-select:valid,
.has-danger .is-focused .custom-select.is-valid {
  border-color: #f44336;
}
.was-validated .has-danger .is-focused .form-control:valid:focus,
.has-danger .is-focused .form-control.is-valid:focus,
.was-validated .has-danger .is-focused .custom-select:valid:focus,
.has-danger .is-focused .custom-select.is-valid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .has-danger .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-control:valid ~ .valid-tooltip,
.has-danger .is-focused .form-control.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-select:valid ~ .valid-tooltip,
.has-danger .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-danger
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-danger .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #f44336;
}
.was-validated
  .has-danger
  .is-focused
  .form-check-input:valid
  ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid ~ .valid-tooltip,
.has-danger .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-danger .is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #f44336;
}
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-danger
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-danger .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-danger
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #f77066;
}
.was-validated
  .has-danger
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-danger
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated
  .has-danger
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #f44336;
}
.was-validated
  .has-danger
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-danger
  .is-focused
  .custom-file-input.is-valid
  ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated
  .has-danger
  .is-focused
  .custom-file-input:valid
  ~ .valid-feedback,
.was-validated
  .has-danger
  .is-focused
  .custom-file-input:valid
  ~ .valid-tooltip,
.has-danger .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-danger
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused [class^="bmd-label"],
.has-danger .is-focused [class*=" bmd-label"] {
  color: #f44336;
}
.has-danger .is-focused .bmd-label-placeholder {
  color: #f44336;
}
.has-danger .is-focused .form-control {
  border-color: #f44336;
}
.has-danger .is-focused .bmd-help {
  color: #555;
}
.has-rose [class^="bmd-label"],
.has-rose [class*=" bmd-label"] {
  color: #e91e63;
}
.has-rose .form-control,
.is-focused .has-rose .form-control {
  background-image: linear-gradient(
      to top,
      #e91e63 2px,
      rgba(233, 30, 99, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:invalid {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(244, 67, 54, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:read-only {
  background-image: linear-gradient(
      to top,
      #d2d2d2 1px,
      rgba(210, 210, 210, 0) 1px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-rose .form-control,
.has-rose .form-control.disabled,
.has-rose .form-control:disabled,
.has-rose .form-control[disabled] {
  background-image: linear-gradient(
    to right,
    #d2d2d2 0%,
    #d2d2d2 30%,
    transparent 30%,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-rose .form-control.form-control-success,
.is-focused .has-rose .form-control.form-control-success {
  background-image: linear-gradient(
      to top,
      #e91e63 2px,
      rgba(233, 30, 99, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-rose .form-control.form-control-warning,
.is-focused .has-rose .form-control.form-control-warning {
  background-image: linear-gradient(
      to top,
      #e91e63 2px,
      rgba(233, 30, 99, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-rose .form-control.form-control-danger,
.is-focused .has-rose .form-control.form-control-danger {
  background-image: linear-gradient(
      to top,
      #e91e63 2px,
      rgba(233, 30, 99, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-rose .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e91e63;
}
.has-rose .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(233, 30, 99, 0.8);
  border-radius: 0.2rem;
}
.was-validated .has-rose .is-focused .form-control:valid,
.has-rose .is-focused .form-control.is-valid,
.was-validated .has-rose .is-focused .custom-select:valid,
.has-rose .is-focused .custom-select.is-valid {
  border-color: #e91e63;
}
.was-validated .has-rose .is-focused .form-control:valid:focus,
.has-rose .is-focused .form-control.is-valid:focus,
.was-validated .has-rose .is-focused .custom-select:valid:focus,
.has-rose .is-focused .custom-select.is-valid:focus {
  border-color: #e91e63;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated .has-rose .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .form-control:valid ~ .valid-tooltip,
.has-rose .is-focused .form-control.is-valid ~ .valid-feedback,
.has-rose .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .custom-select:valid ~ .valid-tooltip,
.has-rose .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-rose
  .is-focused
  .form-check-input:valid
  ~ .form-check-label,
.has-rose .is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #e91e63;
}
.was-validated .has-rose .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .form-check-input:valid ~ .valid-tooltip,
.has-rose .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label,
.has-rose .is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #e91e63;
}
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid
  ~ .custom-control-label::before,
.has-rose
  .is-focused
  .custom-control-input.is-valid
  ~ .custom-control-label::before {
  background-color: #f492b4;
}
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid
  ~ .valid-feedback,
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid
  ~ .valid-tooltip,
.has-rose .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.has-rose
  .is-focused
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  background-color: #ee4c83;
}
.was-validated
  .has-rose
  .is-focused
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.has-rose
  .is-focused
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated
  .has-rose
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #e91e63;
}
.was-validated
  .has-rose
  .is-focused
  .custom-file-input:valid
  ~ .custom-file-label::before,
.has-rose .is-focused .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .valid-tooltip,
.has-rose .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated
  .has-rose
  .is-focused
  .custom-file-input:valid:focus
  ~ .custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused [class^="bmd-label"],
.has-rose .is-focused [class*=" bmd-label"] {
  color: #e91e63;
}
.has-rose .is-focused .bmd-label-placeholder {
  color: #e91e63;
}
.has-rose .is-focused .form-control {
  border-color: #e91e63;
}
.has-rose .is-focused .bmd-help {
  color: #555;
}
.bmd-form-group {
  position: relative;
  padding-top: 27px;
}
.bmd-form-group:not(.has-success):not(.has-danger)
  [class^="bmd-label"].bmd-label-floating,
.bmd-form-group:not(.has-success):not(.has-danger)
  [class*=" bmd-label"].bmd-label-floating {
  color: #aaaaaa;
}
.bmd-form-group [class^="bmd-label"],
.bmd-form-group [class*=" bmd-label"] {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}
.bmd-form-group [class^="bmd-label"].bmd-label-floating,
.bmd-form-group [class*=" bmd-label"].bmd-label-floating {
  will-change: left, top, contents;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
}
.bmd-form-group.is-filled .bmd-label-placeholder {
  display: none;
}
.bmd-form-group.bmd-collapse-inline {
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 2.1em;
}
.bmd-form-group.bmd-collapse-inline .collapse {
  flex: 1;
  display: none;
}
.bmd-form-group.bmd-collapse-inline .collapse.show {
  max-width: 1200px;
}
.bmd-form-group.bmd-collapse-inline .collapsing,
.bmd-form-group.bmd-collapse-inline .width:not(.collapse),
.bmd-form-group.bmd-collapse-inline .collapse.show {
  display: block;
}
.bmd-form-group.bmd-collapse-inline .collapsing {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-form-group .form-control,
.bmd-form-group label,
.bmd-form-group input::placeholder {
  line-height: 1.1;
}
.bmd-form-group label {
  color: #aaaaaa;
}
.bmd-form-group .radio label,
.bmd-form-group label.radio-inline,
.bmd-form-group .checkbox label,
.bmd-form-group label.checkbox-inline,
.bmd-form-group .switch label {
  line-height: 1.5;
}
.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group label {
  font-size: 0.875rem;
}
.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
  top: 2.3125rem;
}
.bmd-form-group .is-focused .bmd-label-floating,
.bmd-form-group .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group .bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px),
    center right 0.46875rem;
}
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm label,
.bmd-form-group.bmd-form-group-sm input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-sm label {
  color: #aaaaaa;
}
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label.radio-inline,
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.bmd-form-group.bmd-form-group-sm .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
  top: 1.875rem;
}
.bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating {
  top: 0.75rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-static {
  top: 0.1rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-help {
  margin-top: 0;
  font-size: 0.65625rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px),
    center right 0.34375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-sm
  .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg label,
.bmd-form-group.bmd-form-group-lg input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-lg label {
  color: #aaaaaa;
}
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label.radio-inline,
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.bmd-form-group.bmd-form-group-lg .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
  top: 2.4375rem;
}
.bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-help {
  margin-top: 0;
  font-size: 0.9375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px),
    center right 0.59375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused
  .bmd-form-group.bmd-form-group-lg
  .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.form-control,
label,
input::placeholder {
  line-height: 1.1;
}
label {
  color: #aaaaaa;
}
.radio label,
label.radio-inline,
.checkbox label,
label.checkbox-inline,
.switch label {
  line-height: 1.5;
}
.checkbox label,
.radio label,
label {
  font-size: 0.875rem;
}
.bmd-label-floating,
.bmd-label-placeholder {
  top: 2.3125rem;
}
.is-focused .bmd-label-floating,
.is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.form-control.form-control-success,
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px),
    center right 0.46875rem;
}
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
select,
select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
}
@media (min-width: 576px) {
  .form-inline .input-group {
    display: inline-flex;
    align-items: center;
  }
}
.form-control-feedback {
  position: absolute;
  top: 33px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
}
.has-success .form-control-feedback {
  color: #4caf50;
  opacity: 1;
}
.has-danger .form-control-feedback {
  color: #f44336;
  opacity: 1;
}
.form-group {
  margin-bottom: 17px;
  position: relative;
}
textarea {
  height: auto !important;
  resize: none;
  line-height: 1.428571 !important;
}
.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.form-newsletter .input-group,
.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
  padding-top: 5px;
}
.form-newsletter .btn {
  float: left;
  width: 20%;
  margin: 9px 0 0;
}
.form-file-upload .input-group-btn:last-child > .btn-round {
  border-radius: 30px;
}
.form-file-upload .input-group-btn .btn {
  margin: 0;
}
.form-file-upload .input-group {
  width: 100%;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 15px;
  background-color: transparent;
  border-color: transparent;
}
.input-group .input-group-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem 0;
}
.list-group.bmd-list-group-sm {
  padding: 0.25rem 0;
}
.list-group.bmd-list-group-sm .list-group-item {
  padding: 0.5rem 1rem;
}
.bmd-list-group-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.list-group-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1rem;
  line-height: 1;
}
.list-group-item .list-group-item-text {
  min-width: 0;
  max-height: 2.188rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-group-item :first-child {
  margin-right: 2rem;
}
.list-group-item > .pull-xs-right,
.list-group-item > .pull-sm-right,
.list-group-item > .pull-md-right,
.list-group-item > .pull-lg-right,
.list-group-item > .pull-xl-right,
.list-group-item > * ~ .label:last-child,
.list-group-item > * ~ .material-icons:last-child {
  margin-right: 0;
  margin-left: auto;
}
.list-group-item .material-icons.pull-xs-right,
.list-group-item .material-icons.pull-sm-right,
.list-group-item .material-icons.pull-md-right,
.list-group-item .material-icons.pull-lg-right,
.list-group-item .material-icons.pull-xl-right,
.list-group-item .material-icons ~ .material-icons:last-child {
  padding-left: 1rem;
}
.list-group-item .list-group-item-text {
  font-size: 0.875rem;
  color: #555;
}
/* .nav-link {
  text-transform: uppercase;
} */
.navbar-nav .nav-link {
  padding: 0.5321rem;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-tabs,
.nav-pills {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-link,
.nav-pills .nav-link {
  padding: 1.4286em 0.8575em;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
}
.nav .nav-item {
  position: relative;
}
.img-thumbnail {
  border-radius: 16px;
}
.img-raised {
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.rounded {
  border-radius: 6px !important;
}
.navbar,
.makeStyles-appBar-6 {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 15px 0px !important;
  margin-bottom: 20px;
  color: #fff !important;
  background-color: #1e2023 !important;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 255, 255, 0.4);
  background-color: #ffffff;
  color: #555;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-color: #555;
}
.navbar.fixed-top {
  border-radius: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  color: inherit;
  padding: 0.9375rem;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 20px;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position: relative;
  top: 2px;
  margin-top: -4px;
  margin-right: 4px;
}
.navbar .navbar-nav .nav-item .nav-link .material-icons,
.navbar .navbar-nav .nav-item .nav-link .fa {
  font-size: 1.25rem;
  max-width: 24px;
  margin-top: -1.1em;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top: -7px;
  top: 3px;
  position: relative;
  margin-right: 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo {
  padding: 0;
  margin: 0 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
  display: none;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
  height: 40px;
  width: 40px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .ripple-container {
  border-radius: 50%;
}
.navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 100% 0;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .btn,
.navbar .navbar-nav .nav-item .btn {
  margin-top: 0;
  margin-bottom: 0;
}
.navbar .navbar-toggler {
  cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 22px;
  height: 2px;
  vertical-align: middle;
  outline: 0;
  display: block;
  border-radius: 1px;
}
.navbar .navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
  margin-top: 4px;
}
.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.navbar .navbar-wrapper {
  display: inline-flex;
  align-items: center;
}
.navbar .navbar-brand {
  position: relative;
  color: inherit;
  height: 50px;
  font-size: 1.125rem;
  line-height: 30px;
  padding: 0.625rem 0;
}
.navbar.bg-primary {
  color: #ffffff;
  background-color: #ffd804 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(156, 39, 176, 0.46);
}
.navbar.bg-primary .dropdown-item:hover,
.navbar.bg-primary .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #ffd804;
  color: #ffffff;
}
.navbar.bg-primary .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-info {
  color: #ffffff;
  background-color: #00bcd4 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(0, 188, 212, 0.46);
}
.navbar.bg-info .dropdown-item:hover,
.navbar.bg-info .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background-color: #00bcd4;
  color: #ffffff;
}
.navbar.bg-info .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-warning {
  color: #ffffff;
  background-color: #ff9800 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(255, 152, 0, 0.46);
}
.navbar.bg-warning .dropdown-item:hover,
.navbar.bg-warning .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #ff9800;
  color: #ffffff;
}
.navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-rose {
  color: #ffffff;
  background-color: #e91e63 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(233, 30, 99, 0.46);
}
.navbar.bg-rose .dropdown-item:hover,
.navbar.bg-rose .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4);
  background-color: #e91e63;
  color: #ffffff;
}
.navbar.bg-rose .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-danger {
  color: #ffffff;
  background-color: #f44336 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(244, 67, 54, 0.46);
}
.navbar.bg-danger .dropdown-item:hover,
.navbar.bg-danger .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background-color: #f44336;
  color: #ffffff;
}
.navbar.bg-danger .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-success {
  color: #ffffff;
  background-color: #4caf50 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(76, 175, 80, 0.46);
}
.navbar.bg-success .dropdown-item:hover,
.navbar.bg-success .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background-color: #4caf50;
  color: #ffffff;
}
.navbar.bg-success .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-dark {
  color: #ffffff;
  background-color: #212121 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(33, 33, 33, 0.46);
}
.navbar.bg-dark .dropdown-item:hover,
.navbar.bg-dark .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 33, 33, 0.4);
  background-color: #212121;
  color: #ffffff;
}
.navbar.bg-dark .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  padding-top: 25px;
  color: #ffffff;
}
#navbar .navbar {
  border-radius: 0;
}
.badge {
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: #ffffff;
}
.badge.badge-primary {
  background-color: #ffd804;
}
.badge.badge-info {
  background-color: #00bcd4;
}
.badge.badge-success {
  background-color: #4caf50;
}
.badge.badge-warning {
  background-color: #ff9800;
}
.badge.badge-danger {
  background-color: #f44336;
}
.badge.badge-rose {
  background-color: #e91e63;
}
.badge.badge-default {
  background-color: #999;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #c1c1c1;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #f06873;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #00a5bb;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #449d48;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #e68900;
  color: #ffffff;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #f32c1e;
}
.badge-rose[href]:hover,
.badge-rose[href]:focus {
  background-color: #d81558;
}
.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
}
.alert b {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.alert,
.alert.alert-default {
  background-color: white;
  color: #555;
}
.alert a,
.alert .alert-link,
.alert.alert-default a,
.alert.alert-default .alert-link {
  color: #555;
}
.alert.alert-inverse {
  background-color: #2f2f2f;
  color: #fff;
}
.alert.alert-inverse a,
.alert.alert-inverse .alert-link {
  color: #fff;
}
.alert.alert-primary {
  background-color: #a72abd;
  color: #fff;
}
.alert.alert-primary a,
.alert.alert-primary .alert-link {
  color: #fff;
}
.alert.alert-success {
  background-color: #55b559;
  color: #fff;
}
.alert.alert-success a,
.alert.alert-success .alert-link {
  color: #fff;
}
.alert.alert-info {
  background-color: #00cae3;
  color: #fff;
}
.alert.alert-info a,
.alert.alert-info .alert-link {
  color: #fff;
}
.alert.alert-warning {
  background-color: #ff9e0f;
  color: #fff;
}
.alert.alert-warning a,
.alert.alert-warning .alert-link {
  color: #fff;
}
.alert.alert-danger {
  background-color: #f55145;
  color: #fff;
}
.alert.alert-danger a,
.alert.alert-danger .alert-link {
  color: #fff;
}
.alert.alert-rose {
  background-color: #ea2c6d;
  color: #fff;
}
.alert.alert-rose a,
.alert.alert-rose .alert-link {
  color: #fff;
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: #fff;
}
.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.87);
}
.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 1.071rem;
}
.alert .alert-icon i {
  margin-top: -7px;
  top: 5px;
  position: relative;
}
.alert .close {
  color: #ffffff;
  text-shadow: none;
  opacity: 0.9;
}
.alert .close i {
  font-size: 20px;
}
.alert .close:hover,
.alert .close:focus {
  opacity: 1;
}
.pagination > .page-item > .page-link,
.pagination > .page-item > span {
  border: 0;
  border-radius: 30px !important;
  transition: all 0.3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent;
  text-align: center;
}
.pagination > .page-item > .page-link:hover,
.pagination > .page-item > .page-link:focus,
.pagination > .page-item > span:hover,
.pagination > .page-item > span:focus {
  color: #999999;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > span {
  color: #999999;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > a:focus,
.pagination > .page-item.active > a:hover,
.pagination > .page-item.active > span,
.pagination > .page-item.active > span:focus,
.pagination > .page-item.active > span:hover {
  background-color: #ffd804;
  border-color: #ffd804;
  color: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
    0 1px 10px 0 rgba(156, 39, 176, 0.12),
    0 2px 4px -1px rgba(156, 39, 176, 0.2);
}
.pagination.pagination-info > .page-item.active > a,
.pagination.pagination-info > .page-item.active > a:focus,
.pagination.pagination-info > .page-item.active > a:hover,
.pagination.pagination-info > .page-item.active > span,
.pagination.pagination-info > .page-item.active > span:focus,
.pagination.pagination-info > .page-item.active > span:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14),
    0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}
.pagination.pagination-success > .page-item.active > a,
.pagination.pagination-success > .page-item.active > a:focus,
.pagination.pagination-success > .page-item.active > a:hover,
.pagination.pagination-success > .page-item.active > span,
.pagination.pagination-success > .page-item.active > span:focus,
.pagination.pagination-success > .page-item.active > span:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14),
    0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}
.pagination.pagination-warning > .page-item.active > a,
.pagination.pagination-warning > .page-item.active > a:focus,
.pagination.pagination-warning > .page-item.active > a:hover,
.pagination.pagination-warning > .page-item.active > span,
.pagination.pagination-warning > .page-item.active > span:focus,
.pagination.pagination-warning > .page-item.active > span:hover {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14),
    0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}
.pagination.pagination-danger > .page-item.active > a,
.pagination.pagination-danger > .page-item.active > a:focus,
.pagination.pagination-danger > .page-item.active > a:hover,
.pagination.pagination-danger > .page-item.active > span,
.pagination.pagination-danger > .page-item.active > span:focus,
.pagination.pagination-danger > .page-item.active > span:hover {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14),
    0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
  margin-left: 5px;
}
.nav-pills.flex-column .nav-item + .nav-item {
  margin-top: 5px;
}
.nav-pills .nav-item .nav-link {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #fff;
  transition: all 0.3s;
  border-radius: 30px;
  padding: 10px 20px;
}
.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills .nav-item .nav-link.active {
  color: #000;
  background-color: #efcb02;
  /* box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); */
}
.nav-pills .nav-item i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: #00bcd4;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
  background-color: #e91e63;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
  background-color: #4caf50;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
  background-color: #ff9800;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
  background-color: #f44336;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(244, 67, 54, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-icons .nav-item .nav-link {
  border-radius: 4px;
}
.tab-space {
  padding: 20px 0 50px 0px;
}
.info .icon {
  color: #999999;
}
.info .icon > i {
  font-size: 3.85rem;
}
.info .info-title {
  color: #000000;
  margin: 1.75rem 0 0.875rem;
}
.info p {
  color: #999999;
}
.info-horizontal .icon {
  float: left;
  margin-top: 24px;
  margin-right: 10px;
}
.info-horizontal .icon > i {
  font-size: 2.25rem;
}
.info-horizontal .description {
  overflow: hidden;
}
.icon.icon-primary {
  color: #ffd804;
}
.icon.icon-info {
  color: #00bcd4;
}
.icon.icon-success {
  color: #4caf50;
}
.icon.icon-warning {
  color: #ff9800;
}
.icon.icon-danger {
  color: #f44336;
}
.icon.icon-rose {
  color: #e91e63;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  line-height: 1.5em;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
.h1 {
  font-size: 40px;
  line-height: 1.15em;
}
h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3,
.h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
  margin-bottom: 15px;
}
h6,
.h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 16px;
  margin: 0 0 10px;
}
b {
  font-weight: 700;
}
small,
.small {
  font-size: 75%;
  color: #777;
}
.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-weight: 500;
}
.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #000000;
  text-decoration: none;
}
h2.title {
  margin-bottom: 1rem;
}
.description,
.card-description,
.footer-big p {
  color: #999;
}
.text-warning {
  color: #ff9800 !important;
}
.text-primary {
  color: #ffd804 !important;
}
.text-danger {
  color: #f44336 !important;
}
.text-success {
  color: #4caf50 !important;
}
.text-info {
  color: #00bcd4 !important;
}
.text-rose {
  color: #e91e63 !important;
}
.text-gray {
  color: #999999 !important;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
.blockquote p {
  font-size: 1.063rem;
  font-style: italic;
}
.blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
.nav-tabs {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-item .nav-link {
  color: #ffffff;
  border: 0;
  margin: 0;
  border-radius: 3px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
  background-color: transparent;
  transition: 0.3s background-color 0s;
}
.nav-tabs .nav-item .nav-link:hover {
  border: 0;
}
.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link:focus {
  border: 0 !important;
  color: #ffffff !important;
  font-weight: 500;
}
.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s background-color 0.2s;
}
.nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent;
}
.nav-tabs .nav-link {
  color: #555;
}
.nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.87);
  border-color: #ffd804;
}
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border-color: #ffd804;
}
.nav-tabs .nav-link.disabled {
  color: #999;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:hover,
.nav-tabs .nav-link.disabled:focus {
  color: #999;
}
.nav-tabs.header-primary .nav-link {
  color: white;
}
.nav-tabs.header-primary .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.active:hover,
.nav-tabs.header-primary .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.header-primary .nav-link.disabled,
.nav-tabs.header-primary .nav-link.disabled:hover,
.nav-tabs.header-primary .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link {
  color: white;
}
.nav-tabs.bg-inverse .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active:hover,
.nav-tabs.bg-inverse .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link.disabled,
.nav-tabs.bg-inverse .nav-link.disabled:hover,
.nav-tabs.bg-inverse .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.card-nav-tabs {
  margin-top: 45px;
}
.card-nav-tabs .card-header {
  margin-top: -30px !important;
}
.tooltip-arrow {
  display: none;
}
.tooltip.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.tooltip {
  opacity: 0;
  transition: opacity, transform 0.2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  font-size: 0.875rem;
}
.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ffffff;
}
.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ffffff;
}
.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ffffff;
}
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ffffff;
}
.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}
.popover,
.tooltip-inner {
  line-height: 1.5em;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color: #555;
}
.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.popover.left > .arrow,
.popover.right > .arrow,
.popover.top > .arrow,
.popover.bottom > .arrow {
  border: none;
}
.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  border: 0;
}
.popover-header {
  background-color: #ffffff;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.125rem;
  margin: 0;
  color: #555;
}
.popover-body {
  padding: 10px 15px 15px;
  line-height: 1.4;
  color: #555;
}
.modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
.modal-dialog .modal-content .card-signup {
  margin: 0;
}
.modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}
.modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-dialog .modal-footer {
  border-top: none;
  padding: 24px;
}
.modal-dialog .modal-footer.text-center {
  text-align: center;
}
.modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-dialog .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-dialog .modal-body + .modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.modal .modal-header .close {
  color: #999;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}
.modal .modal-header .close i {
  font-size: 16px;
}
.dropdown:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown.show > .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-menu {
  display: none;
  padding: 0.3125rem 0;
  border: 0;
  opacity: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu.showing {
  animation-name: bmd-dropdown-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.open > .dropdown-menu,
.dropdown-menu.show {
  display: block;
  opacity: 1;
  transform: scale(1);
}
.dropdown-menu.hiding {
  display: block;
  opacity: 0;
  transform: scale(0);
}

.dropdown-menu .disabled > a {
  color: #777;
}
.dropdown-menu .disabled > a:focus,
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}
.dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
  vertical-align: middle;
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 1px;
  margin-right: 12px;
  opacity: 0.5;
}
.dropdown-menu .dropdown-item,
.dropdown-menu li > a {
  position: relative;
  width: auto;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  color: #333;
  font-weight: normal;
  text-decoration: none;
  font-size: 0.8125rem;
  border-radius: 0.125rem;
  margin: 0 0.3125rem;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  min-width: 7rem;
  padding: 0.625rem 1.25rem;
  overflow: hidden;
  line-height: 1.428571;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
@media (min-width: 768px) {
  .dropdown-menu .dropdown-item,
  .dropdown-menu li > a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu a:active,
.dropdown-menu a.active,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #ffd804;
  color: #fff !important;
}
.dropdown-toggle.bmd-btn-icon::after,
.dropdown-toggle.bmd-btn-fab::after {
  display: none;
}
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-right,
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem;
}
.dropdown-toggle:after {
  will-change: transform;
  transition: transform 150ms linear;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown-toggle.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem;
}
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu {
  margin: 0;
}
.dropdown-header {
  font-size: 0.75rem;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-transform: none;
  color: #777;
  line-height: 1.428571;
  font-weight: inherit;
}
@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dropdown-menu.bootstrap-datetimepicker-widget {
  opacity: 0;
  transform: scale(0);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 0 0;
  will-change: transform, opacity;
  top: 0;
}
.dropdown-menu.bootstrap-datetimepicker-widget.top {
  transform-origin: 0 100%;
}
.dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity: 1;
  transform: scale(1);
  top: 0;
}
.bmd-layout-drawer {
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  overflow: visible;
  overflow-y: auto;
  font-size: 0.875rem;
  transition: transform;
  will-change: transform;
  transform-style: preserve-3d;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-drawer > * {
  flex-shrink: 0;
}
.bmd-layout-drawer > header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
}
.bmd-layout-drawer > header .navbar-brand {
  padding-left: 1.5rem;
  font-size: 1.25rem;
}
.bmd-layout-drawer > .list-group {
  padding-top: 1rem;
}
.bmd-layout-drawer > .list-group .list-group-item {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  font-size: 0.8125rem;
  font-weight: 500;
}
.bmd-drawer-f-l > .bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  transform: translateX(-250px);
}
.bmd-drawer-f-l > .bmd-layout-header,
.bmd-drawer-f-l > .bmd-layout-content {
  margin-left: 0;
}
.bmd-drawer-f-r > .bmd-layout-drawer {
  top: 0;
  right: 0;
  width: 240px;
  height: 100%;
  transform: translateX(250px);
}
.bmd-drawer-f-r > .bmd-layout-header,
.bmd-drawer-f-r > .bmd-layout-content {
  margin-right: 0;
}
.bmd-drawer-f-t > .bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(-110px);
}
.bmd-drawer-f-t > .bmd-layout-content {
  margin-top: 0;
}
.bmd-drawer-f-b > .bmd-layout-drawer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(110px);
}
.bmd-drawer-f-b > .bmd-layout-content {
  margin-bottom: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-header {
  width: calc(100% - 240px);
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-content {
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-header {
  width: calc(100% - 240px);
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-content {
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t > .bmd-layout-header {
  margin-top: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t > .bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b > .bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b > .bmd-layout-content {
  margin-bottom: 100px;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  > .bmd-layout-backdrop
  .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay.bmd-drawer-overlay
    > .bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-l
  > .bmd-layout-header,
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-l
  > .bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-r
  > .bmd-layout-header,
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-r
  > .bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  > .bmd-layout-backdrop
  .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay.bmd-drawer-overlay
    > .bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-t
  > .bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay.bmd-drawer-overlay
  .bmd-drawer-f-b
  > .bmd-layout-content {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-l
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-l
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-l
    > .bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-r
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-r
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-r
    > .bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-t
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-b
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-sm-up
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-sm-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-l
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-l
    > .bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-r
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-r
    > .bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-sm-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-sm-down
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-l
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-l
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-l
    > .bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-r
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-r
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-r
    > .bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-t
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-b
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-md-up
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-md-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-l
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-l
    > .bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-r
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-r
    > .bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-md-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-md-down
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-l
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-l
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-l
    > .bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-r
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-r
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-r
    > .bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-t
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-b
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-lg-up
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-lg-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-l
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-l
    > .bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-r
    > .bmd-layout-header,
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-r
    > .bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out)
      .bmd-drawer-overlay-lg-down
      > .bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-overlay-lg-down
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-l
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-l
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-l
    > .bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-r
    > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-r
    > .bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-r
    > .bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-t
    > .bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-t
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-b
    > .bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out)
    .bmd-drawer-in-xl-up
    .bmd-drawer-f-b
    > .bmd-layout-content {
    margin-bottom: 100px;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-l
  > .bmd-layout-header,
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-l
  > .bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-r
  > .bmd-layout-header,
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-r
  > .bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-t
  > .bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out)
  .bmd-drawer-overlay-xl-down
  .bmd-drawer-f-b
  > .bmd-layout-content {
  margin-bottom: 0;
}
.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #dddddd;
  margin-bottom: 20px;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar.progress-bar-primary {
  background: #ffd804 !important;
}
.progress .progress-bar.progress-bar-info {
  background: #00bcd4;
}
.progress .progress-bar.progress-bar-success {
  background: #4caf50;
}
.progress .progress-bar.progress-bar-warning {
  background: #ff9800;
}
.progress .progress-bar.progress-bar-danger {
  background: #f44336;
}
.progress.progress-line-primary {
  background: rgba(156, 39, 176, 0.2);
}
.progress.progress-line-info {
  background: rgba(0, 188, 212, 0.2);
}
.progress.progress-line-success {
  background: rgba(76, 175, 80, 0.2);
}
.progress.progress-line-warning {
  background: rgba(255, 152, 0, 0.2);
}
.progress.progress-line-danger {
  background: rgba(244, 67, 54, 0.2);
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton label,
.togglebutton input,
.togglebutton .toggle {
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:hover,
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
  margin-left: 5px;
}
.togglebutton label .toggle,
.togglebutton label input[type="checkbox"][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2.5px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type="checkbox"][disabled] + .toggle:after,
.togglebutton label input[type="checkbox"][disabled]:checked + .toggle:after {
  background-color: #bdbdbd;
}
.togglebutton label input[type="checkbox"] + .toggle:active:after,
.togglebutton label input[type="checkbox"][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  left: 15px;
}
.togglebutton label input[type="checkbox"]:checked + .toggle {
  background-color: rgba(156, 39, 176, 0.7);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  border-color: #ffd804;
}
.togglebutton label input[type="checkbox"]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(156, 39, 176, 0.1);
}
.ripple {
  position: relative;
}
.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;
}
.ripple-container .ripple-decorator {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  opacity: 0;
  transform: scale(1);
  transform-origin: 50%;
}
.ripple-container .ripple-decorator.ripple-on {
  opacity: 0.1;
  transition: opacity 0.15s ease-in 0s,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
.ripple-container .ripple-decorator.ripple-out {
  opacity: 0;
  transition: opacity 0.1s linear 0s !important;
}
.page-header {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}
.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.page-header .carousel .carousel-indicators {
  bottom: 60px;
}
.page-header > .container {
  color: #ffffff;
}
.page-header .iframe-container iframe {
  width: 100%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.header-filter {
  position: relative;
  overflow: hidden;
}
.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
}
.header-filter::after {
  content: "▽";
  animation: glow 0.59s infinite alternate;
  font-size: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: -webkit-linear-gradient(#2294f5, #c6e2fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes glow {
  to {
    text-shadow: 0 0 4px #fff, 0 0 8px #fff, 0 0 12px #2294f5, 0 0 16px #2294f5,
      0 0 20px #2294f5, 0 0 24px #2294f5, 0 0 28px #2294f5;
  }
}
.header-filter .container {
  z-index: 2;
  position: relative;
}
.clear-filter::before {
  background: none;
}
.purple-filter:after {
  background: rgba(101, 47, 142, 0.64);
  background: linear-gradient(
    45deg,
    rgba(101, 47, 142, 0.88) 0%,
    rgba(125, 46, 185, 0.45) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(101, 47, 142, 0.88) 0%,
    rgba(125, 46, 185, 0.45) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(101, 47, 142, 0.88) 0%,
    rgba(125, 46, 185, 0.45) 100%
  );
}
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card .card-category:not([class*="text-"]) {
  color: #999999;
}
.card .card-category {
  margin-top: 10px;
}
.card .card-category .material-icons {
  position: relative;
  top: 8px;
  line-height: 0;
}
.card .form-check {
  margin-top: 15px;
}
.card .card-title {
  margin-top: 0.625rem;
}
.card .card-title:last-child {
  margin-bottom: 0;
}
.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
  box-shadow: none !important;
}
.card .card-body,
.card .card-footer {
  padding: 0.9375rem 1.875rem;
}
.card .card-body + .card-footer {
  padding-top: 0rem;
  border: 0;
  border-radius: 6px;
}
.card .card-footer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
  display: inline-flex;
}
.card .card-footer .stats {
  color: #999999;
}
.card .card-footer .stats .material-icons {
  position: relative;
  top: 3px;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 18px;
}
.card.bmd-card-raised {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .card.bmd-card-flat {
    box-shadow: none;
  }
}
.card .card-header {
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border: 0;
}
.card .card-header .title {
  color: #ffffff;
}
.card .card-header:not([class*="header-"]) {
}
.card .card-header .nav-tabs {
  padding: 0;
}
.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}
.card .card-header.card-header-image a {
  display: block;
}
.card .card-header.card-header-image img {
  width: 100%;
  border-radius: 6px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #ffffff;
  font-size: 1.125rem;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}
.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}
.card .card-header.card-header-image.no-shadow {
  box-shadow: none;
}
.card .card-header.card-header-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image.no-shadow .colored-shadow {
  display: none !important;
}
.card .card-header-primary,
.card.bg-primary,
.card.card-rotate.bg-primary .front,
.card.card-rotate.bg-primary .back {
  background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}
.card .card-header-info,
.card.bg-info,
.card.card-rotate.bg-info .front,
.card.card-rotate.bg-info .back {
  background: linear-gradient(60deg, #26c6da, #0097a7);
}
.card .card-header-success,
.card.bg-success,
.card.card-rotate.bg-success .front,
.card.card-rotate.bg-success .back {
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}
.card .card-header-warning,
.card.bg-warning,
.card.card-rotate.bg-warning .front,
.card.card-rotate.bg-warning .back {
  background: linear-gradient(60deg, #ffa726, #f57c00);
}
.card .card-header-danger,
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
  background: linear-gradient(60deg, #ef5350, #d32f2f);
}
.card .card-header-rose,
.card.bg-rose,
.card.card-rotate.bg-rose .front,
.card.card-rotate.bg-rose .back {
  background: linear-gradient(60deg, #ec407a, #c2185b);
}
.card .card-header-primary {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.card .card-header-danger {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(244, 67, 54, 0.6);
}
.card .card-header-rose {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
}
.card .card-header-warning {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
}
.card .card-header-info {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
}
.card .card-header-success {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}
.card [class*="header-"],
.card[class*="bg-"] {
  color: #ffffff;
}
.card [class*="header-"] .card-title a,
.card [class*="header-"] .card-title,
.card [class*="header-"] .icon i,
.card[class*="bg-"] .card-title a,
.card[class*="bg-"] .card-title,
.card[class*="bg-"] .icon i {
  color: #ffffff;
}
.card [class*="header-"] .icon i,
.card[class*="bg-"] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}
.card [class*="header-"] .author a,
.card [class*="header-"] .stats,
.card [class*="header-"] .card-category,
.card [class*="header-"] .card-description,
.card[class*="bg-"] .author a,
.card[class*="bg-"] .stats,
.card[class*="bg-"] .card-category,
.card[class*="bg-"] .card-description {
  color: rgba(255, 255, 255, 0.8);
}
.card [class*="header-"] .author a:hover,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:active,
.card[class*="bg-"] .author a:hover,
.card[class*="bg-"] .author a:focus,
.card[class*="bg-"] .author a:active {
  color: #ffffff;
}
.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .author a {
  color: #000000;
  text-decoration: none;
}
.card .author a .ripple-container {
  display: none;
}
.card .card-category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}
.card .card-category-social .material-icons {
  position: relative;
  top: 5px;
}
.card[class*="bg-"],
.card[class*="bg-"] .card-body {
  border-radius: 6px;
}
.card[class*="bg-"] h1 small,
.card[class*="bg-"] h2 small,
.card[class*="bg-"] h3 small,
.card[class*="bg-"] .card-body h1 small,
.card[class*="bg-"] .card-body h2 small,
.card[class*="bg-"] .card-body h3 small {
  color: rgba(255, 255, 255, 0.8);
}
.card .card-stats {
  background: transparent;
  display: flex;
}
.card .card-stats .author,
.card .card-stats .stats {
  display: inline-flex;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card {
    display: inline-block;
  }
}
.card-carousel {
  overflow: hidden;
}
.card-login .card-header {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0;
  margin-bottom: 15px;
}
.card-login .text-divider {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}
.card-login .card-body {
  padding: 0px 30px 0px 10px;
}
.card-login .form-check {
  padding-top: 27px;
}
.card-login .form-check label {
  margin-left: 18px;
}
.card-login .form-check .form-check-sign {
  padding-right: 27px;
}
.card-login .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.card-login .form {
  min-height: 420px;
}
.card-login .social-line {
  margin-top: 1rem;
  text-align: center;
  padding: 0;
}
.card-login .social-line .btn {
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}
.login-page .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 2;
}
.login-page .footer .copyright a {
  color: #ffffff;
}
.card-plain {
  background: transparent;
  box-shadow: none;
}
.card-plain .card-header:not(.card-avatar) {
  margin-left: 0;
  margin-right: 0;
}
.card-plain .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card-plain .card-header-image {
  margin: 0 !important;
  border-radius: 6px;
}
.card-plain .card-header-image img {
  border-radius: 6px;
}
.card-plain .card-footer {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
}
/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget a:hover {
  box-shadow: none !important;
}
.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 991px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  right: auto;
  left: 12px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
  color: #ffd804;
  box-shadow: none;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action]:hover span {
  background-color: #eee;
  color: #ffd804;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.125rem;
  margin: 0;
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  height: 38px;
  margin-right: 3px;
  padding: 0;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table.table-condensed tr > td {
  text-align: center;
}
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div {
  text-align: center;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 500;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev span,
.bootstrap-datetimepicker-widget table th.next span {
  border-radius: 3px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span,
.bootstrap-datetimepicker-widget
  table
  thead
  tr:first-child
  th.picker-switch:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td > div {
  border-radius: 3px;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td.cw > div {
  font-size: 0.8em;
  height: 20px;
  line-height: 20px;
  color: #999;
}
.bootstrap-datetimepicker-widget table td.day > div {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  position: relative;
  z-index: -1;
  color: #000000;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget table td.minute > div,
.bootstrap-datetimepicker-widget table td.hour > div {
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget table td.day:hover > div,
.bootstrap-datetimepicker-widget table td.hour:hover > div,
.bootstrap-datetimepicker-widget table td.minute:hover > div,
.bootstrap-datetimepicker-widget table td.second:hover > div {
  background: #eee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old > div,
.bootstrap-datetimepicker-widget table td.new > div {
  color: #999;
}
.bootstrap-datetimepicker-widget table td.today > div {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.active > div,
.bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: #ffd804;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
}
.bootstrap-datetimepicker-widget table td.active.today:before > div {
  border-bottom-color: #ffffff;
}
.bootstrap-datetimepicker-widget table td.disabled > div,
.bootstrap-datetimepicker-widget table td.disabled:hover > div {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #ffd804;
  color: #ffffff;
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #999;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
/*! nouislider - 14.1.1  */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 10%;
  width: 10%;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: 100%;
  top: -7px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #ffd804;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
}
.noUi-vertical .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -8px;
  top: -10px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #ffd804;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  right: -10px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #c8c8c8;
  border-radius: 4px;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3fb8af;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
  transform: scale3d(1.5, 1.5, 1);
}
/* Handle stripes;
 */
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.slider {
  background: #c8c8c8;
}
.slider .noUi-connect {
  background-color: #ffd804;
  border-radius: 4px;
}
.slider .noUi-handle {
  border-color: #ffd804;
}
.slider.slider-info .noUi-connect {
  background-color: #00bcd4;
}
.slider.slider-info .noUi-handle {
  border-color: #00bcd4;
}
.slider.slider-success .noUi-connect {
  background-color: #4caf50;
}
.slider.slider-success .noUi-handle {
  border-color: #4caf50;
}
.slider.slider-warning .noUi-connect {
  background-color: #ff9800;
}
.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}
.slider.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider.slider-rose .noUi-connect {
  background-color: #e91e63;
}
.slider.slider-rose .noUi-handle {
  border-color: #e91e63;
}
.index-page .section-components h6.description {
  margin-top: 5px;
  margin-bottom: 30px;
}
.index-page .page-header {
  /* height: 90vh; */
  overflow: hidden;
}
.index-page .brand {
  color: #ffffff;
  text-align: center;
}
.index-page .brand h1 {
  font-size: 4.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.index-page .brand h3 {
  font-size: 1.313rem;
  max-width: 500px;
  margin: 10px auto 0;
}
.index-page h4.description {
  font-size: 1.5em;
}
.section {
  padding: 65px 0;
}
.profile-page .page-header {
  height: 380px;
  background-position: top center;
}
.profile-page .profile {
  text-align: center;
}
.profile-page .profile img {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.profile-page .profile .name {
  margin-top: -80px;
}
.profile-page .follow {
  position: absolute;
  top: 0;
  right: 0;
}
.profile-page .description {
  margin: 1.071rem auto 0;
  max-width: 600px;
}
.profile-page .profile-tabs {
  margin-top: 4.284rem;
}
.profile-page .gallery {
  margin-top: 3.213rem;
  padding-bottom: 50px;
}
.profile-page .gallery img {
  width: 100%;
  margin-bottom: 2.142rem;
}
.profile-page .follow .btn-fab {
  margin-top: -28px;
}
.profile-page .card-background .content {
  padding: 30% 0;
  min-width: 160px;
}
.profile-page .work {
  padding: 40px 0px;
}
.profile-page .work .collections {
  margin-top: 20px;
}
.profile-page .work .stats ul > li {
  padding: 5px 0px;
  font-size: 1em;
}
.profile-page .work .stats ul > li b {
  font-size: 1.2em;
}
.profile-page .connections {
  padding: 40px 0px;
}
.profile-page .connections .card-profile {
  text-align: left;
}
.profile-page .tab-content .collections .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-page .tab-content .collections .card .card-body .badge {
  display: inline-table;
  margin: 0 auto;
}
.signup-page .page-header {
  min-height: 100vh;
  height: auto;
  display: inherit;
}
.signup-page .page-header .container {
  padding-top: 20vh;
}
.signup-page .card-signup {
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 100px;
  padding: 40px 0px;
}
.signup-page .info-horizontal {
  padding: 0px 0px 20px;
}
.signup-page .social .btn {
  margin: 5px;
}
.signup-page .social h4 {
  margin-top: 20px;
}
.signup-page .footer .container {
  padding: 0;
}
.signup-page .footer .copyright,
.signup-page .footer a {
  color: #fff;
}
@media all and (max-width: 991px) {
  [class*="navbar-expand-"] > .container,
  [class*="navbar-expand-"] > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show {
    right: 101% !important;
  }
  .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu,
  .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show {
    left: -165px !important;
  }
  .navbar .navbar-collapse .navbar-nav > li.button-container {
    padding: 15px;
  }
  .carousel .card .card-body {
    max-width: 340px;
    margin: 0 auto;
    min-height: 400px;
  }
  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
    max-height: none !important;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar-collapse .dropdown-toggle:after {
    position: absolute;
    right: 16px;
    margin-top: 8px;
  }
  .navbar-collapse .navbar-nav {
    position: relative;
    z-index: 3;
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #000000;
    margin: 5px 15px;
  }
  .navbar-collapse .navbar-nav .nav-item.active .nav-link,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:hover,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:focus,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:active {
    background-color: #ecedef;
    color: #000000;
  }
  .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
    margin: 15px;
  }
  .navbar-collapse .navbar-nav .nav-item:after {
    width: calc(100% - 30px);
    content: "";
    display: block;
    height: 1px;
    margin-left: 15px;
    background-color: #e5e5e5;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child:after {
    display: none;
  }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .navbar .dropdown-menu .dropdown-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar .dropdown.show .dropdown-menu,
  .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    padding-bottom: 15px;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transform: none !important;
    width: auto;
    margin-bottom: 15px;
    padding-top: 0;
    height: 300px;
    animation: none;
    opacity: 1;
    overflow-y: scroll;
  }
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: #ffffff;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden;
  }
  #navbar .navbar-collapse,
  #navigation .navbar-collapse {
    display: none !important;
  }
}
@media all and (min-width: 991px) {
  .navbar .navbar-nav {
    align-items: center;
  }
  .navbar .navbar-nav .button-container {
    margin-left: 0.1875px;
  }
  .contactus-2 .card-contact {
    margin: 80px 0 80px 150px;
  }
  .index-page .fileinput-new.thumbnail.img-circle {
    margin-top: 50px;
  }
}
@media screen and (max-width: 991px) {
  .presentation-page .section-components .components-macbook {
    max-width: 850px !important;
    max-height: 480px !important;
    margin-top: 12vh;
    left: -12px;
  }
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: 47%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 12%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
    left: 65%;
  }
  .presentation-page .section-content .area-img {
    max-width: 130px;
    max-height: 170px;
  }
  .presentation-page .section-content .info-img {
    max-width: 170px;
    max-height: 120px;
  }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    45% {
      top: 6px;
      transform: rotate(145deg);
    }
    75% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg);
    }
    75% {
      -webkit-transform: rotate(130deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
    }
  }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg);
    }
    75% {
      -moz-transform: rotate(130deg);
    }
    100% {
      -moz-transform: rotate(135deg);
    }
  }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      top: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg);
    }
    45% {
      -webkit-transform: rotate(-10deg);
    }
    75% {
      -webkit-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg);
    }
    45% {
      -moz-transform: rotate(-10deg);
    }
    75% {
      -moz-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -moz-transform: rotate(0);
    }
  }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      transform: rotate(-145deg);
    }
    75% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-135deg);
    }
  }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg);
    }
    75% {
      -webkit-transform: rotate(-130deg);
    }
    100% {
      -webkit-transform: rotate(-135deg);
    }
  }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg);
    }
    75% {
      -moz-transform: rotate(-130deg);
    }
    100% {
      -moz-transform: rotate(-135deg);
    }
  }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg);
    }
    45% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg);
    }
    45% {
      -webkit-transform: rotate(10deg);
    }
    75% {
      -webkit-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg);
    }
    45% {
      -moz-transform: rotate(10deg);
    }
    75% {
      -moz-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0);
    }
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(2) {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(3) {
    opacity: 1;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(4) {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(2) {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(3) {
    opacity: 0;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(4) {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
@media screen and (max-width: 767px) {
  .presentation-page .section-components .components-macbook {
    max-width: 350px !important;
    max-height: 250px !important;
    margin-top: 12vh;
    left: -12px;
  }
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: -7%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 7%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
  }
  .card.card-form-horizontal .input-group {
    padding-bottom: 10px;
  }
  .presentation-page #cd-vertical-nav,
  .index-page #cd-vertical-nav,
  .section-page #cd-vertical-nav {
    display: none;
  }
  .index-page .cd-section .tim-typo .tim-note {
    width: 60px;
  }
  .login-page .page-header > .container {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 400px) {
  .cd-vertical-nav {
    display: none !important;
  }
}
@media screen and (min-width: 767px) {
  .bd-docs .bd-example .card.card-profile .card-header-image {
    max-height: 100% !important;
  }
  .card-profile .card-header-image {
    max-height: 215px;
  }
}
@media screen and (max-width: 991px) {
  .menu-on-left .page-header,
  .menu-on-left .main,
  .menu-on-left .navbar > div {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .menu-on-left .navbar .collapse {
    left: 0;
    top: -25px;
    right: auto;
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .menu-on-left .navbar .navbar-translate .navbar-brand {
    position: absolute;
    right: 0;
    top: -15px;
  }
  .nav-open .menu-on-left .page-header,
  .nav-open .menu-on-left .main {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
  }
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .menu-on-left .navbar > div .navbar-translate {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
@media (min-width: 575px) and (max-width: 991px) {
  .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-255px, 0, 0);
    -moz-transform: translate3d(-255px, 0, 0);
    -o-transform: translate3d(-255px, 0, 0);
    -ms-transform: translate3d(-255px, 0, 0);
    transform: translate3d(-255px, 0, 0);
  }
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform: translate3d(-25px, 0, 0);
    -o-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    width: 245px;
  }
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #ffd806;
}

/* Mobile Navigation */
.mobile_nav {
  background-color: #090909 !important;
}
.home_page_menu li::after {
  display: none;
}
button.hamburger_btn {
  color: #ffd808;
}

/* Custom Styles */
/* Home Page */
a,
button,
ul,
li {
  font-family: Arial, sans-serif;
}
.page_wrap header {
  height: 80px;
  border-bottom: 1px solid #1e1e1e;
  background-color: #090909 !important;
  padding: 10px 0;
}
header {
  border-bottom: 1px solid #1e1e1e;
}
.menu_main_navbar li a {
  padding: 0 18px;
  font-weight: 700;
  font-size: 16px;
  color: #cbcbcb;
  text-transform: capitalize;
}
.menu_main_navbar a:hover {
  color: #ffd700;
  text-decoration: none;
}
.home_page_menu ul {
  display: flex;
  align-items: center;
}
.menu_main_navbar li a.home_menu_btn {
  background: #ffd700;
  border: 2px solid #ffd700;
  color: #090909;
  font-weight: 700;
  line-height: 30px;
  font-size: 14px;
  padding: 1px 20px;
  border-radius: 5px;
  transition: 0.2s all;
  margin: 0 10px;
  text-transform: uppercase;
}
.menu_main_navbar li a.home_menu_btn:hover {
  background: transparent;
  border: 2px solid #ffd700;
  color: #ffd700;
}
.banner_container {
  margin-top: 80px;
}
.banner_container h1 {
  font-size: 35px;
  font-weight: 600;
}
.banner_container h1 span {
  color: #fd802b;
}
.banner_container h2 {
  font-size: 35px;
  font-weight: 600;
}
.banner_container button.primary_btn {
  font-size: 24px;
  padding: 8px 35px !important;
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  border-radius: 19px;
  font-size: 19px;
  /* line-height: 26px; */
}
button.primary_btn,
a.primary_btn {
  padding: 5px 25px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background: #ffd703;
  border: 1px solid #ffd703;
  color: #000 !important;
  border-radius: 5px;
}
a.primary_btn span {
  color: #000 !important;
}
button.primary_btn:hover {
  background: transparent;
  border: 1px solid #ffd808;
  color: #ffd808;
}
button.primary_btn span {
  color: #000;
}
button.primary_btn:hover span {
  color: #ffd808;
}
.heading-title {
  font-size: 36px;
  font-weight: 600;
  color: #ffd808;
  position: relative;
}
.heading-title::before {
  content: "";
  width: 60px;
  height: 3px;
  background: #ffd808;
  position: absolute;
  bottom: -8px;
  left: 47%;
}
.heading-subtitle {
  font-size: 24px;
  font-weight: 600;
}
.heading-text {
  font-size: 18px;
}
.common-table {
  width: 100%;
  border: 1px solid #e2e2e2;
  padding: 5px;
  border-radius: 10px;
}
.common-table .btn {
  width: 90px;
  height: 38px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  background: #ffd808;
  border-radius: 5px;
}
.common-table th {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 30px;
  text-align: left;
}
.text-green {
  color: #67a210;
}
.text-red {
  color: #fe4646;
}
.common-table td {
  font-size: 18px;
  font-weight: 600;
  padding: 15px 30px;
  text-align: left;
}
.common-table thead,
.common-table tr {
  border-bottom: 1px solid #e2e2e2;
}
.common-table span {
  color: #9d9d9d;
}
.table_view {
  color: #ffd808;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.coin_logo {
  max-width: 35px;
}
.home_calculator h4 {
  font-size: 30px;
  color: #fd802b;
  font-weight: bold;
}
.home_calculator h5 {
  font-size: 20px;
  font-weight: bold;
}
.home_calculator p {
  font-size: 16px;
  font-weight: 500;
}
.home_calculator p img {
  vertical-align: top;
  padding-left: 10px;
  margin-top: -4px;
}
.calculate_currency {
  background: #ffd808;
  color: #fff;
  border-radius: 5px;
  transform: scale(1.07);
}
.home_calculator .calculate_currency h5 {
  font-size: 30px;
  font-weight: bold;
  margin-top: -47px;
  margin-bottom: 30px;
}
.calculate_currency h1 {
  font-size: 94px;
  font-weight: bold;
  color: #495fc5;
  margin-bottom: 0;
}
.calculate_currency .form-input {
  border: 1px solid #a462cc;
  padding: 0 15px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}
.calculate_currency .form-input label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
.calculate_currency .form-input input {
  font-size: 28px;
  font-weight: bold;
  background: transparent;
  border: none;
  color: #ffffff;
  text-align: right;
  width: 60%;
  outline: none;
  box-shadow: none;
}
.calculate_currency .form-input input::placeholder {
  color: #ffffff;
}
.calculate_currency .MuiButton-root {
  color: #ffffff;
  padding: 0;
  position: static;
  width: 100%;
  justify-content: flex-start;
}
.calculate_currency .MuiButton-root::after {
  border-top: 5px solid #a462cc;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
  content: " ";
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 12px;
  position: absolute;
  right: 15px;
}
.calculate_currency .MuiButton-root::before {
  border-top: 5px solid #ffd808;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
  content: " ";
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 12px;
  position: absolute;
  right: 15px;
}
.calculate_currency button img {
  max-width: 20px;
}
.calculate_currency span {
  font-size: 15px;
  font-weight: 500;
}
.btn.btn-default:hover {
  background: #ffffff;
  border-color: #ffd808;
  color: #ffd808;
}
.btn.btn-secondary {
	font-size: 15px;
	padding: 7px 3px;
  background: transparent;
  color: #ffd808;
  border: 1px solid #ffd808;
  text-transform: capitalize;
}
.btn.btn-secondary:hover {
  background: transparent;
  border-color: #ffd808;
  color: #ffd808;
  box-shadow: none
  ;
}
.feature_list h5 {
  color: #fd802b;
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
}
.feature_list a {
  font-size: 15px;
  color: #ffd808;
}
.bg-gray {
  background: #f6f8fe;
}
.start_trading .content-container p {
  font-size: 16px;
}
.start_trading span {
  width: 100%;
  height: 1px;
  background: #c3c3c3;
  display: inline-block;
  margin-top: 40px;
}

footer {
  background: #111217;
  color: #ffffff;
}
footer li {
  margin-bottom: 5px;
}
footer h5 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}
footer a {
  font-size: 15px;
  font-weight: 500;
  color: #8f8f8d;
}
footer a:hover {
  color: #fd802b;
  text-decoration: none;
}
.footer_bottom {
  border-top: 1px solid #43454c;
}
.footer_bottom label {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.social_media a:hover {
  color: #039be4;
}
.footer_bottom p {
  color: #8f8f8d;
  margin-bottom: 0;
  font-size: 16px;
  text-align: left;
}
.footer_menu button {
  width: 100%;
  background: #ffffff !important;
  justify-content: flex-start;
  padding: 3px 15px;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 22px;
}
.footer_bottom span {
  margin: 0px 7px;
  color: #ffd700;
}
.footer_link_ww a:hover {
  color: #ffd700;
  text-decoration: none;
}
.footer_link_ww i {
  color: #8f8f8d;
  margin: 0 5px;
}
.flex_footer_1 a:hover {
  color: #ffd700;
}

/* login */
.login_container {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url(../src/assets/images/login.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 20px 0;
  margin-top: 80px;
}
.login_container h2 {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 0;
}
.login_container h6 {
  color: #bcbcbc;
  font-size: 14px;
  text-transform: unset;
  border-bottom: 2px solid #2f2f2f;
  padding-bottom: 25px;
}
.login_container form {
  border: 5px solid #2f2f2f;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 70%);
  border-radius: 15px;
  background: rgb(17 17 17 / 70%);
}
.logo_bg_shadow {
  background: url("../src/assets/images/logo_shadow_bg.webp");
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login_container .form-control {
  color: #929292;
  font-size: 16px;
  border: 2px solid #2f2f2f;
  height: 60px;
  padding: 0 20px;
  border-radius: 15px;
  background-image: none;
}
.login_container .react-tel-input .form-control {
  border: 2px solid #2f2f2f;
  padding: 0 20px;
  height: 60px;
  font-size: 16px;
  border-radius: 15px !important;
  background: none;
}
.login_container .react-tel-input .flag-dropdown { 
  background-color: #0f0f0f;
  border: 2px solid #2f2f2f;
  border-radius: 15px 0 0 15px;
}
.login_container .react-tel-input .selected-flag:hover, 
.login_container .react-tel-input .selected-flag:focus {
  background-color: #0f0f0f !important;
}
.login_container .react-tel-input .selected-flag {
  border-radius: 15px 0 0 15px;
}
.login_container .react-tel-input .flag-dropdown.open .selected-flag,
.login_container .react-tel-input .flag-dropdown.open {
  background: #0f0f0f;
  border-radius: 15px 0 0 15px;
}
.login_container .react-tel-input .country-list {
  background-color: #090909;
}
.login_container .react-tel-input .country-list .country:hover,
.login_container .react-tel-input .country-list .country.highlight {
  background-color: #1a1a1a;
}
.dashboard_box .contact_form .react-tel-input .form-control {
  background: #090909;
  border: 2px solid #2f2f2f !important;
  padding-left: 10px;
  padding-right: 10px;
  color: #929292;
  height: auto;
}
.dashboard_box .contact_form .react-tel-input .flag-dropdown { 
  background-color: #0f0f0f;
  border: 2px solid #2f2f2f;
}
.dashboard_box .contact_form .react-tel-input .selected-flag:hover, 
.dashboard_box .contact_form .react-tel-input .selected-flag:focus {
  background-color: #0f0f0f !important;
}
.dashboard_box .contact_form .react-tel-input .flag-dropdown.open .selected-flag,
.dashboard_box .contact_form .react-tel-input .flag-dropdown.open {
  background: #0f0f0f;
  z-index: 10;
}
.dashboard_box .contact_form .react-tel-input .country-list {
  background-color: #090909;
}
.dashboard_box .contact_form .react-tel-input .country-list .country:hover,
.dashboard_box .contact_form .react-tel-input .country-list .country.highlight {
  background-color: #1a1a1a;
}



.login_container p.error-message {
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 5px;
}
.form-check label {
  font-size: 16px;
  color: #929292;
}
.login_container label {
  font-size: 16px;
  color: #bcbcbc;
}
.login_container button {
  width: 100%;
  height: 60px;
  background: #ffd701;
  border: 2px solid #ffd701;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.login_container button:hover {
  background: transparent;
  border: 2px solid #ffd701;
  color: #ffd701;
}
.login_container a {
  font-size: 15px;
  font-weight: 500;
  color: #ffd701;
  text-decoration: underline;
}
.login_container p {
  font-size: 15px;
}
.login_container p a {
  font-weight: normal;
}
.static_container h5 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
}
.static_container p {
  font-size: 16px;
  color: #787878;
}
.contact_form label {
  margin-bottom: 10px;
  color: #cbcbcb;
}
.custom-file .custom-file-input {
  z-index: 9 !important;
}
.contact_form label span {
  color: #787878;
}
.contact_form .form-control {
  background: #090909;
  border: 2px solid #2f2f2f !important;
  border-radius: 5px !important;
  padding-left: 10px;
  padding-right: 10px;
  color: #929292;
}
select.custom-select.form-control {
  height: 40px !important;
  background: #090909 url("../src/assets/images/dropdown_arrow.webp") no-repeat
    right 0.75rem center;
}
.contact_form input {
  height: 40px;
}
.contact_form textarea {
  height: 100px;
}
.btn.btn-primary {
  background: #ffd808;
  color: #090909;
  text-align: center;
  font-weight: 600;
}
.contact-list span {
  color: #787878;
}
.contact-list p {
  color: #000;
  margin-bottom: 0;
  min-height: 24px;
}

.profile_dash {
  padding: 0;
  text-align: right;
}
#profile_menu.MuiPaper-root.MuiMenu-paper,
#notification_dropdown.MuiPaper-root.MuiMenu-paper {
  margin-top: 48px;
  box-shadow: none;
  border: 1px solid #e9e9e9;
}
.dashboard_container {
  background: #090909;
  min-height: 100vh;
}
.dash_title {
  font-size: 24px;
  font-weight: 600;
  color: #ffd808;
  margin-top: 0;
}
.profile_dash span {
  color: #787878;
}
.profile_dash li {
  list-style: none;
  color: #cbcbcb;
}
.dashboard_box {
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  background: #090909;
  padding: 20px 25px;
  margin-bottom: 30px;
}
.contact_form .form-control.disabled {
  background: #e6e6e6;
  color: #000;
}
.dash_subtitle {
  font-size: 18px;
  font-weight: 600;
  color: #ffd808;
  margin: 0 0 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #2f2f2f;
}
.dash_table th {
  color: #787878;
  font-weight: normal;
  border: none !important;
}
.dash_table th,
.dash_table td {
  padding: 5px 10px;
  white-space: nowrap;
  color: #c0c0c0;
}
.dash_table td {
  border-bottom: 1px solid #2f2f2f;
  border-top: none;
}
.dash_table td i {
  color: #adadce;
}
.dash_table tr:last-child td {
  border: none;
}
.dash_table a {
  color: #c0c0c0;
}
.dash_table a:hover {
  color: #ffd808;
  text-decoration: underline;
}
.contact_form .dash_table input {
  height: auto;
}
.contact_form .dash_table .form-check {
  text-align: center;
}
.dashRightLinks {
  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.btn.btn-outline {
  padding: 4px 24px;
  border: 1px solid #ffd808;
  background: #ffc809 !important;
  color: #090909;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: none !important;
}
button.btn.btn-outline:hover,
button.btn.btn-outline:focus,
button.btn.btn-outline:active {
  border: 1px solid #ffc809;
  color: #090909;
  background: transparent;
}
.dashboard_login li {
  list-style: none;
  height: 100%;
  border-left: 1px solid #1d1919;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}
.dashboard_login li:first-child {
  border-left: 0;
}
.dashboard_login a {
  color: #cbcbcb;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
}
.dashboard_login a:hover {
  color: #ffd808;
}
.dashboard_login button {
  color: #787878;
  font-size: 18px;
}
.dashboard_login button:hover {
  background: transparent;
}
#profile_menu .MuiPopover-paper {
  top: 65px !important;
}
#profile_menu .MuiPopover-paper ul li {
  padding-top: 0;
  padding-bottom: 0;
}
#profile_menu .MuiPopover-paper ul li a {
  color: #787878;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
}
#profile_menu .MuiPopover-paper ul li a:hover {
  color: #ffd808;
}
.notify_count {
  background: #ffd808;
  color: #000;
}
.notify_count {
  background: #ffd808;
  color: #000;
  width: auto;
  height: 17px;
  display: inline-block;
  border-radius: 17px;
  line-height: 13px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  right: 20px;
  margin-top: -20px;
  border: 2px solid #ffd808;
  z-index: 9;
  /* max-width: 17px; */
  min-width: 17px;
}
/* .dashboard_login .fa-user {
  color: #cbcbcb;
} */

/* Dashboard Pages */
.balance_details_left h3 {
  font-size: 16px;
  color: #787878;
  margin: 0 0 3px;
}
.balance_details_left h4 {
  font-size: 20px;
  color: #c5c5c5;
  margin-top: 0;
  font-weight: 600;
}
.balance_details_left h2 {
  font-size: 26px;
  color: #cbcbcb;
  margin-top: 0;
}
.balance_details_left h2 span {
  font-size: 20px;
  color: #cbcbcb;
}
.balance_details_panel {
  display: flex;
}
.balance_details_panel div.balance_details_left {
  width: 100%;
}
.balance_details_panel div.balance_details_right {
  width: 60%;
  display: none;

}
.p2p_button_flex{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
  padding: 8px 0px;
}
.chartDash {
  width: 230px;
  display: inline-flex;
  margin-right: 25px;
  max-height: 180px;
  overflow: hidden;
  align-items: center;
}
.chartDash ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.chartLabel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.chartLabel {
  width: calc(100% - 195px);
}
.chartLabel ul li {
  display: flex;
  margin-bottom: 10px;
  font-weight: 400 !important;
}
.chartLabel ul li label {
  margin: 0;
  width: 50%;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #9b9b9b;
}
.chartLabel ul li label i {
  font-size: 8px;
  margin-right: 6px;
}
.chartLabel ul li span {
  margin: 0;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  color: #9b9b9b;
}
.squareOrange {
  color: #f68819;
}
.squareBlue {
  color: #6076ff;
}
.squareYellow {
  color: #f0b90b;
}
.squareGreen {
  color: #82bb47;
}
.squareGrey {
  color: #eaecef;
}
.tabelDashBalance {
  margin: 25px 0 0 0;
}
.tabelDashBalance td:first-child {
  color: #787878;
}
.tabelDashBalance td {
  padding: 5px 15px;
  color: #c5c5c5;
  white-space: nowrap;
}
.tabelDashBalance td span {
  margin-right: 10px;
}
.tabelDashBalance td span a {
  color: #f0d808;
}
.tabelDashBalance td span a:hover {
  color: #ffd808;
  text-decoration: underline;
}
.nav-tabs.primaryNav {
	border-bottom: 1px solid #2f2f2f;
	border-radius: 0px;
	padding: 0;
}    
.nav-tabs.primaryNav .nav-link {
	padding: 10px 15px;
	margin-right: 10px;
	color: #c0c0c0;
	text-transform: capitalize;
	border: 0px solid #ffd808;
	font-size: 16px;
}
.nav-tabs.primaryNav .nav-link.active,
.nav-tabs.primaryNav .nav-link:hover {
  border-bottom: 0px solid #ffd808;
  color: #000000;
  background: #ffd808;
}
.dashHistoryTable tr th {
  font-weight: 600;
  color: #c5c5c5;
  padding: 6px 15px;
  border-bottom: 0;
  white-space: nowrap;
}
.dashHistoryTable tr td {
  border-top: 0;
  padding: 5px 15px;
  vertical-align: middle;
  color: #c5c5c5;
  white-space: nowrap;
}
.peer2peer_section .table-responsive {
  min-height: 200px;
}
.table.historyCryptoTable thead th {
  color: #fff;
}
.table.historyCryptoTable th,
.table.historyCryptoTable td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  color: #999;
}

.dashHistoryTable tr td a {
  color: #ffd808;
}
.dashHistoryTable tr td a:hover {
  text-decoration: underline;
}
.dashSecurityTable .table {
  margin-bottom: 0;
}
.dashSecurityTable tr:first-child td {
  border-top: 0;
}
.dashSecurityTable tr td:first-child {
  color: #c5c5c5;
  font-weight: 600;
}
.dashSecurityTable tr td:first-child i {
  color: #adadce;
}
.dashSecurityTable tr td {
  padding: 5px 15px;
  color: #5a5a5a;
  white-space: nowrap;
}
.dashSecurityTable tr td:last-child {
  font-weight: 600;
}
.disabledRed {
  color: #e80047;
}
.enableGreen {
  color: #4bae50;
}
.dashSecurityTable tr td a {
  color: #ffd808;
}
.dashSecurityTable tr td a:hover {
  text-decoration: underline;
}

.textDepositGreen {
  color: #02c076 !important;
}
.textWithdrawRed {
  color: #a81e3a !important;
}
.textStatusGreen {
  color: #02c076 !important;
}
.textStatusOrange {
  color: #ffd806 !important;
}
.dashAlertNotification ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dashAlertNotification ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #111;
  padding-top: 7px;
  padding-bottom: 7px;
}
.dashAlertNotification ul li label {
  margin-bottom: 0;
  color: #c5c5c5;
  font-weight: 500;
}
.dashUserDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.dudLeft {
  width: 50px;
  margin-right: 10px;
  position: relative;
}
.dudLeft a {
  font-size: 9px;
  color: #ffffff;
  background: #ffd808;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}
.dudRight {
  width: calc(100% - 60px);
}
.dudRight ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dudRight ul li {
  margin-bottom: 18px;
}
.dudRight ul li label {
  margin: 0;
  font-size: 14px;
  color: #c5c5c5;
  font-weight: 600;
  white-space: nowrap;
  width: 50%;
}
.dudRight ul li label span {
  font-size: 14px;
  margin-left: 7px;
  font-weight: 400;
  white-space: nowrap;
}
.dudRight ul li label a {
  color: #ffd808;
}
.dudRight ul li label a:hover {
  text-decoration: underline;
}
.dudRight ul li p {
  margin: 0;
  font-size: 14px;
  color: #000000;
}
.dudRight ul li p a i {
  color: #adadce;
  font-size: 16px;
}
.showOnlyforUsers {
  display: none;
}
.afterLoginHeader .showOnlyforUsers {
  display: inline-flex;
}
.afterLoginHeader header > div {
  max-width: 100% !important;
}
.userPages {
  padding-top: 100px;
  padding-bottom: 30px;
}
.userPages.tradePages {
  padding-top: 80px;
  padding-bottom: 30px;
}
.dashboard_login ul {
  display: flex;
  margin-bottom: 0;
}
.disabledForm {
  position: relative;
}
/*.disabledForm::before{
  content: "";
  background: rgba(0,0,0,.4);
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  z-index: 9;
}*/
.new_burron {
  color: #000;
  background: #ffd808;
  border: none;
  padding: 7px 14px !important;
  border-radius: 10px;
  margin: 3px auto;
}
.toggleMode input {
  display: none;
}
.toggleMode input:checked ~ span {
	border-color: #272727;
	background: #686666;
}
.toggleMode span:before {
  content: "Light Mode";
  position: absolute;
  top: 0;
  left: 40px;
  font-size: 14px;
  line-height: 34px;
  color: #787878;
  border-radius: 50%;
  transition: all 0.5s cubic-bezier(0, 0.55, 0.45, 1);
}
.toggleMode input:checked ~ span::before {
	content: "Dark Mode";
	left: 10px;
	color: #fff;
	top: -1px;
}
.toggleMode input:checked ~ span::after {
	content: "\f186";
	top: 1px;
	left: 95px;
	width: 29px;
	height: 29px;
	font-size: 14px;
	color: #ffffff;
	font-family: "Font Awesome 5 Free";
	font-weight: bold;
	background-color: #272727;
	display: flex;
	align-items: center;
	justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.toggleMode span {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 130px;
	height: 35px;
	border-radius: 5rem;
	background: #f7f7f7;
	border: 2px solid #ffd808;
	transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.light_theme .toggleMode span {
  background: #090909;
}
.toggleMode span::after {
	content: "\f185";
	font-family: "Font Awesome 5 Free";
	font-weight: bold;
	position: absolute;
	font-size: 14px;
	top: 1px;
	left: 2px;
	width: 29px;
	height: 29px;
	color: #000;
	transition: all 0.5s cubic-bezier(0, 0.55, 0.45, 1);
	background-color: #ffd808;
	display: flex;
	align-items: center;
	justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.light_theme .toggleMode span:after {
  color: #090909;
}
.toggleMode label {
  margin-bottom: 0;
}
.toggleMode {
  padding-right: 20px;
  padding-left: 20px;
}

.profileDetailView ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.profileDetailView ul li {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
}
.profileDetailView ul li label {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0;
  color: #787878;
  font-size: 14px;
  width: 35%;
}
.profileDetailView ul li span {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
}
.profileDetailView h4 {
  font-size: 18px;
  font-weight: 600;
  color: #ffd808;
  margin: 0 0 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #2f2f2f;
}
.profileDetailView h4 small {
  font-size: 16px;
  color: #fff;
}
.textRed {
  color: #cb0000 !important;
}
.settingsSelect .form-group label {
  display: block;
  width: 100%;
  white-space: nowrap;
}
.settingsSelect .MuiInput-underline {
  background: #090909;
  border: 2px solid #2f2f2f;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
  color: #c5c5c5;
  min-height: 40px;
}
.settingsSelect .MuiInput-underline .MuiSelect-icon {
  color: rgb(255 255 255 / 54%);
}
.settingsSelect .MuiInput-underline::before,
.settingsSelect .MuiInput-underline::after {
  display: none;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: #ffd808 !important;
  color: #000 !important;
}
.MuiMenu-list {
  background: #090909;
  color: #c5c5c5;
}
.qrScanCode {
  /* background: url(../src/assets/images/qrcodebg.png) #fff no-repeat scroll 0 0; */
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 158px;
  flex-direction: column;
  margin: 0 auto;
}
.qrScanCode img {
  padding: 10px;
}
.settingsNote {
  margin: 0 0 30px 0;
}
.settingsNote p {
  font-size: 14px;
  line-height: 18px;
  color: #787878;
}
.settingsNote p a {
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
}
.settingsNote p a:hover {
  text-decoration: none;
  color: #ffd808;
}
.profileDetailView .settingsNote ul li {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #787878;
  font-weight: 400;
}
.profileDetailView .settingsNote h6 {
  margin: 0 0 10px 0;
  padding: 0;
  color: #ffd808;
  font-size: 14px;
  text-transform: none;
}
fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background: #090909 !important;
}
.btnType1 {
  box-shadow: none !important;
}
.input-group .form-control {
  /* border-right: 0 !important; */
  border-radius: 0;
}
.input-group .btnType1 {
  box-shadow: none !important;
  background: #ffd808 !important;
  color: #090909 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #ffd808;
  border-radius: 0 5px 5px 0;
}
.disabledGroup .btnType1 {
  box-shadow: none !important;
  background: #ffd808 !important;
  color: #090909 !important;
  font-size: 16px !important;
}
.disabledGroup span.btnType1 {
  color: #5a5a5a !important;
}
.flexLabel {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}
.flexLabel a {
  font-size: 14px;
  color: #ffd808;
}
.btn.btn-primary {
  box-shadow: none !important;
}
.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
.custom-file-label {
  background: #090909 !important;
  box-shadow: none !important;
  border: 1px solid #2f2f2f !important;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: auto !important;
  margin-bottom: 0 !important;
}
.custom-file-label:focus {
  border: 1px solid #4040fa !important;
}
.custom-file-label::after {
  background-color: #fad408 !important;
  color: #000 !important;
  border-radius: 0 5px 5px 0px;
  height: 34px;
  text-transform: uppercase;
}
.settingsNote ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.settingsNote ul li a {
  color: #ffd808;
}
.verifiedDocument {
  padding: 20px 0 0 0;
}
.verifiedDocument h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  color: #4bae50;
}
.verifiedDocument p {
  margin-bottom: 0;
}
.verifiedDocument p a {
  color: #ffd808;
}
.verifiedDocument p a:hover {
  text-decoration: underline;
}
.kycUserType {
  margin-bottom: 30px;
}
.userTypeCard {
	background: #090909;
	border: 1px dashed #2f2f2f;
	border-radius: 5px;
	padding: 15px;
	height: 100%;
}
.utcTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.utcTop h4 {
	font-size: 22px;
	font-weight: 600;
	color: #ffd808;
	text-transform: uppercase;
	margin: 0;
}
.userTypeCard ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.userTypeCard ul li {
	font-size: 14px;
	color: #fff;
	margin-top: 7px;
}
.userTypeCard ul li i {
  color: #51b155;
  margin-right: 4px;
}
.userTypeCard h6 {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	text-transform: none;
	margin-bottom: 7px;
}
.userTypeCard h6 span {
  color: #4bae50;
}
.utcTop p {
  margin: 0;
  color: #4bae50;
  font-size: 12px;
  text-transform: uppercase;
}
.utcTop p i {
  margin-right: 3px;
}
.userTypeCard.verifiedUser {
  background: #eaffeb;
}
.userTypeCard.unVerifiedUser {
  background: #ffeaea;
  border-color: #b53939;
}
.userTypeCard.unVerifiedUser h6 span {
  color: #971f1f;
}
.kycTopCard {
	border-bottom: 1px solid #2f2f2f;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.kycTopCard h3 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.kycTopCard p {
  margin: 0;
}
.kycTopCard p label {
  margin: 0;
  font-size: 16px;
  color: #787878;
}
.kycTopCard h5 {
  font-size: 16px;
  color: #787878;
  margin: 12px 0 0 0;
}
.kycTopCard h5 label {
  margin: 0;
  font-size: 16px;
  color: #787878;
}
.kycTopCard h5 span {
  border: 1px solid #4bae50;
  color: #4bae50;
  padding: 4px 20px;
  margin-left: 10px;
  border-radius: 5px;
  display: inline-block;
}
.kycTypeGreen {
  border: 1px solid #4bae50 !important;
  color: #4bae50 !important;
}
.kycTypeRed {
  border: 1px solid #e80047 !important;
  color: #e80047 !important;
}
.kycCardStatus h6 {
  text-transform: none;
  font-size: 14px;
  color: #787878;
  margin: 0 0 7px 0;
}
.kycCardStatus ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.kycCardStatus ul li {
  justify-content: space-between !important; 
}
.kycCardStatus ul li label {
  font-size: 14px;
  color: #fff;
  margin: 0;
  width: auto;
}
.kycCardStatus.labelWidth ul li label {
}
.kycCardStatus ul li span {
  margin-left: 10px;
  font-size: 14px;
  color: #787878;
}
.walletCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.walletCardLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.walletCardRight .textLinkGroup {
  margin-bottom: 0;
  color: #787878;
  font-weight: 500;
}
.walletCardRight .textLinkGroup a {
  font-size: 14px;
  color: #787878;
  font-weight: 500;
}
.walletCardRight .textLinkGroup a:hover {
  text-decoration: none;
  color: #ffd808;
}
.currencyName {
  min-width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #787878;
}
.currencyName img {
  min-width: 32px;
  margin-right: 18px;
  width: 32px;
  border-radius: 50%;
}
.currencyPrice {
  font-size: 18px;
  color: #ffffff;
  padding: 0 15px;
}
.currencyPrice small {
  font-size: 18px;
  color: #ffffff;
}
.collapseWallet {
  border-top: 1px solid #2f2f2f;
  margin-top: 15px;
  padding: 20px 20px 0px 20px;
}
.adminAccountDetails {
  background: #f7f7f7;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 15px;
}
.adminAccountDetails ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.adminAccountDetails ul li {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}
.adminAccountDetails ul li span {
  display: inline-block;
  width: 120px;
}
.walletCardRight .dropdown-menu {
  min-width: 60px;
  margin-left: -30px;
}
.walletCardRight ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.walletCardRight ul li a {
  margin: 0px;
  padding: 5px 15px;
  text-decoration: none;
  background: #fff !important;
  box-shadow: none !important;
  color: #787878 !important;
}
.walletCardRight ul li a:hover {
  background: #ffd808 !important;
  text-decoration: none;
}
.walletCardRight .dropdown-toggle::after {
  display: none !important;
}

.stakingSubscription ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stakingSubscription ul li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #2f2f2f;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.csLeft h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #787878;
}
.csLeft h3 img {
  max-width: 26px;
  min-width: 26px;
}
.csLeft p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
}
.csRight {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.csRight h4 {
	margin: 0;
	white-space: nowrap;
	font-size: 14px;
	font-weight: 600;
}
.stakingCurrencyList tr th {
  border-bottom: 0;
  font-size: 14px;
  color: #787878;
  font-weight: 500;
}
.stakingCurrencyList tr td {
  line-height: 18px !important;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 0;
  color: #ffffff;
}

.stakingCurrencyList tr td img {
  max-width: 26px;
  min-width: 26px;
  margin-right: 10px;
}
.stakingHistoryTable > div > div > div {
  background-color: #090909;
  color: #ffffff;
}
.stakingCurrencyList .table-striped tbody tr:nth-of-type(2n + 1) {
  background: #090909;
}
.rdt_TableBody .rdt_TableRow {
  border: 0 !important;
}
.rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: #f6f6f6 !important;
}
.newUsersFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2f2f2f;
  padding-bottom: 15px;
}
.newsSelectGroup {
  display: inline-flex;
  align-items: center;
}
.newsSelectGroup label {
  margin: 0;
  white-space: nowrap;
  margin-right: 15px;
}
.tableSearchBox {
  min-width: 230px;
  margin-left: 15px;
}
.flexText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stakePopDetails .flexText {
  margin-bottom: 20px;
}
.stakePopDetails .flexText label {
  margin: 0;
  color: #5a5a5a;
}
.stakePopDetails .form-group label {
  color: #5a5a5a;
  margin: 0;
}
.stakePopDetails .flexText h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.input-group-prepend .btnType1 {
  border-radius: 5px 0 0 5px !important;
}
.contact_form .form-control.borderZero {
  border-left: 0 !important;
}
.stakeDetailsRight h4 {
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}
.stakeDetailsRight {
  background: #f7f7f7;
  padding: 15px;
}
.stakeDetailsRight ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stakeDetailsRight ul li {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.stakeDetailsRight ul li label {
  color: #5a5a5a;
  width: 45%;
  margin: 0 15px 0 0;
}
.modal-title {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0;
}
.stakePopDetails .form-check {
  display: flex;
  align-items: center;
}
.stakePopDetails .form-check a {
  color: #ffd808;
}
.stakePopDetails .form-check a:hover {
  text-decoration: underline;
}

.historyCryptoTable .rdt_TableCell a {
  color: #ffd808;
}
.historyCryptoTable .rdt_TableCell a:hover {
  text-decoration: underline;
}
.marginSpace {
  margin-left: 15px;
}
.supportTicketList {
  background: #090909;
  padding: 15px;
  border: 1px solid #2f2f2f;
}
.supportTicketList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.supportTicketList ul li {
  margin-bottom: 7px;
  font-weight: 400 !important;
}
.supportTicketList ul li a {
  font-size: 16px;
  color: #ffffff;
  transition: 0.5s;
}
.supportTicketList ul li a:hover {
  color: #ffd808;
}
.supporTicketAccordian {
  padding: 0;
}
.supporTicketAccordian #accordion .card-header .btn {
  padding: 18px 0;
  display: block;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
  text-decoration: none;
  border-bottom: 0;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.supporTicketAccordian #accordion .card-header .btn.btn-link.collapsed {
  color: #c8c8c8;
  text-decoration: none;
  transition: 0.5s;
  border-bottom: 0px solid #ffd808;
  border-radius: 0;
  padding-bottom: 0px;
  transition: 0.5s;
  font-weight: 400;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
}
.supporTicketAccordian #accordion .card-header .btn.btn-link.collapsed:hover {
  text-decoration: none;
  color: #ffd808;
}
.supporTicketAccordian #accordion .card-header:hover .btn.btn-link.collapsed {
  text-decoration: none;
  color: #ffd808;
}
.supporTicketAccordian #accordion .card-header .btn i {
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  padding: 8px;
  color: #ffd808;
  font-size: 0.85rem;
  transition: 0.5s;
}

.supporTicketAccordian #accordion .card-header {
  padding: 0px 0;
  margin-bottom: 0;
  background: transparent;
  border-bottom: 0;
  box-shadow: none !important;
  margin: 0;
}
.supporTicketAccordian #accordion .card-header h5 {
  margin: 0;
  padding: 0;
}
.supporTicketAccordian #accordion .card {
  border-radius: 0px;
  border: 0;
  background: #090909;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #2f2f2f;
  box-shadow: none;
  margin-bottom: 15px;
  border-radius: 5px;
}
.supporTicketAccordian #accordion .card-body {
  padding: 10px 0 15px 0;
  border-top: 1px solid #2f2f2f;
}
.supporTicketAccordian #accordion .card-body p {
  color: #787878;
  font-size: 14px;
}
.supporTicketAccordian #accordion .card-body p.metaChatDetails {
  color: #787878;
  font-size: 14px;
}
.supporTicketAccordian
  #accordion
  .card-header
  [data-toggle="collapse"]
  .fa:before {
  content: "\f068";
  color: #ffd808;
}

.supporTicketAccordian
  #accordion
  .card-header
  [data-toggle="collapse"].collapsed
  .fa:before {
  content: "\f067";
  color: #ffd808;
}
.supporTicketAccordian #accordion .card-header:hover .btn i:before {
  color: #ffd808 !important;
}
.supporTicketAccordian #accordion .card-header .btn .numList {
  background: #ff8300;
}
.supporTicketAccordian
  #accordion
  .card-header
  .btn.btn-link.collapsed
  .numList {
  background: #ffd808;
}
.yellowText {
  color: #fb6321 !important;
}
.greenText {
  color: #029cae !important;
}
.submitChat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticketUserDetails {
  text-align: center;
  margin-right: 20px;
  width: 60px;
}
ul.ticketComments {
  margin: 0;
  padding: 0;
}
ul.ticketComments li {
  list-style: none;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}
ul.ticketComments li:last-child {
  margin-bottom: 0;
}
.userImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.userImg img {
  border-radius: 50%;
}
.supportTicketStatus i {
  background: #2b2e35;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.messageTypeBox {
  margin-top: 20px;
}
.metaChatDetails {
  border-bottom: 1px solid #2f2f2f;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.submitChat a {
  color: #ffd808;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  text-decoration: underline;
}
.submitChat a:hover {
  color: #ffffff;
  text-decoration: none;
}
.statusOpenText {
  color: #42ff8e;
}
.statusCloseText {
  color: #ff4273;
}
.ticketDetails {
  width: calc(100% - 70px);
}
.stHeadText {
  font-size: 16px;
  color: #ffffff;
  text-transform: none;
}
.stHeadText small {
  color: #ffd808;
  font-size: 16px;
  text-transform: none;
  margin-right: 10px;
}
/* .stHeadText.subjectWidth {
  width: 70%;
} */

.panel {
  background: #c0c0c0;
  padding: 100px;
  border: #000;
}

/* new home section */
.banner_container h1 {
  font-size: 60px;
  color: #fff;
}
.new_home_section_o p {
  font-size: 18px;
  margin: 0;
  padding-top: 10px;
}
.banner_inside_img img {
  transform: scale(1.2);
  margin-top: 59px;
  width: 100% !important;
  height: 100% !important;
}
.banner_slider_image {
  /* width: 70% !important;
  height: 175px !important; */
  object-fit: cover;
  margin: 0 auto;
}
.banner_container {
  margin-top: 80px;
  background: #090909;
  padding-bottom: 50px !important;
}
.token_new_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  background: #000;
  padding: 4px 12px;
  border-radius: 8px;
  background: rgba(39, 39, 39, 0.4);
  /* box-shadow: 0 8px 32px 0 rgba(33, 33, 34, 0.37); */
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(91, 91, 91, 0.18);
  height: 154px;
}
.token_new_card p {
  margin: 0;
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 6px;
  color: #ffe038;
}
.token_new_card h2 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
}
.token_new_card button,
.token_new_card a.primary_btn {
  margin: 9px 0px !important;
  padding: 2px 11px !important;
  font-size: 13px;
  line-height: 20px;
}
.token_new_card a.primary_btn:hover,
.token_new_card a.primary_btn:hover span {
  color: #ffd703 !important;
}
.token_new_card img {
  /* width: 148px; */
  /* transform: scale(1.2); */
  /* margin-bottom: -1px; */
  margin-top: 2px;
  position: relative;
  top: 16px;
  left: 2px;
}
.token_new_card span {
  color: #ffe038;
}
.gp_gray {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(15, 15, 15) 100%);
  padding: 33px 0px;
}
.whitw_color {
  color: #fff !important;
}
.orince_colo {
  color: #ffdf30 !important;
}
.width_12 {
  width: 139px;
}
/* .gd_ted{
  background: url(../src/assets/images/logo_transparant.png) no-repeat scroll 0 0;
  width: 100%;
background-size: 66% 108px;
background-position: center;
} */
.icon_lectdf_token {
  /* width: 119px !important; */
  top: 34px !important;
  left: 20px !important;
}
.token_new_card_11 {
  position: relative;
}
.token_new_card_11:before {
  content: "";
  position: absolute;
  background: url(../src/assets/images/logo_transparant.webp) no-repeat scroll 0
    0;
  width: 116px;
  height: 129px;
  background-size: 121px 138px;
  right: 43px;
  top: 8px;
}
.token_new_card_11_22 {
  position: relative;
}
.token_new_card_11_22:before {
  content: "";
  position: absolute;
  background: url(../src/assets/images/logo_transparant.webp) no-repeat scroll 0
    0;
  width: 116px;
  height: 129px;
  background-size: 115px 130px;
  left: 25px;
  top: 9px;
}
.token_new_card:hover {
  box-shadow: 0px 9px 7px #000;
  cursor: pointer;
}
.slider_contet p {
  margin: 0;
}
.slider_contet h3 {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  color: #fff;
  font-size: 22px;
}
.slider_contet img {
  width: 15px;
  margin-left: 14px;
}
.black_ae {
  padding: 41px 0px;
  background: #000;
}
.card_digital_currnsy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 12px;
  margin: 18px 16px;
  border: 1px solid #2f2f2f;
  border-radius: 9px;
  transition-duration: 0.3s;
  min-height: 335px;
}
.card_digital_currnsy h3 {
  margin: 0;
  text-align: center;
  padding: 22px 4px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}
.card_digital_currnsy p {
  text-align: center;
  color: #bcbcbc;
}
.card_digital_currnsy:hover {
  background: #ffd805;
  border-color: transparent;
  filter: drop-shadow(0px 6px 5px #f7d31454);
  cursor: pointer;
  border: 1px solid #ffd805;
}
.card_digital_currnsy:hover.card_digital_currnsy h3 {
  color: #000;
}
.card_digital_currnsy:hover.card_digital_currnsy p {
  color: #000;
}

.clas_heasdin p {
  text-align: center;
  padding: 8px 2px;
  margin: 0;
}
.clas_heasdin h1 {
  margin: 0;
  text-align: center;
  padding: 16px 1px;
  color: #fff;
  font-size: 31px;
}
.tabel_tab_new .nav-tabs {
  background: #1a1a1a;
  padding: 0;
  border-radius: 0;
}
.nav_icin_tab a {
  /* height: fit-content !important; */
  display: block;
  padding: 11px 30px;
  font-weight: 600;
  color: #fff;
  opacity: 0.3;
}
.nav_icin_tab img {
  width: 26px;
  height: 26px;
  margin-right: 7px;
}
.nav_icin_tab a.active {
  background: #272727;
  opacity: 1;
}

.tabel_inside_content .table-dark th,
.tabel_inside_content .table-dark td,
.tabel_inside_content .table-dark thead th {
  border-color: transparent;
}
.tabel_inside_content .table-dark > thead > tr > th {
  color: #ffd700;
}
.tabel_inside_content .table-dark.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #1a1a1a;
}
.tabel_inside_content .table-dark > thead {
  background: #272727;
}
.tabel_inside_content {
  margin-top: -1px;
}
.tabel_inside_content .table-striped tbody tr:nth-of-type(2n) {
  background-color: #272727 !important;
}
.table_button_p button {
  width: 60px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 7px;
  background: #353535;
  color: #fff;
}
.table_button_p button:hover {
  background: #ffd700;
  color: #000;
  cursor: pointer;
}
.tabel_inside_content .table th,
.tabel_inside_content .table td {
  padding: 16px 16px;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  font-weight: 600;
}
.marketTableSpot .overflow_hid_we {
  margin-top: 15px;
}
.overflow_hid_we {
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
}
.tabel_inside_content table {
  margin: 0;
}
.tabel_tab_new button.primary_btn {
  padding: 7px 18px !important;
  font-size: 15px;
  margin: 30px auto !important;
  display: block;
}
.gb_app_sectio {
  background: #151515;
  padding: 15px 0px;
  /* margin-top: 50px; */
}
.gb_app_sectio img {
  width: 68%;
  margin: 0 auto;
  display: block;
  /* transform: scale(2.6); */
}
.home_timer_cls .timer_panel {
  justify-content: flex-start;
  margin-top: 10px;
}
.padin_for_timer {
  padding: 70px 0px;
}
.home_timer_cls span.timer_time {
  font-size: 50px;
  margin-bottom: 10px;
}
.gb_app_sectio p {
  text-align: left;
  font-size: 27px;
  color: #fff;
}
.gb_app_sectio h1 {
  text-align: left;
  font-size: 50px;
  color: #ffd807;
  font-weight: 600;
}
.mat_ton_p {
  margin-top: 24px !important;
}
.why_content_right h1 {
  font-size: 38px;
  text-align: left;
  color: #fff;
}
.why_content_right p {
  text-align: left;
}
.inner_why_content h2 {
  font-size: 20px;
  color: #ffd700;
}
.inner_why_content p {
  text-align: left;
}
.inner_why_content {
  width: 72%;
}

.card_section_7 {
  background: url(../src/assets/images/trad_chart.webp) no-repeat scroll 0 0;
  width: 68%;
  margin: 116px auto;
  border: 1px solid #ffd700;
  border-radius: 10px;
  padding: 10px 38px;
  background-size: cover;
}
.card_section_7 h1 {
  text-align: left;
  font-size: 60px;
  font-weight: bold;
  color: #ffd700;
  padding: 0;
}
.card_section_7 p {
  text-align: left;
  padding: 0;
  color: #626262;
  font-weight: 600;
}
.card_section_7 div {
  margin: 15px 0px;
}
.getstarted_section {
  margin: 16px 21px;
  padding: 21px;
}
.icon_ing {
  width: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 156px;
  margin: 12px 0px;
  background: #272727;
  border-radius: 10px;
  transition-duration: 0.3s;
}
.icon_ing img {
  width: 90px;
  padding: 4px;
}

.content_get_start h3 {
  margin: 0;
  color: #ffd700;
  font-size: 20px;
  margin-bottom: 11px;
}
.content_get_start {
  margin-top: 34px;
}
.getstarted_section:hover {
  cursor: pointer;
}
.getstarted_section:hover .icon_ing {
  background: #ffd700;
  filter: drop-shadow(0px 2px 4px #99830a);
}
.foter_logo_sectopn_i {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 22px 0px !important;
}
.fooer_ll img {
  transition-duration: 0.3s;
}
.fooer_ll:hover.fooer_ll img {
  cursor: pointer;
  filter: invert(68%) sepia(88%) saturate(3116%) hue-rotate(27deg)
    brightness(132%) contrast(233%);
}
.flex_footer_1 {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.flex_footer_1 .social_media {
  margin-left: 20px;
  color: #a3a3a3;
}
.flex_footer_1 h5 {
  color: #a3a3a3;
  font-size: 16px;
}
.flex_footer_1 a {
  font-size: 23px;
  font-weight: 500;
  color: #8f8f8d;
}
.footer_link_ww {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
}
.footer_link_ww a {
  font-size: 16px;
}

.chartcontainer {
  height: 100%;
}
nav.place_order_nav_tabs {
  display: block;
  min-width: 100%;
  /* overflow-x: auto; */
}
.place_order_nav_tabs .nav {
  white-space: nowrap;
  display: inline-block;
  min-width: 100%;
  padding: 0;
}
.place_order_nav_tabs .nav .nav-item {
  display: inline-block;
}
.placeOrderBox.contact_form h3 span {
  font-size: 14px;
}
.placeOrderBox.contact_form h3 {
  font-size: 20px;
}
.clss_789table th {
  font-size: 12px;
  color: #ffffff;
  padding: 5px 12px;
}
.clss_789table td {
  padding: 5px 12px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  color: #939393;
}
.clss_789table p {
  margin: 0;
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  /* align-content: center; */
  align-items: center;
  color: #ffffff;
}
.clss_789table span {
  color: #ffffff;
  text-align: left;
}
.light_theme .clss_789table p,
.light_theme .clss_789table p span {
  color: #eeeeee !important;
  text-align: left;
}
.light_theme .clss_789table p span.price_increase{
  color: #04b06d !important
}
.clss_789table i {
  font-size: 10px;
  margin-right: 3px;
  margin-top: -2px;
  color: #fd9933;
}
.span_menu_gerrn {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #04b06d !important;
}
.span_menu_red {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #e63c4d !important;
}
.light_theme .clss_789table tbody > tr:hover {
  background: rgba(120, 120, 120,.25);
}
.clss_789table tbody > tr:hover {
  background: rgba(120, 120, 120,.25);
}
.price_increase {
  color: #04b06d !important;
}
.price_decrease {
  color: #e63c4d !important;
}
.colornormal {
  color: #939393 !important;
}

#notificationDropdown {
	position: absolute;
	top: 46px;
	min-width: 300px;
	margin-left: -240px;
}
.notificationDropdown {
	background: #272727;
	border-radius: 7px;
}
.notificationDropdown ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 15px !important;
  list-style: none;
  border: 0;
}
.notificationDropdown ul li {
	border-bottom: 1px solid #414248;
	height: auto;
	border-left: 0;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	align-items: flex-start;
	padding: 0 0 12px;
	margin: 0 0 12px;
}
.notificationDropdown ul li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.notificationDropdown ul li h5 {
  margin: 0;
  font-size: 14px;
}
.notificationDropdown ul li p {
  margin: 0;
  font-size: 12px;
}
.btnNotification {
  background: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #787878;
}
.btnNotification:hover {
  color: #ffd808;
}
.btnNotification:focus {
  color: #787878;
}
.input-group.regGroupInput {
  border: 2px solid #2f2f2f;
  border-radius: 15px;
}
.regGroupInput .input-group-append {
  align-items: center;
  padding: 0 20px;
}
.login_container .input-group.regGroupInput .form-control {
  border: 0;
}
.hintText {
  line-height: 14px;
}
.profileDetailView .alert.alert-info {
  padding: 15px 15px;
  border-radius: 0.25rem;
  color: #c5c5c5;
  background: #191818;
}
.profileDetailView .alert.alert-info a {
  color: #ffd808;
}
.green-button .btn.btn-primary {
  background: #3ab521;
  color: #090909;
  text-align: center;
  font-size: 15px;
}
.nav_icon_container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  margin-right: 8px;
  border-radius: 6px;
  background-color: rgb(255 255 255 / 25%);
}
.nav_icon_container i {
  font-size: 18px;
  color: #fff;
}
.navbar_left {
  display: inline-flex;
}
.navbar_left a#dropdownMenuIcon {
	background: #272727;
	padding: 3px 10px;
	box-shadow: none;
	margin-left: 20px;
	width: 35px;
	height: 28px;
}
.navbar_left a#dropdownMenuIcon i {
  font-size: 20px;
}
.navbar_left .mainmenudropdown {
  background: #272727;
}
.nav_menu_item_container h6 {
  margin-top: 8px;
  margin-bottom: 2px;
  font-size: 0.9rem;
  font-weight: 700;
  color: #cbcbcb;
}
.nav_menu_item_container p {
  font-size: 0.75rem;
  opacity: 0.8;
  text-transform: none;
  color: #cbcbcb;
}
.navbar_left .mainmenudropdown a.dropdown-item {
  padding: 8px 15px;
  width: 300px;
  justify-content: space-between;
}
.navbar_left .mainmenudropdown a.dropdown-item:hover {
  background: #000;
  box-shadow: none !important;
}
.navbar_left .mainmenudropdown a.dropdown-item i.fa-arrow-right {
  display: none;
  transition: 0.5s;
  font-size: 18px;
}
.navbar_left .mainmenudropdown a.dropdown-item:hover i.fa-arrow-right {
  display: block;
}
.MuiSwitch-thumb {
  color: #ffd808;
}
.MuiSwitch-track {
  background-color: #272727 !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #ffd808 !important;
}
.f_13 {
  font-size: 13px;
}
.btn.btn-primary a {
  color: #000;
}

/* Launchpad CSS Starts */
.launchpad_token_section {
  background: #eef2f7;
  padding: 0 0 60px;
}
.timer_panel {
  text-align: center;
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  font-size: 14px;
  color: #7d7d7d;
}
span.timer_time {
  font-size: 20px;
  color: #fff;
}
.timer_panel > span {
  margin: 5px;
}
span.timer_dots {
  margin: 0 3px;
  line-height: 38px;
  color: #333333;
}
.launchpad_btn_center {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}
.launchpad_btn_center .btnLandingJoin {
  padding: 10px 30px;
  font-size: 14px;
}
.launchpad_token_single {
  background: #090909;
  border: 1px solid #2d2d2d;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgb(239 203 2 / 5%);
  margin-bottom: 30px;
  min-height: 682px;
}
.launchpad_token_single img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.launchpad_token_single p {
  padding: 0 15px;
  margin: 0 0 10px;
  font-size: 14px;
  color: #fff;
}
.launchpad_token_single h4 {
  font-size: 18px;
  color: #fff;
  margin: 15px 0 5px;
}
.launchpad_token_single .btnLandingJoin {
  font-size: 14px;
  padding: 7px 30px;
  margin-bottom: 20px;
}
.launchpad_token_single > div p:first-child {
  color: #7d7d7d;
}
.launchpad_token_single h6 {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteBoxLaunchpad {
  background: #090909;
  border: 1px solid #2d2d2d;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 10%);
}
.launchpadCoinName {
  display: flex;
  align-items: center;
}
.launchpadCoinName img {
  margin-right: 15px;
}
.launchpadCoinName h3 {
  font-size: 36px;
  font-weight: 400;
}
.launchpadCoinName h3 small {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
}
.launchPadCoinDetails h4 {
  font-size: 18px;
  color: #fff;
  border: 2px solid #fcb305;
  padding: 10px 30px;
  border-radius: 50px;
}
.launchPadCoinDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.whiteShadowBox {
  box-shadow: 0 0 7px 3px rgb(248 210 1 / 3%);
  background: #090909;
  border: 1px solid #2d2d2d;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px 30px 15px;
}
.projectIntroduction h3 {
  font-size: 18px;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 10px 0;
}
.projectIntroduction ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.projectIntroduction li {
  color: #fff;
  font-size: 14px;
  margin: 10px 0;
}
.projectIntroduction li span {
  font-size: 14px;
  color: #fff;
  width: 40%;
  display: inline-block;
}
.projectIntroduction p {
  color: #fff;
  font-size: 14px;
}
.whiteShadowBox .form-group label {
  color: #fff;
  font-size: 14px;
}
.choose_coin_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 10px;
}
.choose_coin_list li {
  margin: 0 25px 0 0;
}
.choose_coin_list li a {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s all;
}
.choose_coin_list li a.active {
  color: #ffd902;
  border-bottom: 2px solid #ffd902;
}
.whiteShadowBox input.form-control {
  border: 2px solid #2f2f2f;
}
.whiteShadowBox span.input-group-text {
  background-color: transparent;
  border: 2px solid #2f2f2f;
  border-radius: 5px;
}
.btnGroupPercent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.whiteShadowBox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffd801;
  background-color: #febf04;
}
.whiteShadowBox h3 {
  font-size: 18px;
  margin: 0;
}
.whiteShadowBox .table thead th {
  border-bottom: 0;
  color: #fff;
}
.whiteShadowBox .table tbody {
  border-top: 0;
  color: #fff;
}
.project_docs a {
  font-size: 16px;
  color: #7d7d7d;
  margin: 0 40px 0 0;
  display: inline-block;
}
.project_docs a.active {
  color: #ffd801;
}
.socialMediaCoinDetails ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}
.socialMediaCoinDetails ul li {
  margin: 0 20px 10px 0px;
}
.socialMediaCoinDetails ul li a {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.4s all;
}
.socialMediaCoinDetails ul li a:hover {
  color: #ffd801;
  border: 1px solid #ffd801;
}
.socialMediaCoinDetails h3 {
  font-size: 18px;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 10px 0;
  margin-top: 30px;
}
.cdTeamDetail h3 {
  font-size: 18px;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 10px 0;
  margin: 30px 0;
}
.cdTeamDetail ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0;
}
.cdTeamProfilePic img {
  border-radius: 50%;
  margin-right: 10px;
}
.cdTeamDetail ul li {
  display: flex;
}
.cdTeamProfileDetail h6 {
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail p {
  color: #7d7d7d;
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}
.cdTeamProfileDetail a:hover {
  color: #ffd801;
  border: 1px solid #ffd801;
}
.copy_trading_top_panel {
  background: #090909;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #2f2f2f;
}
.copy_trading_top_panel .nav-pills {
  padding: 0;
}
.copy_trading_top_panel .input-group {
  width: 300px;
}
.custom-control-label:after,
.custom-control-label:before {
  top: 0;
  left: -20px;
}
button.inner_primary_btn {
  background-image: linear-gradient(#ffd801, #ffd801);
  border: 2px solid #ffd801;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 25px;
  color: #000;
  transition: all 0.2s;
  border-radius: 5px;
}
.copy_trading_top_panel input.form-control {
  background-image: linear-gradient(
      to top,
      #ffd804 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #2f2f2f 1px, rgba(210, 210, 210, 0) 1px);
}
/* Launchpad CSS Ends */
/* p2p */
.t2patid {
  padding: 25px 20px;
}
.chat_content_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #1b2038;
}
.chat_content_center p {
  margin: 0;
  text-align: center;
}
.chat_content_center h4 {
  margin: 0;
  font-weight: 600;
  padding: 4px 0px;
  color: #ffd808;
}
.chat_conte_po {
  padding: 12px;
}
.chat_conte_po h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px;
  color: #fff;
}
.new_timer {
  display: grid;
  place-content: center;
  padding: 3px 2px;
}
.chat_conte_po p {
  margin: 0;
  font-size: 13px;
  /* margin: 0; */
  font-weight: 600;
}
.button_section_po_chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.chat_conte_po a {
  color: #5d5e65 !important;
}
.chat_message_section {
  min-height: 260px;
  height: 352px;
  overflow: auto;
}
.message_send {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-end;
}
.message_section div {
  width: auto;
  padding: 6px 3px;
  position: relative;
  /* margin: 22px 9px; */
}
.message_section p {
  margin: 0;
  padding: 10px 30px;
  color: #fff;
  background: #161a2d;
  font-size: 13px;
}
.message_section span {
  position: absolute;
  top: -16px;
  /* width: 100%; */
  right: 4px;
  color: #6f7075;
  font-size: 12px;
}
.message_send span {
  left: 4px !important;
}
.messAge_recive {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-start;
}
.message_section div {
  width: auto;
  padding: 6px 3px;
  position: relative;
  /* margin: 22px 9px; */
}
.message_section p {
  margin: 0;
  padding: 10px 30px;
  color: #fff;
  background: #161a2d;
  font-size: 13px;
}
.footer_chat {
  width: 100%;
  padding: 25px 0 0;
}
.footer_chat .form-group {
  margin: 0;
}
.footer_chat .form-control {
  background: #141825;
  padding: 14px 11px;
  border-radius: 5px;
  border: 1px solid #1b2038;
  height: 45px;
  color: #fff;
  text-transform: capitalize;
}
.footer_chat .fa-paper-plane {
  float: right;
  margin-right: 20px;
  margin-top: -31px;
  font-size: 20px;
  color: #ffd808;
  cursor: pointer;
}
.chat_box_dark {
  min-height: 338px;
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  background: #090909;
}
.color_gol-sd {
  color: #fff;
}
.end_content {
  display: flex;
  justify-content: flex-end;
}
.color_whit_7 {
  color: #fff !important;
}
.inputabowlabel {
  margin: 0;
  font-size: 14px;
  padding: 3px 0px;
}

.with_22_2 {
  width: 20%;
}
.spna_ifd {
  font-size: 14px;
  margin-right: 6px;
  color: #5d5e65;
}
.unde_lin_qwe {
  float: right;
  color: #ffd808 !important;
}
.d-flex_p2p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.width_input {
  width: 100%;
}
.flot_fixe_ins .MuiInput-underline {
  width: 35%;
  border-radius: 0px 5px 5px 0px;
  padding: 0.5px 5px;
}
.paggin_sdf {
  padding: 10px 0px;
}
.buttont_poidf_butn {
  padding: 6px 0px !important;
  margin: 12px 7px !important;
}
.inpor_inside_buttons {
  border: 2px solid #2f2f2f;
  background: #090909;
  color: #8f9097;
  border-radius: 0px 5px 5px 0px;
  padding-right: 10px;
}
.d-flex_p2p_23 label {
  margin: 0;
  font-size: 14px;
  color: #ccc !important;
  width: 51% !important;
  padding: 1px 7px;
  text-align: center;
}
.whithAutft label {
  width: auto !important;
}
.bitcoin_value_box {
  width: 100%;
  padding: 5px 8px;
  background: #090909;
  border: 2px solid #2f2f2f;
  border-radius: 5px;
}
.bitcoin_value_box h3 {
  font-size: 18px;
  margin: 0;
  color: #ffd808;
  text-align: center;
}
.bitcoin_value_box span {
  font-size: 14px;
  margin-right: 6px;
  color: #5d5e65;
}
.mt-151_15 {
  margin-top: 20px !important;
}
.flort_lesttd {
  float: left;
}
.w100_form_p2p {
  width: 100%;
}
.wdth--1001 {
  width: 100%;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}
.w100_form_p2p .react-datepicker-wrapper {
  width: 100%;
}
.wdth--1001 i {
  float: right;
  margin-right: 11px;
  margin-top: -26px;
  position: relative;
  font-size: 16px;
}
.ckksdyt_box {
  display: flex;
  align-items: center;
  margin: 0;
}
.display_block_mars {
  width: 150px !important;
  display: block;
  margin: 25px auto;
}
.buy_sellaction .modal-dialog .modal-content {
  background: #080808;
  border: 1px solid #2b2d37;
  /* z-index: 5555; */
}
.buy_sellaction .modal-dialog .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.confirm_body {
  padding: 25px 0px;
}
.confirm_body h2 {
  margin: 0;
  font-size: 22px;
  text-align: center;
  padding: 7px 1px;
  color: #000;
}
.confirm_body img {
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}
.model_detail_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.detail_text_model {
  width: 50%;
  padding: 4px 0px;
}
.righttext {
  text-align: right;
  padding: 0px 7px;
  width: 100%;
  display: block;
}
.model_detail_text span {
  font-size: 16px;
}
.colo_fff {
  color: #fff;
  font-size: 15px !important;
}
.mat_205 {
  margin-top: 20px;
}
.confirm_body .new_confirm_pay_button {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
.button2 {
  background: transparent !important;
  border: 1px solid #ffe201 !important;
  color: #ffe201;
}
.new_confirm_pay_button .button1 {
  min-width: 130px !important;
  height: 31px;
  padding: 0 !important;
  margin: 10px 0px !important;
  border: none;
  padding: 8px;
  border-radius: 4px;
  background: #ffe201;
  text-transform: uppercase;
}
.page_wrap header {
  z-index: 1000 !important;
  position: fixed;
}
.peer2peer_contant_first h1 {
  font-size: 20px;
  text-align: center;
  color: #fff;
  padding: 5px 0px;
}
.peer2peer_contant_first p {
  margin: 0;
  font-size: 13px;
  text-align: center;
  padding: 7px 0px;
}
.recent_title h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	text-align: center;
}
.Post_id p {
  margin: 0;
  font-size: 13px;
  color: #334dbc;
}
.Post_id span {
  color: #5d5e65;
}
.recentad_section p {
  font-size: 13px;
  margin: 0;
  color: #fff;
}
.Posted_date p {
  color: #fff;
}
.Sell_section p {
  color: #fff;
}
.link_section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.link_section p {
  color: #787878;
  margin: 0 4px;
}
.border_po0 {
  border: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.recentad_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #090909;
  margin: 18px 23px;
  padding: 12px 9px;
  border-radius: 5px;
  border: 1px solid #181818;
}
.w100_form_p2p {
  width: 100%;
}
.d-flex_p2p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.settingsSelect .form-group label {
  display: block;
  width: 100%;
}
.clas_Newselect .form-group label {
  color: #fff !important;
}
.clas_Newselect124 .form-group label {
  color: #5d5e65 !important;
}
.flot_fixe_ins input {
  float: left;
  width: 70%;
  border-radius: 5px 0px 0px 5px !important;
  border-right: 0 !important;
}
.flot_fixe_ins .MuiInput-underline {
  border-radius: 0px 5px 5px 0px;
}
.peer2peer_contant_first button {
	width: 190px;
	margin: 4px 6px;
	display: block;
	border: none;
	padding: 8px;
	border-radius: 4px;
	background: #ffd801;
}
.refresh_color_78 {
  margin: 0;
  padding: 17px;
  text-align: right;
  color: #ffd801;
  text-transform: uppercase;
  font-size: 14px;
}
.d-flex_p2p label {
  margin: 0;
  font-size: 13px;
  color: #ccc !important;
  width: 45% !important;
  padding: 1px 5px;
}
.blew {
  color: #f6d001;
  text-align: center;
  font-size: 24px;
  margin: 10px 0 15px;
}
.buy_table_contant {
  text-align: left;
}
.buy_table_contant h4 {
  margin: 0;
  font-size: 14px;
}
.buy_table_contant p {
  margin: 0;
  font-size: 14px;
  color: #8f9097;
}
.balance_amt_detail h4 {
  margin: 0;
  font-size: 14px;
}
.balance_amt_detail span {
  color: #8f9097;
  /* margin-left: 4px; */
}
.balance_amt_detail p {
  margin: 0;
  font-size: 14px;
}
.clas_po_78 {
  margin: 0;
  text-align: left;
}
.peer2peer_section .dashHistoryTable tr td {
  text-align: center;
}
.buy_button_op div {
  margin: 15px auto;
}
.buy_button_op button {
  border: 1px solid #03b470;
  padding: 5px 14px;
  border-radius: 5px;
  box-shadow: none;
  background: transparent;
  color: #03b470;
  text-transform: uppercase;
  font-size: 14px;
}
.balance_amt_detail {
  text-align: left !important;
}
.sellcolor_o {
  color: #9e1d38 !important;
  border-color: #9e1d38 !important;
}
.buy_sellaction .modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}
.buy_sellaction .modal-title {
  font-size: 20px;
  color: #02c076;
}
.model_detail_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.detail_text_model {
  width: 50%;
  padding: 4px 0px;
}
.model_detail_text .f-20 {
  font-size: 17px;
  word-wrap: break-word;
  padding-right: 10px;
}
.select_box .MuiSelect-icon,
.select_box .MuiInputBase-root {
  color: #fff;
}
.select_box .MuiInput-underline::before,
.select_box .MuiInput-underline::after {
  display: none;
}
.detail_text_model {
  width: 50%;
  padding: 4px 0px;
}
.color_whit_7 {
  color: #fff !important;
}
.model_detail_text span {
  font-size: 16px;
}
.color_green_o {
  color: #1ab666;
}
.buy_sellaction .modal-dialog .modal-header {
	border-bottom: 1px solid #2b2d37;
}
.grid_wh_padding {
  display: grid;
  place-content: center;
  padding: 40px 17px;
}
.solo_padding {
  padding: 18px 20px;
}
.confirm_body h2 {
  margin: 0;
  font-size: 22px;
  text-align: center;
  padding: 7px 1px;
  color: #fff;
}

.confirm_body img {
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}
.confirm_body p {
  font-size: 16px;
  text-align: center;
  color: #fff;
}
.confirm_body p {
  font-size: 16px;
  text-align: center;
  color: #fff;
}
.confirm_body .new_confirm_pay_button {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
.confirm_sellaction .modal-dialog {
  max-width: 450px !important;
  margin: 1.75rem auto;
}
.onlytab_bordernone {
  padding: 0;
  margin-top: 40px;
}
.border_po0 .boxSpace {
  background: transparent;
  border: 1px solid #313131;
  padding: 11px 0px;
  border-radius: 5px;
}

.flexDashBalance {
  display: flex;
  flex-direction: column;
}
.balance_details_left h3 {
  font-size: 16px;
  color: #787878;
  margin: 0px;
}
.peer2peer_section .balance_color_dash {
  font-size: 26px !important;
}
.balance_details_left h2 span {
  font-size: 20px;
}
.balance_details_left h3 {
  font-size: 16px;
  color: #787878;
  margin: 0px;
}
.peer2peer_section .balance_details_left h4 {
  font-size: 20px;
  color: #ffff;
}
.p-t_09 {
  padding-top: 10px;
}
.post_ter {
  margin: 0 auto;
  display: block;
}
.flot_fixe_ins {
  display: flex;
}
.flot_fixe_ins input.form-control {
  border-radius: 5px 0px 0px 5px !important;
  border-right: 0 !important;
}
.ckksdyt_box span {
  margin: 0 5px;
}
.ckksdyt_box input {
  margin-right: 6px;
}

.new_flex_iterm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.new_flex_iterm select {
  height: 40px;
  border: none;
  padding: 10px 18px;
  border-radius: 6px;
}
.new_flex_iterm .form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.input_position {
  position: relative;
}
.flx_amount_just {
  display: flex;
  width: fit-content;
  align-items: baseline;
  position: absolute;
  right: 0px;
  top: 13px;
}
.button_section_chat {
  width: fit-content;
  position: relative;
  right: 0;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.button_section_chat > div {
  position: relative;
}
.button_section_chat input {
  width: 20px;
  cursor: pointer;
}
.button_section_chat .fa-paperclip {
  color: #ffd807;
  font-size: 19px;
  margin-right: 9px;
}
.button_section_chat input {
  z-index: 5 !important;
}
/* p2p */

.stakingHistoryTable .rdt_Pagination {
  background: transparent;
  color: #fff;
}
#pagination-first-page > svg {
  fill: #fff !important;
}
#pagination-previous-page > svg {
  fill: #fff !important;
}
#pagination-next-page > svg {
  fill: #fff !important;
}
#pagination-last-page > svg {
  fill: #fff !important;
}

/* Blog Page */
.blogWhiteCard {
  background: #151515;
  padding: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  margin-bottom: 30px;
  margin-top: 0 !important;
}
.blogSidebarTitle {
  font-size: 20px;
  color: #fbb91a;
  padding: 15px;
  background-color: #1c1c1c;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  text-transform: uppercase;
  margin-top: 0;
}
.blogSideBarLinks {
  margin: 0;
  padding: 10px 15px 5px;
  list-style: none;
}
.blogSideBarLinks li {
  padding-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.blogSideBarLinks li::before {
  content: "\f101";
  font-family: "Font Awesome 5 Free" !important;
  font-display: swap;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
  color: #a4a4a7;
}
.blogSideBarLinks li a {
  font-size: 15px;
  color: #a4a4a7;
}
.blogSideBarLinks li a:hover {
  color: #faba1a;
}
.news_section {
	margin-bottom: 30px;
	background-color: #1c1c1c;
	border-radius: 5px;
}
.blog_inner .news_section {
  border-bottom: 0px solid rgb(251, 185, 26);
}
.news_section img {
  margin-bottom: 15px;
}
.news_content {
  text-align: left;
  padding: 6px 0px;
  padding-top: 0;
}
.news_content .newstag {
  text-align: left;
  padding: 5px 9px;
  background: #fcc117;
  color: #000;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
}
.news_content h3 {
  font-size: 20px;
  text-align: left;
  padding: 1px 10px;
  color: #fbb91a;
  margin: 10px 0;
  font-weight: bold;
}
.blog_inner .news_content h3 {
  margin: 0 0 20px;
}
.blog_category {
  margin-top: 20px;
}
.news_content p {
  padding: 1px 9px;
  color: #fff;
}
.news_content .first_span {
  margin-right: 15px;
  font-weight: bold;
}
.blogListTitleBox.blogDetailTitle h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #fbb91a !important;
  line-height: 36px;
}
.blogListTitleBox h6 {
  color: #fab91a;
  font-weight: 500;
  font-size: 14px;
}
.blogListDescription.blogFullDetail p {
  color: #fff !important;
  margin-bottom: 30px;
  font-size: 14px;
}
.blogListDescription.blogFullDetail h2 {
  /* font-weight: 600 !important; */
  font-size: 18px !important;
  margin: 30px 0 15px !important;
  line-height: 28px !important;
  color: #fbb91a !important;
}
.blogListDescription.blogFullDetail ol li,
.blogListDescription.blogFullDetail ul li {
  color: #fff !important;
  margin-bottom: 18px;
  line-height: 30px;
  display: flex;
}
.blogListDescription.blogFullDetail ol li::before,
.blogListDescription.blogFullDetail ul li::before {
  content: "\f105";
  font-weight: 700;
  font-family: "Font Awesome 5 Free" !important;
  font-size: 16px;
  margin-right: 15px;
}
.blogListDescription h3 {
  font-size: 18px !important;
  margin: 30px 0 15px !important;
  line-height: 28px !important;
  color: #fbb91a !important;
}
.bloglistImg {
  margin-bottom: 25px;
  position: relative;
}
.blogPostDate {
  background-color: #fbb91a;
  border-radius: 0.25rem;
  padding: 12px 24px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #1d1d1d;
}
.blog_category span {
  color: #fbb91a;
}
.main_title {
  position: relative;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 30px;
  padding-left: 25px;
  display: inline-block;
}

.support_new_desion .card-header {
  margin: 0;
  padding: 10px;
  border: 1px solid #2f2f2f !important;
  border-radius: 5px !important;
  background: #090909;
}

.support_new_desion h5 button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.support_new_desion {
	border: 1px solid #2f2f2f;
	background: transparent;
	border: none;
}
.support_new_desion .stHeadText.subjectWidth {
  min-width: 300px;
  text-align: left;
}
.support_new_desion .stHeadText.ticketIdWidth {
  min-width: 200px;
  text-align: left;
}
.metaChatDetails {
  color: #fff;
}
.ticketDetails {
  color: #fff;
}
.ticketUserDetails p {
  color: #fff;
}
.support_new_desion .card-body {
  background: rgb(9 9 9);
  border: 1px solid #2f2f2f;
}
.content_page h2 {
  color: #fff;
  font-size: 28px;
  font-family: "Barlow", sans-serif;
}
.content_page h3 {
  color: #fff;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
}
.content_page p,
.content_page ul li {
  color: #bcbcbc;
  font-size: 14px;
}
.pdin_top_pos {
  padding-top: 84px;
  /* color: #000; */
}
.about_first_section {
  margin-top: 80px;
}
.new_about_section {
  background: url(../src/assets/images/aboutbanner.webp) no-repeat scroll 0 0;
  background-size: cover;
  background-position-y: 84px;
}
.about_head_title {
  padding: 47px 10px;
}

.about_head_title h1 {
  margin: 0;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  color: #ffd703;
}
.about_head_title h3 {
  margin: 0;
  text-align: center;
  margin: 23px 0px;
  font-size: 33px;
  font-weight: bold;
  color: #f4f4f4;
}
.about_head_title p {
  margin: 0;
  text-align: center;
  color: #bcbcbc;
  font-size: 18px;
  margin: 5px 0px;
}

.our_story_section {
  padding: 70px 0px;
}

.our_story_section h4 {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  margin-bottom: 20px;
}
.our_story_section p {
  color: #bcbcbc;
  /* font-weight: 400; */
}
.img_right_about {
  width: 439px;
  margin: 48px auto;
  display: block;
}

.about_section_3 {
  background: url(../src/assets/images/trad_chart.webp) no-repeat scroll 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-position: center;
  border: 4px solid #ffd700;

  border-left: 0;
  border-right: 0;
}
.text_number_section h1 {
  text-align: center;
  font-weight: bold;
  font-size: 60px;
  color: #ffd700;
}

.text_number_section p {
  margin: 0;
  text-align: center;
}
.flex_slider_name {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}
.flex_slider_name h3 {
  margin: 0;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border: 2px solid #292929;
  border-radius: 32px;
  margin-left: 23px;
  position: relative;
  font-size: 18px;
  color: #292929;
}
.flex_slider_name h3:hover {
  border-color: #ffd700;
  background: #ffd700;
  color: #000;
  cursor: pointer;
}
.flex_slider_name h3::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 46px;
  background: #323232;
  bottom: -51px;
}
.flex_slider_name .none_border::before {
  display: none;
}

.flex-_end_cl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-_end_cl span {
  color: #bcbcbc;
}

.flex-_end_cl h5 {
  color: #ffd700;
  font-size: 21px;
  font-weight: 600;
}
.flex-_end_cl p {
  color: #bcbcbc;
  text-align: left;
}
.flex_slider_name p {
  color: #fff;
  font-weight: 600;
}
.flex_slider_name span {
  width: 100%;
  text-align: right;
  display: block;
  font-weight: 600;
  color: #bcbcbc;
}

.roadmap_section .slick-track {
  height: auto !important;
}

.doctor_box {
  position: relative;
  z-index: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 1rem;
  padding-bottom: 15px;
}

.doctor_box_img {
  position: relative;
  border-radius: 1rem;
}

.doctor_box_img > img {
  width: 100%;
  border-radius: 1rem;
}

.doctor_box_social {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 0 28px 30px;
  list-style: none;
  text-align: left;
  overflow: hidden;
  border-radius: 10px;
}

.doctor_box_social:before {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}

.doctor_box_social > li {
  position: relative;
  left: -70px;
  -webkit-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}

.doctor_box_social > li:not(:last-child) {
  margin-bottom: 10px;
}

.doctor_box_social > li > a {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-decoration: none !important;
}

.doctor_box_social > li > a > i {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 17px;
  font-style: normal;
  line-height: 36px;
  text-align: center;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}
.doctor_box_social > li > a > i.fab.fa-linkedin-in {
  color: #0077b5;
}
.doctor_box_social > li > a > i.fab.fa-facebook-f {
  color: #3b5998;
}
.doctor_box_social > li > a > i.fab.fa-twitter {
  color: #1da1f2;
}
.doctor_box_social > li > a > i.fab.fa-youtube {
  color: #ff0000;
}
.doctor_box_social > li > a > span {
  position: absolute;
  top: 2px;
  left: 0;
  padding: 0 14px 0 25px;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  line-height: 32px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
}
.doctor_box_social > li > a:hover > span.linkedInBg {
  background-color: #0077b5;
}
.doctor_box_social > li > a:hover > span.facebookBg {
  background-color: #3b5998;
}
.doctor_box_social > li > a:hover > span.twitterBg {
  background-color: #1da1f2;
}
.doctor_box_social > li > a:hover > span.youtubeBg {
  background-color: #ff0000;
}
.doctor_box_social > li > a:hover > i {
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.doctor_box_social > li > a:hover > span {
  opacity: 1;
  visibility: visible;
  left: 50%;
  border-radius: 0 50px 50px 0;
}

.doctor_box:hover .doctor_box_social {
  top: 0;
  opacity: 1;
}

.doctor_box:hover .doctor_box_social:before {
  top: 0;
  opacity: 1;
}

.doctor_box:hover .doctor_box_social > li {
  left: 0;
}

.doctor_box:hover .doctor_box_social > li:nth-child(2) {
  -webkit-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
}

.doctor_box:hover .doctor_box_social > li:nth-child(3) {
  -webkit-transition-delay: 750ms;
  -o-transition-delay: 750ms;
  transition-delay: 750ms;
}

.doctor_box:hover .doctor_box_social > li:nth-child(4) {
  -webkit-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.doctor_box_icon {
  position: relative;
  z-index: 0;
}

.doctor_box_icon > i {
  position: absolute;
  top: -21px;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 18px;
  line-height: 38px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.doctor_box_icon > i {
  color: #fff;
  background: linear-gradient(
    77deg,
    rgba(21, 59, 232, 1) 0%,
    rgba(39, 168, 249, 1) 46%,
    rgba(41, 178, 251, 1) 100%
  );
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.doctor_box:hover .doctor_box_icon > i {
  color: #1b5cee;
  background: #fff;
  border-color: rgba(21, 59, 232, 1);
}
.doctor_box_title {
  font-size: 22px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 600;
  margin-bottom: 0;
}

.doctor_box_role {
  margin: 0;
  font-size: 14px;
}

.doctor_box_link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.doctor_box_social::before {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffd801 100%
  );
}
.doctor_box:hover {
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.07);
}
.slick-current .flex_slider_name h3 {
  border-color: #ffd700;
  background: #ffd700;
  color: #000;
  cursor: pointer;
}
.height_121 {
  /* height: 100vh; */
  display: grid;
  place-items: center;
}
.height_121 h1 {
  font-size: 96px;
  color: #e1c401;
}
.img_with {
  width: 60%;
}
.coming_btn {
  background: #ffd701;
  color: #000 !important;
  padding: 9px 20px;
  border-radius: 5px;
  font-weight: 600 !important;
  text-decoration: none !important;
  margin-top: 13px;
}
.blog_share_list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.blog_share_list li {
  margin-right: 10px;
}
.blog_share_list li a {
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  background: #090909;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.2s all;
  color: #ffd804;
}
.blog_share_list li a:hover {
  background: #ffd804;
  color: #090909;
}
.blog_author {
  width: 160px;
  /* border-radius: 50%; */
}
.buy_crypto_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px 15px;
}
.buy_crypto_line img.arrow_icon {
  width: 24px;
}
.buy_crypto_line p {
  margin-bottom: 0;
  color: #fff;
}
.buy_crypto_line:hover p {
  color: #fbb91a;
}
.buy_crypto_line > div img {
  width: 36px;
}
.buy_crypto_line:hover {
  background: #090909;
}

.form_fonr_ad li {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_fonr_ad a {
  width: 100%;
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 15px;
  background: #42423f;
  margin: 14px 3px 20px;
  border-radius: 6px;
  color: #fff;
  transition: 0.5s;
  font-weight: 600;
}

.form_fonr_ad a.active,
.form_fonr_ad a:hover {
  background: #ffdf36;
  border: 0 solid #ffdf36;
  color: #090909;
}
.MuiSlider-root {
  color: #ffd808 !important;
  cursor: pointer;
}

.MuiSlider-markLabelActive {
  color: rgb(255, 216, 8) !important;
}
.MuiSlider-markLabel {
  color: #fff !important;
}
.PrivateValueLabel-label-44 {
  color: #000 !important;
}

.modal-content{
  background: #1d1d1d;
}
.modal-title{
  color: #ffd808;
}
.stakeDetailsRight {
  background: #1a1919;
  padding: 15px;
  border: 1px solid #282828;
  border-radius: 7px;
}
.lockedStakeBtn {
  background: #070707;
  margin-right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #2f2f2f;
}
.lockedStakeBtn:hover,
.lockedStakeBtn.active {
  background: #ffd808;
  color: #1a1919;
  border: 1px solid #ffd808;
}
.stakeDetailsRight h4{
  color: #ffd808;
}

.lockedStakeBtn{
  background: #070707;
  margin-right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #2f2f2f;
}
.stakePopDetails .flexText h4{
  color: #ffd808;
}
.MuiMenu-list{
  background: #141414;
}
.tradeFulltabbedTable .nav-tabs{
  border-color: #242424 !important;
}
.tradeFulltabbedTable .nav-tabs .nav-link{
  border-bottom: 2px solid transparent;
}
.orderBook .tradeFulltabbedTable .nav-tabs .nav-link{
  margin-bottom: -2px;
}
.accordion > .card > .card-header {
	border-radius: 0;
	margin-bottom: -1px;
	margin: 0;
	padding: 0;
}

.error-message {
	color: red !important;
  font-size: 14px;
}
.stakePopDetails  .input-group p{
  position: absolute;
bottom: -31px;
}
.btn.btn-primary:active:focus{
  color: #000 !important;
}
.button_bott_trader {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffd808;
	border: 1px solid #ffd808;
	border-radius: 5px;
}
.button_bott_trader > span {
	/* width: 100%; */
	display: flex;
	justify-content: center;
  align-items: center;
  color: #000;
}
.button_bott_trade .border_s{
 border: none !important;
}
.historyCryptoTable th {
	color: #fff;
	white-space: nowrap;
	padding: 7px 12px;
	padding-left: 0;
}
.historyCryptoTable td {
  padding: 7px 11px;
  padding-left: 11px;
padding-left: 0;
}

.faqCont  .accordion > .card {
	box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  background: #090909;
border: 1px solid #2f2f2f;
}
.btn-primary:not(:disabled):not(.disabled).active {
  color: #000;
background-color: #d2b202;
border-color: #ffd808;
}


.userTypeCard.verifiedUser  h4 {
	color: #000;
}
.userTypeCard.verifiedUser  li {
	color: #999;
}
.userTypeCard.unVerifiedUser  h4 {
	color: #000;
}
.userTypeCard.unVerifiedUser  h6 {
	color: #000;
}
.userTypeCard.unVerifiedUser  li {
	color: #999;
}
.spot_ad_banner .mt-3 {
  margin-top: 0 !important;
}
.spot_ad_banner div {
  height: 100%;
  display: flex;
}
.spot_ad_banner {
  display: flex;
  height: 100%;
}
.spot_ad_banner img {
  width: 100%;
  height: 100%;
}
.spot_ad_banner_panel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.deopdown_menu {
  max-height: 320px;
  overflow-y: scroll;
}
.thanks_msg_box_panel {
  display: flex;
  align-items: center;
  background: url("../src/assets/images/login.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.thanks_msg_box {
  border: 5px solid #2f2f2f;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 70%);
  border-radius: 15px;
  background: rgb(17 17 17 / 70%);
  padding: 50px;
}
.profile_img_holder img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
}
.profile_pic_upload input[type="file"] {
  z-index: 1;
  position: relative;
  height: 40px;
  width: 40px;
  right: 32px;
  top: 40px;
  left: unset;
}
.profile_pic_upload .btn-primary {
  position: absolute;
  top: 100px;
  left: 70px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  min-width: auto;
  font-size: 16px;
  line-height: 17px;  
}
form.contact_form.disabledForm {
  cursor: not-allowed;
  pointer-events: none;
}
form.contact_form.disabledForm::before {
  content: "";
  background: rgb(9 9 9 / 60%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.pagination .page-item .home_menu_btn {
  color: #ffd804 !important;
}
.pagination .page-item.active .home_menu_btn {
  color: #000 !important;
  border-radius: 50%;
}
.blog_share_list .a2a_svg {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog_share_list .a2a_svg svg {
  width: 28px;
}
#wallet_menu .MuiPaper-root {
  top: 65px !important;
}
.afterLoginHeader header.MuiPaper-root {
  padding-right: 0 !important;
}
#wallet_menu .MuiPaper-root ul li a {
  color: #cbcbcb !important;
}
#wallet_menu .MuiPaper-root ul li a:hover {
  color: #ffd804 !important;
}
.dashboard_login li button:hover {
  color: #ffd804 !important;
}
.supporAttachment {
  display: flex;
  justify-content: space-between;
}
.supporAttachment i{
  margin-top: 15px;
}
/* dark mode css */
body.light_theme,
body.light_theme ul li,
body.light_theme ol li,
body.light_theme p  {
  color: #2a2a2a !important;
}
body.light_theme .nav_menu_item_container p {
  color: #ffffff !important;
}
.light_theme  .blog_inner .news_section {
	border-bottom: 0px solid rgb(251, 185, 26);
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
}
.light_theme .supporAttachment i{
  color: #000 !important;
}
.light_theme .stakingHistoryTable .rdt_Pagination {
	color: #000;
}
.light_theme #pagination-first-page > svg {
	fill: #000 !important;
}
.light_theme #pagination-previous-page > svg {
	fill: #000 !important;
}
.light_theme #pagination-next-page > svg {
	fill: #000 !important;
}
.light_theme #pagination-last-page > svg {
	fill: #000 !important;
}

.light_theme  .confirm_body h2 {
  color: #000;
}
.light_theme  .blogSidebarTitle {
	color: #000;
	background-color: #eee;
}
.light_theme .news_section {
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
}
.light_theme .dashboard_login a {
  color: #cbcbcb;
}
.light_theme .dashboard_login a:hover {
  color: #ffd804;
}
.light_theme .blogSideBarLinks li a {
	color: #575757;
}
.light_theme  .main_title {
	color: #000;
}
.light_theme  .blogWhiteCard {
	background: #fff;
	border: 1px solid #dbdbdb;
}
.light_theme  .blogListDescription.blogFullDetail p {
	color: #000 !important;

}
.light_theme  .faqCont .accordion > .card {
	border-radius: 5px !important;
	background: #fff;
	border: 1px solid #ccc;
}
.light_theme  .homeAccordian .card .card-header .btn {
	color: #000;
}
.light_theme .homeAccordian .card .card-body > p {
	color: #000;
}
.faqCont h3 {
	color: #999;
}
.light_theme .tradeFulltabbedTable .nav-tabs{
  border-color: #eee !important;
}
.light_theme .daysLocked {
	border: 1px solid #000;

}
.light_theme  .button_bott_trader {
	background: #eee;
	border: 1px solid #d3d4d7;
	border-radius: 5px;
}
.light_theme .MuiMenu-list{
  background: #ffffff;
}
.light_theme .stakeDetailsList span {
  color: #000;
}
.light_theme .page_wrap {
  background: #eeeeee !important;
}
.light_theme #wallet_menu .MuiPaper-root ul li a {
  color: #333 !important;
  text-decoration: none;
}
.light_theme .page_wrap header {
  border-bottom: 0px solid #1e1e1e;
  background-color: #686666 !important;
  border-radius: 0;
}
.light_theme .settingsNote p a {
  color: #000;
}
.light_theme .stakeDetailsRight{
  background: #fdfdfd;
  border-color: #eeeeee;
}
.light_theme .settingsNote p a:hover {
  text-decoration: underline;
}
.light_theme .menu_main_navbar li a.home_menu_btn {
  background: #ffd808;
  border: 1px solid #ffd808;
  color: #000 !important;
}
.light_theme .menu_main_navbar li a.home_menu_btn:hover {
  border: 1px solid #000;
  color: #000 !important;
  background: transparent;
}
.light_theme .menu_main_navbar li a {
  color: #fff;
}
.light_theme .banner_container h1 {
  color: #272727;
}
.light_theme .banner_container h2 {
  color: #fff;
}
.light_theme .heading-subtitle {
  color: #fff;
}
.light_theme .heading-text {
  font-size: 18px;
  color: #787878;
}
.light_theme .common-table {
  border: 1px solid #282b33;
}
.light_theme .common-table thead {
  border-bottom: 1px solid #282b33;
}
.light_theme .common-table tr {
  border-bottom: 1px solid #282b33;
}
.light_theme .common-table th {
  color: #787878;
}
.light_theme .common-table td {
  color: #b4b4b4;
}
.light_theme .home_menu_btn {
  color: #fff !important;
}
.light_theme .pagination .home_menu_btn {
  color: #2a2a2a !important;
}
.light_theme .bg-gray {
  background: #181a20;
}
.light_theme .home_calculator h5 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.light_theme .home_calculator p {
  color: #555;
}
.light_theme .feature_text img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
.light_theme .start_trading img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
.light_theme .profile_dash li {
  list-style: none;
  color: #979595;
}
.light_theme .dash_title {
  color: #000;
}
.light_theme .dashboard_login li {
  border-left: 0px solid #282b33;
}
.light_theme .dashboard_box {
  border: 1px solid #d2d2d2;
  background: #fff;
}
.light_theme .dash_subtitle {
  color: #000;
  border-bottom: 1px solid #d7d7d7;
}
.light_theme .modal-content{
  background: #fff;
}
.light_theme .stakingSubscription ul li {
  border-bottom: 1px solid #eeeeee;
}
.light_theme .balance_details_left h2 {
  color: #686666;
}
.light_theme .dudRight ul li label {
  color: #000;
}
.light_theme .dudRight ul li span {
  color: #808080;
}
.light_theme .balance_details_left h2 span {
  font-size: 20px;
  color: #686666;
}
.light_theme .tabelDashBalance td span a {
  color: #272727;
}
.light_theme .tabelDashBalance td span a:hover {
  color: #272727;
}
.light_theme .dashHistoryTable tr td {
  color: #5e5959;
}
.light_theme .dashHistoryTable tr th {
  color: #000;
}
.light_theme .textStatusOrange {
  color: #ccad06 !important;
}

.light_theme .balance_details_left h4 {
  color: #686666;
}
.light_theme .peer2peer_contant_first h1 {
  color: #000;
}
.light_theme .chartLabel ul li label {
  color: #a5a5a6;
}
.light_theme .chartLabel ul li span {
  color: #fff;
}
.light_theme .csLeft h3 {
  color: #a5a5a6;
}
.light_theme .csLeft p {
  color: #787878;
}
.light_theme .csRight h4 {
	color: #000;
}
.light_theme button.btn.btn-outline {
  color: #000;
}
.light_theme .launchpad_token_single {
  background: #ffff;
  border: 1px solid #d7d7d7;
}
.light_theme .copy_trading_top_panel {
  background: #fff;
  border: 1px solid #c6c4c4;
}
.light_theme .copy_trading_top_panel .nav-pills .nav-item .nav-link {
  color: #000;
}
.light_theme span.timer_time {
  color: #000;
}
.light_theme .launchpad_token_single > div p:first-child {
  color: #484848;
}
.light_theme .whiteBoxLaunchpad {
  background: #ffff;
  border: 1px solid #e1dbdb;
}
.light_theme .launchPadCoinDetails h4 {
  color: #000;
  border: 2px solid #ffd801;
}
.light_theme .launchPadCoinDetails .projectIntroduction li {
  color: #000;
}
.light_theme .projectIntroduction li span {
  color: #797676;
}
.light_theme .projectIntroduction li {
  color: #000;
}
.light_theme .whiteShadowBox {
  background: #eee;
  border: 1px solid #ddd;
}
.light_theme .whiteShadowBox span.input-group-text {
  background-color: transparent;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
}
.light_theme .whiteShadowBox .table thead th {
  border-bottom: 0;
  color: #000;
}
.light_theme .whiteShadowBox .table th,
.light_theme .whiteShadowBox .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  color: #000;
}
.light_theme .whiteShadowBox h3 {
  color: #000;
}
.light_theme .socialMediaCoinDetails ul li a {
  color: #686666;
}
.light_theme .socialMediaCoinDetails ul li a:hover {
  color: #686666;
  border: 1px solid #686666;
}
.light_theme .choose_coin_list li a.active {
  color: #000;
  border-bottom: 2px solid #000;
}
.light_theme .socialMediaCoinDetails h3 {
  border-top: 1px solid #e1dada;
  border-bottom: 1px solid #d9d9d9;
  color: #000;
}
.light_theme .projectIntroduction h3 {
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  color: #000;
}
.light_theme .stakingCurrencyList .table-striped tbody tr:nth-of-type(2n + 1) {
  background: #181a20;
}
.light_theme .submitChat a {
  color: #000;
  text-decoration: underline;
}
.light_theme .submitChat a:hover {
  color: #000;
  text-decoration: underline;
}
.rdt_TableHeadRow {
  background-color: #090909 !important;
}
.rdt_TableCol_Sortable {
  color: #d7d7d7 !important;
  font-weight: 600;
  font-size: 13px;
}
.rdt_TableBody .rdt_TableRow {
  background-color: #090909 !important;
}
.rdt_TableCell {
  color: #ffffff;
}
.rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: #101010 !important;
}
.table-striped tbody tr:nth-of-type(2n) {
  background-color: #101010 !important;
}
.light_theme .table-striped tbody tr:nth-of-type(2n) {
  background-color: #1b1d23;
}
.light_theme .stakingCurrencyList tr td {
  color: #000;
}
.light_theme .currencyPrice {
  color: #000;
}
.light_theme .rdt_TableCol_Sortable {
  color: #222 !important;
}
.light_theme .static_container h1 {
  color: #000;
}
.light_theme .currencyPrice small {
  font-size: 18px;
  color: #686666;
}
.light_theme .contact_form .form-control {
  background: #eee !important;
  border: 1px solid #d0d0d0 !important;
  color: #000;
}
.light_theme .input-group .btnType1 {
  background: #eee !important;
  color: #000 !important;
  border: 1px solid #d3d4d7;
}
.light_theme .contact_form label {
  margin-bottom: 10px;
  color: #535353;
}
.light_theme .settingsNote h6 {
  color: #000;
  font-weight: 600;
}
.light_theme .settingsNote li {
  color: #5b5b5b;
}
.light_theme .eJTwzY {
  background-color: #191b21 !important;
  /* border-bottom-style: solid; */
}
.light_theme .FMWkK {
  font-size: 13px;
  font-weight: 500;
  color: #787878;
}
.light_theme .rdt_TableBody .rdt_TableRow:nth-child(2n + 1) {
  background: #181a20 !important;
}
.light_theme .doEGfX {
  color: #fff !important;
  background-color: #12161c !important;
}
.light_theme .settingsSelect .MuiInput-underline {
  background: #eee;
  border: 1px solid #bfbfbf;
  color: #787878;
}
.light_theme .MuiSelect-icon {
  color: #787878;
}
.light_theme .refresh_color_78 {
  color: #000;
}
.light_theme .settingsSelect .MuiInput-underline .MuiSelect-icon {
  color: #000;
}
.light_theme .newUsersFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
}
.light_theme .supportTicketList {
  background: #eee;
  border-radius: 6px;
  border: 1px solid #d0d0d0;
}
.light_theme .supportTicketList ul li {
  color: #000;
}
.light_theme .supportTicketList ul li a {
  color: #b4b4b4;
}
.light_theme .support_new_desion .card-header {
	border: 1px solid #e6e6e6 !important;
	background: #fff;
	padding: 0;
	margin: 0;
	border-radius: 5px !important;
	overflow: hidden;
}
.light_theme .stHeadText small {
  color: #686666;
}

.light_theme .support_new_desion {
  border: 1px solid #e6e6e6;
}
.light_theme .supporTicketAccordian #accordion .card {
  background: #1b1d23;
  border: 1px solid #29323f;
}
.light_theme .stHeadText {
  color: #000;
}
.light_theme .support_new_desion .card-body {
  background: #fff;
  border: 1px solid #e8dfdf;
}
.light_theme .ticketDetails {
  color: #000;
}
.light_theme .supporTicketAccordian #accordion .card-body {
  border-top: 1px solid #29323f;
}
.light_theme .metaChatDetails {
	border-bottom: 1px solid #e8dfdf;
}
.light_theme .supporTicketAccordian #accordion .card-body p {
  color: #fff;
}
.light_theme .login_container form {
  box-shadow: 0 0 10px #c6c6c6;
  background: #fff;
}
.light_theme .login_container .form-control {
  color: #000;
  border: 1px solid #d4d5d7;
  box-shadow: none;
  background: #e6e6e6;
}
.light_theme .form-control,
.is-focused .form-control {
  background-image: none;
}
.light_theme .profileDetailView h4 {
  color: #000;
  border-bottom: 1px solid #e1e1e1;
}
.light_theme .disabledForm {
  position: relative;
  padding: 11px;
}
.light_theme p {
  color: #515151;
}
.light_theme .profileDetailView h4 small {
  color: #adadad;
}
.light_theme .profileDetailView ul li span {
  color: #000 !important;
}
.light_theme .dash_table th,
.light_theme .dash_table td {
  color: #555;
}
.light_theme .input-group-append .btnType1 {
  color: #686666 !important;
}
.light_theme .profileDetailView .settingsNote ul li {
  color: #000;
}
.light_theme .stakingHistoryTable .rdt_TableHeadRow {
  background-color: #eee !important;
}

.light_theme .tabelDashBalance td {
  color: #333;
}
.light_theme .dudRight ul li p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.light_theme .nav-tabs.primaryNav .nav-link.active {
	color: #000;
	border-bottom: 0px solid #000;
}
.MuiSelect-select.MuiSelect-select {
	padding-right: 0;
}
.light_theme .nav-tabs.primaryNav .nav-link {
  color: #686666;
}
.light_theme .nav-tabs.primaryNav {
  border-bottom: 1px solid #eee;
}
.light_theme .recentad_section {
  background: #eee;
  border: 1px solid #dadbdf;
}
.light_theme .dashSecurityTable tr td:first-child {
  color: #000;
  font-weight: 600;
}
.light_theme .dashAlertNotification ul li label {
  color: #6f6f6f;
}
.light_theme .notificationDropdown ul li h5 {
  color: #999;
}
.light_theme .notificationDropdown ul li {
  border-bottom: 1px solid #dfe0e6;
}
.light_theme .dashSecurityTable tr td a {
  color: #000;
}
.light_theme .notificationDropdown {
  background: #fff;
  box-shadow: 0px 0px 8px #000;
}
.light_theme .profileDetailView .alert.alert-info {
  color: #6a6969;
  background: #eee;
}
.light_theme .profileDetailView .alert.alert-info a {
  color: #000;
}
.light_theme .dashAlertNotification ul li {
  border-bottom: 1px solid #e3e3e3;
}
.light_theme .kycTopCard {
	border-bottom: 1px solid #d2d2d2;
}
.light_theme .userTypeCard.verifiedUser {
	background: #eaffeb;
	border: 1px solid #51b155;
}
.light_theme .userTypeCard.unVerifiedUser {
	background: #ffeaea;
	border-color: #b53939;
}
.light_theme .userTypeCard {
	border: 1px solid #d2d2d2;
	background: #fff;
}
.light_theme .utcTop h4 {
	color: #000;
}
.light_theme .userTypeCard ul li {
	color: #757575;
}
.light_theme .userTypeCard h6 {
	color: #000;
}
.light_theme .react-grid-item:not(.react-grid-placeholder) {
  background: #fff !important;
  border: 1px solid #dbdbdb !important;
}

.light_theme .custom-file-label {
	background: #f3f3f3 !important;
	border: 1px solid #eaeaea !important;
}
.light_theme .tableHead h4 {
  color: #000;
}
.light_theme .tradeFulltabbedTable .nav-tabs .nav-link.active {
  border-bottom: 2px solid #ffd808 !important;
	color: #000 !important;
}
.light_theme .tradeFulltabbedTable .nav-tabs .nav-link:hover {
	border-bottom: 2px solid #ffd808 !important;
	color: #000 !important;
}
.light_theme .tradeFulltabbedTable .nav-tabs {
  border-bottom: 1px solid #eeeeee;
}
.light_theme .poList li span {
  color: #fff;
}
.light_theme .MuiSlider-thumb {
  background-color: #686666;
}
.light_theme .MuiSlider-root {
  color: #787878;
}
.light_theme .MuiSlider-track {
  color: #ffd808;
}
.light_theme .MuiSlider-markLabel {
  color: #787878 !important;
}
.light_theme .MuiSlider-markLabelActive {
  color: #6c6969 !important;
}
.light_theme .tradeTableBodyRow.highLight {
  background: #eee !important;
}
.light_theme .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #090909;
}
.light_theme .selectPair.dropdown-toggle {
  color: #000;
}
.light_theme .derivativeTrade .selectPair small {
  color: #787878;
}
.light_theme .headerOverviewStatus h5 {
  color: #000;
}
.light_theme .tableHead {
  border-bottom: 1px solid #eeeeee;
}
.light_theme .radioGroup .MuiTypography-root {
  color: #787878;
}
.light_theme .poList {
  border-bottom: 1px solid #282b33;
}
.light_theme .flexTitle h3 {
  color: #fff;
  font-size: 20px;
}
.light_theme .kycCardStatus ul li span {
  color: #fff;
}
.light_theme .kycCardStatus ul li label {
  color: #787878;
}
.light_theme .page_wrap header {
  border-bottom: 0px solid #1e1e1e;
  background-color: #686666 !important;
}

.light_theme .page_wrap header .logo_div img {
  filter: none;
}

.light_theme .bg_light-gray {
  background: #eee !important;
}
.light_theme .bg_light-white {
  background: #fff !important;
}
.light_theme .new_home_section_o p {
  color: #515151;
}
.light_theme .banner_container {
  background: #fff;
}
.light_theme .slider_contet h3 {
  color: #272727;
}
.light_theme .home_calculator h2 {
  color: #272727;
}
.light_theme .card_digital_currnsy h3 {
  color: #272727;
}
.card_digital_currnsy {
  margin: 51px 16px;
}
.light_theme .clas_heasdin h1 {
  color: #272727;
}
.light_theme .nav_icin_tab a {
  color: #000;
}
.light_theme .nav_icin_tab a.active {
  background: #ffd808;
  opacity: 1;
  color: #000;
}
.light_theme .tabel_inside_content .table-dark > thead > tr > th {
  color: #000;
  background: #fff;
}
.light_theme .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #fff !important;
}
.light_theme .tabel_inside_content .table th,
.light_theme .tabel_inside_content .table td {
  color: #404040;
}

.light_theme .tabel_inside_content .table-striped tbody tr:nth-of-type(2n) {
  background-color: #fff !important;
}
.light_theme .tabel_tab_new .nav-tabs {
  background: #eee;
}
.light_theme .why_content_right h3 {
  color: #272727;
}
.light_theme .card_section_7 h3 {
  color: #000;
}
.light_theme .icon_ing {
  background: #fff;
}
.light_theme .token_new_card {
  background: #fff;
}
.light_theme .token_new_card p {
  color: #000 !important;
}
.light_theme .token_new_card h2 {
  color: #4d4d4d;
}
.light_theme .token_new_card:hover {
  box-shadow: 0px 9px 7px #868383;
  cursor: pointer;
}
.light_theme .token_new_card span {
  color: #000;
}
.light_theme .orince_colo {
  color: #000 !important;
}
.light_theme button.primary_btn:hover {
  background: transparent;
  border: 1px solid #000;
  color: #000 !important;
}
.light_theme button.primary_btn:hover span {
  color: #000;
}
.light_theme .content_get_start h3 {
  color: #000;
}
.light_theme .card_digital_currnsy {
  border: 1px solid #d5d5d5;
  background: #fff;
}
.light_theme .card_digital_currnsy img {
  opacity: 0.5;
}
.light_theme .card_digital_currnsy:hover {
	filter: drop-shadow(0px 6px 3px #4d493912);
	background: #ffd808;
}

.light_theme .login_container form {
  border: 5px solid #e6e6e6;
}
.light_theme .login_container h2 {
  font-size: 24px;
  color: #000;
}
.light_theme .login_container h6 {
  color: #797777;
  border-bottom: 2px solid #e6e6e6;
}
.light_theme .login_container button:hover {
  background: transparent;
  border: 2px solid #000;
  color: #000;
}
.light_theme .login_container label {
  font-size: 16px;
  color: #8e8e8e;
}
body.light_theme .error-message {
	color: red !important;
}
.light_theme .login_container a {
  color: #000;
}
.light_theme .form_fonr_ad a {
  background: #e6e6e6;
}

.light_theme .form_fonr_ad a.active,
.light_theme .form_fonr_ad a:hover {
  background: #ffdf36;
  border: 0 solid #ffdf36;
  color: #090909;
}
.light_theme .input-group.regGroupInput {
  border: 1px solid #d4d5d7;
  border-radius: 15px;
  overflow: hidden;
}
.light_theme .regGroupInput .input-group-append {
  background: #e6e6e6;
}

.light_theme .rdt_TableBody .rdt_TableRow:nth-child(2n + 1) {
  background: #fff !important;
}
.light_theme .rdt_TableCell {
  color: #000;
}
.light_theme .rdt_TableBody .rdt_TableRow {
  background-color: #eee !important;
}
.light_theme .launchpadCoinName h3 {
  color: #000;
}
.light_theme body {
  background: #eeeeee;
}
.light_theme .logo_bg_shadow {
  background: none;
}
.light_theme .modal-dialog .modal-content {
  background: #fff;
  border: 0px solid #959599;
}
.light_theme .color_gol-sd {
  color: #424242;
}
.light_theme .historyCryptoTable th {
  color: #000;
}
.light_theme .historyCryptoTable td {
  color: #999;
}
.light_theme .table-striped tbody tr:nth-of-type(2n) {
  background-color: #eee !important;
}
.modal {
  z-index: 5555;
}
.light_theme .form-control:disabled,
.light_theme .form-control[disabled] {
  background: #dbd9d9 !important;
  padding: 0px 6px;
}
.light_theme .model_detail_text span {
  font-size: 16px;
  color: #5b5b5b;
}
.light_theme .button2 {
  background: transparent !important;
  border: 1px solid #000 !important;
  color: #000;
}
.light_theme .bitcoin_value_box {
  background: #eee;
  border: 1px solid #d9d2d2;
}
.light_theme .bitcoin_value_box h3 {
  color: #000;
}
.light_theme .inpor_inside_buttons {
  border: 2px solid #c8c7c7;
  background: #eee;
  color: #6c6c6c;
}
.light_theme .color_whit_7 {
  color: #000 !important;
}
.light_theme .qr_code_ {
  color: #000;
}
.light_theme .qr_code_ a {
  color: #000;
}
.light_theme .qr_code_ a:hover {
  text-decoration: underline;
}
.light_theme .chat_content_center h4 {
  color: #000;
}

.light_theme .chat_content_center {
  border-bottom: 1px solid #c4c4c4;
}
.light_theme .chat_conte_po h3 {
  color: #000;
}
.light_theme .recent_title h3 {
	color: #000;
}
.button_section_po_chat .buttonOrangeBorder {
  border: 1px solid #000;
  color: #000;
  margin-top: 10px;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: 600;
}
.new_timer > div {
  color: #000;
}
.light_theme .chat_box_dark {
  border: 1px solid #dbd8d8;
  background: #eee;
}

.chat_message_section {
  padding-top: 20px;
}
.message_section span {
  position: static;
}
.new_caht_span_deati {
  width: 100%;
  display: block;
}
.new_caht_span_deati span {
  margin: 0 6px;
}
.message_section p {
  font-size: 13px;
  min-width: 80px;
  float: right;
}
.light_theme .message_section p {
  margin: 0;
  padding: 10px 30px;
  color: #fff;
  background: #fff;
  font-size: 13px;
  color: #000 !important;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
}
.light_theme .footer_chat .form-control {
  background: #fff;
  border: 1px solid #b0b0b0;
  color: #000;
}
.light_theme .footer_chat .fa-paper-plane {
  color: #686666;
}
.light_theme .button_section_chat .fa-paperclip {
  color: #686666;
}
.light_theme .inner_pageheader h2 {
  color: #000;
}
.light_theme .main_title.light {
  color: #000;
}
.light_theme .content_page h3 {
  color: #000;
  margin-top: 30px;
}
.light_theme .content_page ul li {
  color: #000;
  font-size: 14px;
}
.light_theme .new_about_section {
  background-position-y: 79px;
}
.light_theme .our_story_section h4 {
  color: #272727;
}
.light_theme .about_section_2 {
  background: #fff;
}
.light_theme .black_ae {
  background: #eee;
}
.light_theme .roadmap_section.section {
  background: #fff;
}
.light_theme .roadmap_section.section .main_title {
  color: #000;
}
.light_theme .flex-_end_cl h5 {
  color: #272727;
}
.text-danger {
  color: #f44336 !important;
  position: absolute;
  bottom: -20px;
}

.daysLocked {
  border: 1px solid #ffd808;
  margin: 0 5px;
  padding: 5px 7px;
  border-radius: 5px;
}

.stakingHistoryTable .rdt_Table > div{
  background: #090909;
  color: #787878;
}
.light_theme .stakingHistoryTable .rdt_Table > div{
  background: #ffffff;
  color: #787878;
}
.light_theme .modal-title {
	color: #000;
}
.light_theme .stakePopDetails .flexText h4 {
	color: #000;
}
.light_theme  .flexLabel a {
	font-size: 14px;
	color: #000;
	background: #ccc;
	padding: 4px 5px;
	border-radius: 5px;
}
.light_theme  .MuiSlider-root {
	color: #000 !important;
	cursor: pointer;
}
.light_theme .stakeDetailsRight h4 {
	color: #0b0b0b;
}
.light_theme .btn.btn-secondary {
	font-size: 15px;
	padding: 7px 3px;
  background: transparent;
  color: #ffd808;
	border: 1px solid #ffd808;
}
.light_theme .btn.btn-secondary:hover {
  background: #ffffff;
  border-color: #000;
  color: #000;
}
.light_theme .buy_sellaction .modal-dialog .modal-header {
	border-bottom: 1px solid #959599;
}
.light_theme .MuiMenu-list a {
  color: #000;
  
}
.light_theme .MuiMenu-list a:hover {
text-decoration: underline;
  
}
.light_theme #profile_menu .MuiPopover-paper ul li a{
color: #000;
}
.light_theme #profile_menu .MuiPopover-paper ul li a:hover{
  text-decoration: underline;
  }
  .light_theme .collapseWallet {
    border-top: 1px solid #d2d2d2;

  }
  .light_theme .stakingHistoryTable > div > div > div {
    background-color: transparent;
    color: #000;
  }
  .light_theme a {
    color: #d09300;
  }
  .light_theme .blogWhiteCard h5 {
    color: #313131;
  }
.light_theme .blogListDescription.blogFullDetail ol li,
.light_theme .blogListDescription.blogFullDetail ul li {
  color: #2a2a2a !important;
}

.light_theme .login_container .react-tel-input .form-control {
  border: 1px solid #d4d5d7;    
  background: #e6e6e6;
}
.light_theme .login_container .react-tel-input .flag-dropdown { 
  background-color: #e6e6e6;
  border: 1px solid #d4d5d7;
}
.light_theme .login_container .react-tel-input .selected-flag:hover, 
.light_theme .login_container .react-tel-input .selected-flag:focus {
  background-color: #e6e6e6 !important;
}
.light_theme .login_container .react-tel-input .flag-dropdown.open .selected-flag,
.light_theme .login_container .react-tel-input .flag-dropdown.open {
  background: #e6e6e6;  
}
.light_theme .login_container .react-tel-input .country-list {
  background-color: #ffffff;
}
.light_theme .login_container .react-tel-input .country-list .country:hover,
.light_theme .login_container .react-tel-input .country-list .country.highlight {
  background-color: #d7d7d7;
}
.light_theme .dashboard_box .contact_form .react-tel-input .form-control {
  background: #eee !important;
  border: 1px solid #d0d0d0 !important;
  color: #000;
}
.light_theme .dashboard_box .contact_form .react-tel-input .flag-dropdown { 
  background-color: #eee;
  border: 1px solid #d0d0d0;
}
.light_theme .dashboard_box .contact_form .react-tel-input .selected-flag:hover, 
.light_theme .dashboard_box .contact_form .react-tel-input .selected-flag:focus {
  background-color: #eee !important;
}
.light_theme .dashboard_box .contact_form .react-tel-input .flag-dropdown.open .selected-flag,
.light_theme .dashboard_box .contact_form .react-tel-input .flag-dropdown.open {
  background: #eee;
}
.light_theme .dashboard_box .contact_form .react-tel-input .country-list {
  background-color: #fff;
}
.light_theme .dashboard_box .contact_form .react-tel-input .country-list .country:hover,
.light_theme .dashboard_box .contact_form .react-tel-input .country-list .country.highlight {
  background-color: #d7d7d7;
}

/* dark mode css */
.img_new_launc {
  height: 300px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.img_new_launc img {
  height: auto;
  /* width: auto; */
  max-width: 100%;
}

.profile_user {
  color: #fbd84a;
}
.trader_img {
  height: 100%;
  width: 100%;
}
.dashboard_login li button {
  color: #cbcbcb;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
  text-transform: capitalize;
}
.table_btn button.primary_btn {
  padding: 5px 16px;
  font-size: 13px;
  text-transform: uppercase;
}
.qr_code_ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.qr_code_ p {
  margin: 0;
  text-align: center;
  font-size: 17px;
  padding: 3px 2px;
}

.social_icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.social_icon img {
  transform: none;
  width: auto;
  margin: 8px 0px;
  cursor: pointer;
  height: 50px;
}
.social_icon a+a {
  margin-left: 15px;
}
.text_over_heing {
  line-height: 30px;
  font-size: 23px !important;
}
.blogCategoryItem button.btn.btn-primary {
  min-width: 96%;
  display: block;
  margin: 0 auto !important;
}
.inp_grp {
  position: relative;
}
.inp_grp .bi.bi-search {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.blog_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog_tag {
  background: #28a745;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
}
/* 404 Css */
#notfound {
  position: relative;
  height: 100vh
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}
.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center
}
.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1
}
.notfound .notfound-404 h1 {
  font-size: 230px;
  margin: 0;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url("./assets/images/errorbg.webp") no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  filter: brightness(1) contrast(0.9);
}
.notfound h2 {
  color: #eee;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0
}
.notfound p {
  color: #c7c7c7;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0
}
.notfound button.primary_btn a {
  color: #000;
}
.notfound button.primary_btn:hover a {
  color: #ffd703;
}
.light_theme button.primary_btn:hover a {
  color: #000 !important;
}
.light_theme .notfound .notfound-404 h1 {
  filter: unset;
}
@media only screen and (max-width:767px) {
  .notfound .notfound-404 {
      height: 142px
  }

  .notfound .notfound-404 h1 {
      font-size: 112px
  }
}
.user_details_dash,
.user_details_dash .dudRight {
  width: 100%;
}
.user_details_dash li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_details_dash li span {
  text-align: right;
}
.banner_card_settings .slick-track {
  margin-bottom: 50px;
}
.banner_card_settings .slick-prev, 
.banner_card_settings .slick-next {
  width: 36px;
  height: 36px;
  top: 38%;
  z-index: 1;
}
.banner_card_settings .slick-prev::before,
.banner_card_settings .slick-next::before {
  content: "";
  width: 36px;
  height: 36px;
  display: block;
}
.banner_card_settings .slick-prev::before {
  background: url("./assets/images/prev_icon.webp") no-repeat;
}
.banner_card_settings .slick-next::before {
  background: url("./assets/images/next_icon.webp") no-repeat;  
}
.delete_eye_icon {
  position: absolute;
  right: 15px;
  top: 10px;
}

/* Media Queries */
@media (min-width: 991px) and (max-width: 1199px) {
  .calculate_currency h1 {
    font-size: 65px;
  }
  .home_calculator .calculate_currency h5 {
    margin-top: 0 !important;
  }
  .common-table td {
    white-space: nowrap;
  }
  /* .dashboard_login a {
    padding: 0 20px;
  } */
  .banner_container h1 {
    font-size: 49px;
  }
  .card_digital_currnsy h3 {
    font-size: 17px;
  }
  .card_section_7 {
    width: 85%;
  }
}
@media (max-width: 959px) {
  .home_page_menu ul {
    display: block;
  }
  .home_page_menu ul li {
    padding: 10px 0;
  }
  .menu_main_navbar li a.home_menu_btn {
    width: fit-content;
  }
}
@media (max-width: 991px) {
  .token_new_card {
    margin: 22px 17px;
  }
  .gb_app_sectio img {
    transform: none;
  }
  .gb_app_sectio p {
    font-size: 23px;
  }
  .gb_app_sectio h1 {
    font-size: 43px;
  }
  .foter_logo_sectopn_i {
    justify-content: center;
    flex-wrap: wrap;
  }
  .fooer_ll img {
    margin: 12px 9px;
  }
  .flex_footer_1 {
    justify-content: center;
    margin-top: 19px;
  }
  .footer_bottom p {
    text-align: center;
  }
  .banner_container h1 {
    font-size: 25px;
  }
  .heading-title {
    font-size: 30px;
  }
  .heading-subtitle {
    font-size: 20px;
  }
  .calculate_currency h1 {
    font-size: 45px;
  }
  .home_calculator .calculate_currency h5 {
    margin-top: 0 !important;
    font-size: 24px;
  }
  .common-table td {
    white-space: nowrap;
  }
  .calculate_currency .form-input input {
    font-size: 20px;
  }
  .static_container p {
    font-size: 14px;
  }
  .blog_header {
    display: block;
  }
}

@media (max-width: 767px) {
  .flex_recent_section{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .MuiMenu-paper {
    width: 100%;
    max-width: 100% !important;
    left: 0 !important;
    margin-top: 50px;
    transform: none !important;
  } */
  .order-2 {
    order: 2;
  }
  .order-1 {
    order: 1;
  }
  .dashRightLinks {
    padding: 10px;
  }
  .table thead th {
    white-space: nowrap;
  }
  .banner_card_settings .slick-dots {
    bottom: 0;
  }
  .banner_card_settings .slick-dots li button:before {
    font-size: 14px;
    color: #fff;
  }
  .light_theme .banner_card_settings .slick-dots li button:before {    
    color: #000;
  }
  .banner_card_settings .slick-dots li.slick-active button:before {
    color: #ffd703;
  }
}
@media (max-width: 1400px) { 
  .page_wrap header .logo_div img {
    filter: none;
    width: 216px;
  }
  .navbar_left a#dropdownMenuIcon {
    width: 34px;
    height: 25px;
}
}
@media (max-width: 1279px) {
  .dashboard_login ul {
    padding-left: 0;
  }
  .page_wrap header .logo_div img {
    filter: none;
    width: 170px;
  }

  .afterLoginHeader .container {
    max-width: 100%;
  }
  .userTypeCard {
    margin-bottom: 30px;
  }
  .userTypeCard.lastCard {
    margin-bottom: 0;
  }
  .stakingSubscription ul li {
    flex-direction: column;
  }
  .csRight {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }
  .stakingCurrencyList tr td {
    white-space: nowrap;
  }
}
@media (max-width: 959px) {
  .social_icon {
    justify-content: center;
  }
  .home_calculator h2{
    text-align: center;
  }

  .dashboard_login ul {
    display: flex;
    margin-bottom: 0;
    flex-direction: column;
    padding: 0;
  }
  .dashboard_login li {
    height: auto;
    border-left: 0;
    border-bottom: 1px solid #282828;
  }
  .dashboard_login li a {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 15px;
  }
  .afterLoginHeader .showOnlyforUsers {
    display: none;
  }
  .profile_dash {
    text-align: left;
  }
  .twoFAForm {
    margin-bottom: 20px;
  }
  .balance_details_panel {
    flex-wrap: wrap;
  }
  .balance_details_panel div.balance_details_left {
    width: 100%;
  }
  .balance_details_panel div.balance_details_right {
    width: 100%;
  }
  .balance_details_left {
    display: flex;
    /* align-items: center; */
    margin-bottom: 15px;
  }
  .balance_details_left div {
    margin-bottom: 0 !important;
    width: 50%;
  }
  .kycCardStatus {
    margin-bottom: 20px;
  }
  .walletCardLeft {
    flex-direction: column;
    align-items: flex-start;
  }
  .currencyPrice {
    padding: 0;
    margin-top: 15px;
  }
  .collapseWallet {
    padding-left: 0;
    padding-right: 0;
  }
  .collapseWallet .settingsNote {
    margin-top: 20px;
  }
  .adminAccountCard {
    margin-top: 25px;
  }
  .smallScreenCenter {
    text-align: center;
  }
  .stakingSubscription ul li {
    flex-direction: row;
  }
  .csRight {
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 0px;
  }
  .tableSearchBox {
    min-width: 200px;
  }
  .marginSpace {
    margin-left: 0px;
    margin-top: 10px;
  }
  .historyPageFilter.newUsersFilter {
    flex-direction: column;
  }
  .historyPageFilter .newsSelectGroup {
    flex-direction: column;
  }
  .historyPageFilter .newsSelectGroup label {
    margin-bottom: 10px;
  }
  .historyPageFilter .tableSearchBox {
    margin: 10px 0;
    min-width: 230px;
  }
  .supporTicketAccordian #accordion .card-header .btn {
    flex-direction: column;
  }
  .supporTicketAccordian #accordion .card-header .btn i {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
@media (max-width: 767px) {
  .launchpad_token_single {
    width: 100%;
    margin: 0 auto;
  }
  .bitcoin_value_box {
    width: 100%;
  }
  .launchPadCoinDetails {
    flex-wrap: wrap;
  }
  .launchPadCoinDetails h4 {
    font-size: 15px;
    padding: 10px 17px;
    margin: 0 auto;
  }

  .dash_title {
    font-size: 16px;
   
  }
  .d-flex_p2p{
    margin-bottom: 5px;
  }
  .d-flex_p2p.flex-wrwp-mobile_1 {
    flex-wrap: wrap;
  }
  .ckksdyt_box {
    display: block;
  }
  .ckksdyt_box input {
    margin-right: 6px;
    height: auto;
  }
  .end_content {
    display: flex;
    justify-content: flex-start;
  }
  .getstarted_section {
    margin: 0;
  }
  .inner_why_content {
    width: 100%;
  }
  .inner_why_content p {
    text-align: left !important;
  }
  .slider_contet p {
    text-align: center;
  }
  .slider_contet h3 {
    justify-content: center;
  }
  .logo_bg_shadow {
    background: none;
  }
  .logo_bg_shadow img {
    width: 100px;
    margin-bottom: 15px;
  }
  .login_container label {
    font-size: 14px;
  }
  .clas_heasdin h1 {
    font-size: 28px;
    text-align: center;
  }
  .nav_icin_tab li {
    width: 50%;
  }
  .gb_app_sectio img {
    transform: none;
  }
  .foter_logo_sectopn_i {
    flex-wrap: wrap;
  }
  .fooer_ll img {
    margin: 13px 0px;
    width: 128px;
  }
  .footer_link_ww {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer_link_ww li {
    margin-bottom: 5px;
    margin: 10px;
  }
  .footer_link_ww i {
    display: none;
  }
  .footer_bottom p {
    text-align: center;
  }
  .navbar_left .mainmenudropdown a.dropdown-item {
    width: auto;
    padding: 0;
  }
  .logo_div > img {
    width: 147px;
  }
  .nav_icon_container {
    height: 41px;
    width: 41px;
  }
  .nav_menu_item_container h6 {
    margin-bottom: 5px;
    font-size: 0.7rem;
  }
  .table_button_p button {
    margin: 4px 7px;
  }
  .card_section_7 {
    width: 90%;
    margin: 17px auto;
    padding: 23px 38px;
  }
  .fooer_ll img {
    margin: 13px 13px;
    width: 128px;
  }
  .flex_footer_1 {
    justify-content: center;

    flex-wrap: wrap;
  }
  .gb_app_sectio p {
    text-align: center;
    font-size: 25px;
  }
  .home_page_menu ul li {
    padding: 10px 0;
    margin: 0 7px;
    width: 60%;
  }
}
@media (max-width: 599px) {
  .support_new_desion h5 button {
    flex-wrap: wrap;
  }
  
  .social_icon {
    justify-content: center;
  }
  .home_calculator h2{
    text-align: center;
  }
  .blogCategoryItem button.btn.btn-primary {
    min-width: auto;
  }
 .blogCategory .slick-prev {
    left: 0;
    z-index: 10;
  }
  .blogCategory .slick-next {
    right: 0;
  }
  .flex_slider_name {
    justify-content: flex-start;
    flex-direction: row;
  }
  .flex_slider_name h3 {
    margin-right: 20px;
  }
  .flex_slider_name span {
    text-align: left;
  }
  .height_121 h1 {
    font-size: 50px;
  }

  .getstarted_section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .home_calculator p {
    text-align: center;
  }
  .content_get_start h3 {
    text-align: center;
  }
  .qrScanCode {
    margin-bottom: 20px;
  }
  .profileDetailView h4 small {
    display: block;
  }
  .profileDetailView ul li label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
  }
  .balance_details_left {
    flex-direction: column;
  }
  .balance_details_left div {
    width: 100%;
  }
  .balance_details_panel div.balance_details_right {
    flex-wrap: wrap;
  }
  .chartDash {
    margin-right: 0;
    width: 100%;
    margin-bottom: 25px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .chartLabel {
    width: 100%;
  }
  .walletCard {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .currencyPrice {
    padding: 0;
    margin: 15px 0;
  }
  .walletCardLeft {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .stakingSubscription ul li {
    flex-direction: row;
    align-items: center;
  }
  .csRight {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }
  .newUsersFilter {
    flex-direction: column;
  }
  .newsSelectGroup {
    flex-direction: column;
  }
  .newsSelectGroup label {
    margin-bottom: 10px;
  }
  .tableSearchBox {
    margin: 10px 0;
    min-width: 230px;
  }
  .common-table th {
    font-size: 12px;
    padding: 10px 10px;
  }
  .common-table td {
    font-size: 14px;
    padding: 10px 10px;
  }
  .kycCardStatus ul li {
    display: block;
  }
  .kycCardStatus ul li span {
    margin-left: 0;
  }
}
@media (max-width: 400px) {
  .stakingSubscription ul li {
    flex-direction: column;
    align-items: center;
justify-content: space-evenly;
  }
  .new_about_section {
    background-size: cover;
    background-position: center;
  }
  .about_head_title h3 {
    font-size: 23px;
  }
  .about_head_title p {
    font-size: 16px;
  }
  .img_right_about {
    width: 100%;
  }
  .flex_slider_name {
    justify-content: flex-start;
    flex-direction: row;
  }
  .flex_slider_name h3 {
    margin-right: 20px;
  }
  .flex_slider_name span {
    text-align: left;
  }

  .logo_div > img {
    width: 147px;
  }
  .token_new_card {
    margin: 24px 17px;
  }
  .slider_contet p {
    text-align: center;
  }
  .slider_contet h3 {
    justify-content: center;
  }
}



@media (max-width: 360px) {
  .stakingSubscription ul li {
    flex-direction: column;
    align-items: center;
justify-content: space-evenly;
  }
}

.daysLocked.active{
  background: #ffd808;
  color: #1a1919;
  border: 1px solid #ffd808;  
}
.daysLocked:hover{
  background: #ffd808;
  color: #1a1919;
  border: 1px solid #ffd808;  
  cursor: pointer;
}


/* Fees Page CSS  */
.fees-info-table table th{
  color: #ffffff !important;
  font-weight: 600;
  text-transform: none !important;
}
.light_theme .fees-info-table table th{
  color: #000000 !important;
}
.fees-info-table table td:first-child{
  color: #ffd808;
}
.light_theme .fees-info-table table td:first-child{
  color: #000000;
}
.tradeFulltabbedTable .fees-table thead th{
  vertical-align: middle !important;
}
.fees-info-table table td{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.fees {
  background-color:#f2f2f9;
  flex-shrink:0;
  width:100%
 }
 .fees,
 .fees-section {
  display:inline-flex;
  flex-direction:column
 }
 .fees-section {
  align-self:center;
  align-items:center;
  width:75%;
  padding:0 36px
 }
 @media screen and (max-width:767px) {
  .fees-section {
   padding:0 15px;
   width:100%
  }
 }
 .fees-title {
  font-weight:700;
  padding:45px 0 20px;
  text-transform:uppercase;
  font-size:16px;
  letter-spacing:.5px;
  align-self:flex-start
 }
 @media screen and (max-width:767px) {
  .fees-title {
   padding:20px 0 8px;
   font-size:14px
  }
 }
 .fees-info {
  position:relative;
  width:100%
 }
 .fees-info-title {
  justify-content:left
 }
 .fees-info-title,
 .fees-info-wrapper {
  display:flex;
  flex-direction:column;
  align-items:center;
  max-width:1200px;
  width:100%
 }
 .fees-info-wrapper {
  z-index:2;
  justify-content:center
 }
 .fees-info-wrapper .table-responsive {
  overflow-x:unset
 }
 @media only screen and (max-width:991px) {
  .fees-info-wrapper .table-responsive {
   height:65vh;
   overflow-y:auto;
   margin-bottom:0
  }
 }
 .fees-info-toggle {
  width:100%
 }
 .fees-info-toggle-group {
  width:100%;
  display:flex;
  margin-bottom:16px
 }
 @media screen and (max-width:767px) {
  .fees-info-toggle-group {
   margin-bottom:12px
  }
 }
 .fees-info-toggle-group-parent {
  width:100%;
  display:flex;
  margin-bottom:16px;
  align-self:start;
  border-bottom:2px solid rgba(48,103,237,.10196078431372549)
 }
 @media screen and (max-width:767px) {
  .fees-info-toggle-group-parent {
   margin-bottom:12px;
   width:100%
  }
 }
 .fees-info-toggle-item {
  flex-grow:1;
  font-size:13px;
  font-weight:600;
  box-shadow:none!important;
  opacity:.3;
  position:relative
 }
 @media screen and (max-width:767px) {
  .fees-info-toggle-item {
   font-size:11px
  }
 }
 .fees-info-toggle-item.active {
  opacity:1;
  font-weight:700;
  color:#3067f0
 }
 .fees-info-toggle-item.active:after {
  content:"";
  height:4px;
  width:100%;
  display:block;
  background:#3067f0;
  position:absolute;
  left:0;
  border-radius:16px;
  margin-top:5px
 }
 .fees-info-toggle-item-parent {
  width:30%;
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  box-shadow:none!important;
  opacity:.3;
  position:relative
 }
 @media screen and (max-width:767px) {
  .fees-info-toggle-item-parent {
   font-size:11px
  }
 }
 .fees-info-toggle-item-parent.active {
  opacity:1;
  font-weight:700;
  color:#3067f0
 }
 .fees-info-toggle-item-parent.active:after {
  content:"";
  height:4px;
  width:100%;
  display:block;
  background:#3067f0;
  position:absolute;
  left:0;
  border-radius:16px;
  margin-top:5px
 }
 @media screen and (max-width:767px) {
  .fees-info-toggle-item-parent {
   width:60%
  }
 }
 .fees-info-table {
  width:100%
 }

 .fees-info-table table {
  margin-bottom:0!important;
  border:1px solid #242424;
  border-collapse:initial;
  color: #fff;
 }
 .fees_nav nav .nav-tabs a.nav-item.nav-link {
  color: #eee;
 }
 .fees_nav nav .nav-tabs a.nav-item.nav-link.active {
  background-color: #4b4b4b;
  border-color: #ffd804 !important;
 }
 .light_theme .fees_nav nav .nav-tabs a.nav-item.nav-link {
  color: #333;
 }
 .light_theme .fees_nav nav .nav-tabs a.nav-item.nav-link.active {
  background-color: #cbcbcb;
  border-color: #ffd804 !important;
 }
 .light_theme .fees-info-table table{
  border:1px solid #ddd;
  color: #333;
}
 .fees-info-table table>thead {
  position:sticky;
  top:0
 }
 .fees-info-table table>tbody>tr,
 .fees-info-table table>thead>tr {
  background-color:#242424;
 }
 .light_theme .fees-info-table table>tbody>tr,
 .light_theme .fees-info-table table>thead>tr {
  background-color:#eeeeee;
 }
 .fees-info-table table>tbody>tr.total,
 .fees-info-table table>thead>tr.total {
  background-color:#00c853;
  color:#fff
 }
 .fees-info-table table>tbody>tr.even-child,
 .fees-info-table table>thead>tr.even-child {
  background-color:#090909;
 }
 .light_theme .fees-info-table table>tbody>tr.even-child,
 .light_theme .fees-info-table table>thead>tr.even-child {
  background-color:#ffffff;
 }

 .fees-info-table table>tbody>tr.last-child,
 .fees-info-table table>thead>tr.last-child {
  border-bottom:1px solid hsla(0,0%,50.2%,.24)
 }
 .fees-info-table table>tbody>tr>td,
 .fees-info-table table>tbody>tr>th,
 .fees-info-table table>thead>tr>td,
 .fees-info-table table>thead>tr>th {
  padding:8px 15px;
  font-size:12px
 }
 @media screen and (max-width:767px) {
  .fees-info-table table>tbody>tr>td,
  .fees-info-table table>tbody>tr>th,
  .fees-info-table table>thead>tr>td,
  .fees-info-table table>thead>tr>th {
   padding:8px 14px;
   font-size:11px
  }
 }
 .fees-info-table table>tbody>tr>td span.tag-green,
 .fees-info-table table>tbody>tr>th span.tag-green,
 .fees-info-table table>thead>tr>td span.tag-green,
 .fees-info-table table>thead>tr>th span.tag-green {
  display:flex;
  justify-content:center;
  align-items:center;
  flex-shrink:0;
  width:100%;
  background:rgba(66,182,128,.12);
  color:#42b680;
  padding:4px 8px;
  height:25px;
  border-radius:4px;
  line-height:1em;
  text-transform:capitalize
 }
 .fees-info-table table>tbody>tr>td span.tag-red,
 .fees-info-table table>tbody>tr>th span.tag-red,
 .fees-info-table table>thead>tr>td span.tag-red,
 .fees-info-table table>thead>tr>th span.tag-red {
  display:flex;
  justify-content:center;
  align-items:center;
  flex-shrink:0;
  width:100%;
  color:#f44336;
  background:rgba(244,67,54,.12);
  padding:4px 8px;
  height:25px;
  border-radius:4px;
  line-height:1em;
  text-transform:capitalize
 }
 .fees-info-table table>tbody>tr>td a.rapid,
 .fees-info-table table>tbody>tr>th a.rapid,
 .fees-info-table table>thead>tr>td a.rapid,
 .fees-info-table table>thead>tr>th a.rapid {
  line-height:1em;
  text-align:center;
  color:#3067f0;
  background:rgba(48,103,240,.12);
  display:flex;
  justify-content:center;
  align-items:center;
  flex-shrink:0;
  width:100%;
  padding:4px 8px;
  height:25px;
  border-radius:4px;
  font-size:12px;
  text-transform:uppercase;
  font-weight:700;
  letter-spacing:.3px
 }
 .fees-info-table table>tbody>tr>td a.rapid:hover,
 .fees-info-table table>tbody>tr>th a.rapid:hover,
 .fees-info-table table>thead>tr>td a.rapid:hover,
 .fees-info-table table>thead>tr>th a.rapid:hover {
  border:1px solid #3067f0
 }
 .fees-info-table table>thead>tr>th{
   border-bottom: 1px solid #242424 !important;
   border-left: 1px solid #242424 !important;
 }
 .light_theme .fees-info-table table>thead>tr>th{
  border-bottom: 1px solid #dddddd !important;
  border-left: 1px solid #dddddd !important;
}
 .fees-info-table table>thead>tr>th:first-child{
  border-left: 0px solid #242424 !important;
 }
 .fees-info-table table>tbody>tr>th,
 .fees-info-table table>thead>tr>th {
  color:rgba(0,0,0,.36);
  text-transform:uppercase;
  border-top:0;
  vertical-align:middle;
  text-align:center;
 }
 .fees-bottom-note {
  display:flex;
  padding:0 4px 45px;
  width:100%
 }
 @media screen and (max-width:767px) {
  .fees-bottom-note {
   padding:0 4px 25px
  }
 }
 @media screen and (max-width:1200px) {
  .react-grid-item:not(.react-grid-placeholder) {
    width: calc(100% - 30px)!important;
    position: relative!important;
    -webkit-transform: none!important;
    transform: none!important;
    margin: 0 auto;
  }  
 }
 .fees-faq {
  background:#fff;
  width:100%;
  display:flex
 }
 .fees-faq-wrapper {
  max-width:650px;
  margin:48px auto;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:16px 0
 }
 .fees-faq-wrapper {
  width:100%;
  display:flex
 }
 .rc-checkbox-input {
  height: auto !important;
}


.react-tel-input .form-control{
  padding-left: 50px !important;
  width: 100% !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
  display: flex !important;
  position: relative !important;
  /* border:2px solid #009bff !important; */
}
.react-tel-input .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

} 
.react-tel-input .country-list{
  z-index: 9;
}

.react-tel-input .country-list{
  z-index: 9;
}
.dark_theme .react-tel-input .country-list{
  background-color: #141416 !important;
}
.country-list li{
  width: 100% !important;
  text-align: left !important;
  justify-content: flex-start !important;
}
.dark_theme .react-tel-input .country-list .country:hover{
  background-color: #1b1d23;
}
.dark_theme .react-tel-input .country-list .country.highlight{
  background-color: #1b1d23;
}
.dark_theme .react-tel-input .flag-dropdown,
.dark_theme .react-tel-input .selected-flag:hover,
.dark_theme .react-tel-input .selected-flag:focus,
.dark_theme .react-tel-input .flag-dropdown.open .selected-flag{
  background-color: #12161c !important;
  border-color: #29323f;
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .captchaScale > div > div > div{
    transform: scale(.85);
    margin-left: -20px;
  }
}
@media screen and (max-width:599px) {
  .captchaScale > div > div > div{
    transform: scale(.85);
    margin-left: -20px;
  }
  .profileDetailView ul li{
    flex-direction: column;
  }
  .submitChat{
    flex-wrap: wrap;
  }
  .supporAttachment{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (max-width:991px) {
.navscroll{
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
} 
@media screen and (max-width:575px) {
  .launchpad_token_single {
    width: 100%;
  }
}
.model_detail_text_flx .detail_text_model {
  width: 100%;   
}
.model_detail_text_flx .detail_text_model:nth-child(even) {
  margin-bottom: 10px;
}
}
.Toastify__toast-container {
  z-index: 666666;
}
.error_msg{
  position: relative;
  bottom: 0;
  width: 100%;
}
